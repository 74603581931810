import axios from 'axios'
import React,{useEffect,useState} from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Cookies, useCookies } from 'react-cookie';
import LoadingIndicator from 'react-loading-indicator';
import '../manager.css'
import {BsArrowLeft,BsArrowRight} from 'react-icons/bs'
import Feedback from '../Feedback';
import Expiring from '../Expiring';
import Chat from '../../chat/Chat';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import ELoader from '../../../eLoader';

export default function Instructions() {
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [videoData,setVideoData] = useState("")
    const [hindi,setHindi] = useState("")
    const [feedback,setFeedback] = useState(false)
    const [from , setFrom] = useState("")
    const [graphData,setGraphData] = useState("")
    let User = cookies.user;
    const newToken = cookies.userToken
    const params = useParams()
    const location = useLocation()
    var purchaseid = params.purchaseid
    var courseid = params.courseid
    var folderid = params.folderid
    var a = 0;
    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }
    const query = useQuery()
    var report = query.get("rpg")
    useEffect(() =>{
        if(cookies.user == "" || cookies.user == null || cookies.user == undefined) {
            window.location.href = "/"
        }
        if(a == 0) {
            fetchFileData()
        }
        if(location.state != "" && location.state != null && location.state.from != undefined && location.state.from != null){
            setFrom(location.state.from)
        }
        a++
    },[])
    let saved = 1;
    const makeAttempt = (quizid,fileid) =>{
        axios.post(global.url + "api/user/makeAttempt",{
            userid: User.userid, 
            purchase_id: purchaseid,
            course_id: courseid,
            quizid:quizid,
            fileid: fileid
        },{
            headers :{
                "content-type": "application/json",   
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        }
        )
        .then((res) =>{
                window.open("/dashboard/c/quiz/"+ res.data.attemptid +'/'+courseid+'/'+purchaseid+'/'+folderid , "_blank")
                window.location.reload()
        },
        (error) =>{
            setError(error)
        })
    }
    const [leaderboard,setLeaderboard] = useState("")
    const [failed,setFailed] = useState(false)
    const fetchFileData = () =>{
        axios.post(global.url + "api/user/file_information_app",{
            uid: User.userid, 
            purchase_id: purchaseid,
            course_id: courseid,
            id: folderid,
            report : (report != undefined && report != null && report != "") ? "Yes" : ""
        },
        {headers : {
            "content-type": "application/json",   
            "accept": "application/json",
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }}
        )
        .then((res) => {
            if(res.data.status == "Failed"){
                setFailed(true)
            }
            else{
                setVideoData(res.data)
                setGraphData(res.data.file.graph)
                fetchLeader()
                setLeaderboard(res.data.file.leaderboard)
                if(res.data.file.encrypted_hindi_path != "" || res.data.file.encrypted_hindi_path != null || res.data.file.encrypted_hindi_path != undefined  ) setHindi(res.data.file.encrypted_hindi_path)
                else setHindi("")
            }
            setLoading(false)
        },
        (error) =>{
            setError(error)
        })
    }
    const [leaderList,setLeaderList] = useState("")
    const [leaderFail,setLeaderFail] = useState(false)
    const [segment,setSegment] = useState(0)
    const fetchLeader = () =>{
        axios.post(global.url + "api/user/file_quiz_leaderboard",{
            uid: User.userid, 
            purchase_id: purchaseid,
            course_id: courseid,
            id: folderid
        },
        {headers : {
            "content-type": "application/json",   
            "accept": "application/json",
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }}
        )
        .then((res) => {
            if(res.data.status == "Failed"){
                setLeaderFail(true)
            }
            else{
                setLeaderList(res.data.leaderboard)
                setLeaderFail(false)
            }
        },
        (error) =>{
            setError(error)
        })
    }
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Results',
          },
        },
        scales :{
            y : {
                max: 100
            }
        }
    }
    const options2 = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Leaderboard',
          },
        },
        scales :{
            y : {
                max: 100
            }
        }
    }
    
    if(loading) 
    return(
        <section className='videopage'>
            <div className='container'>
                <div className='w90 float-center  videobox-vidpage loadingbxvid'>
                    <ELoader />
                </div>
            </div>
        </section>
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
  return (
    <>
    {failed ? 
    <section className='videopage'>
        <div className='container topbuttonsvids'>
            <div className='w90 float-center flx-vids'>
                <a href="#" onClick={() => history.back()} title='Go Back' className='backbutton'><BsArrowLeft /> Back</a>
            </div>
        </div>
        <div className='container'>
            <div className='w90 float-center videobox-vidpage' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='pdfcontainer container'>
                    <div className='nodataimg'>
                        <img src={noDataImg} alt="No data found" />
                    </div>
                    <div className='container nofiles'>
                        <p>This files was not found or has been removed</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    :
    <section className='videopage'>
        <div className='container topbuttonsvids maxwidth'>
            <div className='w90 float-center flx-vids'>
                <a href={"javascript:history.back()"} title='Go Back' className='backbutton'><BsArrowLeft /> Back</a>
                {from == "" &&
                ((videoData.file.quizattempts - videoData.file.oldatts.length) > 0) &&
                <button title='Save and Continue' className='nextbutton' onClick={() => makeAttempt(videoData.file.quizid,videoData.file.id)}>Take Test <BsArrowRight /></button>
                }
            </div>
        </div>
        {from == "" &&
        <div className='container maxwidth'>
            <div className='w90 float-center videobox-vidpage instructbox'>
                <div className='container videonambox examinstuctions'>
                    <div className='w95 float-center'>
                        <p>{videoData.file.name} Instructions</p>
                    </div>
                </div>
                {videoData.file.quizinstruction != "" && videoData.file.quizinstruction != null && videoData.file.quizinstruction != undefined &&
                <div className='videodata container'>
                    <div className='w95 float-center ck-content' dangerouslySetInnerHTML={{__html : videoData.file.quizinstruction}}>
                        
                    </div>
                </div>
                }
            </div>
        </div>
        }
        <div className='container maxwidth'>
            <div className='w95 float-center'>
                {from !== "" &&
                <div className='w95 float-center videonambox examinstuctions fromname'>
                    <div className='w95 float-center'>
                        <p>{videoData.file.name}</p>
                    </div>
                </div>
                }
                {from == "" &&
                <div className='w50 float-left leftbxinst'>
                    <div className='w90 float-center videobox-vidpage instructbox'>
                        <div className='container videonambox examinstuctions'>
                            <div className='w95 float-center'>
                                <p>Quiz Summary</p>
                            </div>
                        </div>
                        <div className='instruclist container'>
                            <ul>
                                <li>
                                    <span>Quiz Title</span>
                                    {videoData.file.quiztitle}
                                </li>
                                <li>
                                    <span>Quiz Time</span>
                                    {videoData.file.quiztime}
                                </li>
                                <li>
                                    <span>No. of attempts allowed</span>
                                    {videoData.file.quizattempts}
                                </li>
                                <li>
                                    <span>Remaining Attempts</span>
                                    {videoData.file.quizattempts - videoData.file.oldatts.length}
                                </li>
                                <li>
                                    <span>No. of questions</span>
                                    {videoData.file.quizquestions}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                }
                <div className='w50 float-left rightbxinst'>
                    <div className='w90 float-center videobox-vidpage instructbox'>
                        <div className='container videonambox examinstuctions'>
                            <div className='w95 float-center'>
                                <p>My Attempts</p>
                            </div>
                        </div>
                        <div className='instruclist container attemptslist'>
                            {videoData.file.oldatts == "" || videoData.file.oldatts == null || videoData.file.oldatts == undefined ?
                                <div className='w90 float-center noattemsp center-text'>
                                    <div className='noattemps'>
                                        <img src={noDataImg} alt="No attemps yet" />
                                    </div>
                                    <p>No Attempts Yet</p>
                                </div>
                                :
                                <ul>
                                    {videoData.file.oldatts.map((att, i) =>(
                                    <Link to={'/dashboard/c/result/'+courseid+'/'+purchaseid+'/'+folderid} state={{attemptid : att.attemptid}} title={"Attemp "+(i + 1)}  key={i}>
                                        <li>
                                            <span>{att.quiz_start}</span>
                                            <p>{att.attemptid} <BsArrowRight /></p>
                                        </li>
                                    </Link>
                                    ))}
                                </ul>
                            }
                        </div>
                    </div>
                </div>
                {from !== "" && 
                <>
                <div className='container'>
                    {graphData != "" && graphData != null &&
                    <div className='w50 float-left rightbxinst'>
                        <div className='w95 float-center videobox-vidpage instructbox'>
                        <div className='container videonambox examinstuctions'>
                            <div className='w95 float-center'>
                                <p>Results</p>
                            </div>
                        </div>
                        <Bar options={options} data={{
                            labels: graphData.weblabels,
                            datasets: [{
                            label :'Percentage',
                            data: graphData.values,
                            backgroundColor: '#000173',
                        }]
                        }} />
                        </div>
                    </div>
                    }
                    {leaderboard != "" && leaderboard != null &&
                    <div className='w50 float-left rightbxinst'>
                        <div className='w95 float-center videobox-vidpage instructbox'>
                        <div className='container videonambox examinstuctions'>
                            <div className='w95 float-center'>
                                <p>Leaderboard</p>
                            </div>
                        </div>
                        <Bar options={options2} data={{
                            labels: leaderboard.labels,
                            datasets: [{
                            label :'Percentage',
                            data: leaderboard.values,
                            backgroundColor: '#000173',
                        }]
                        }} />
                        </div>
                    </div>
                    }
                </div>
                {!leaderFail &&
                    <div className='container leaderlist'>
                        <div className='w98 float-center leadwbox'>
                            <div className='container videonambox examinstuctions'>
                                <div className='w95 float-center'>
                                    <p>Leaderboard</p>
                                </div>
                            </div>
                            <div className='container leaderstable'>
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>
                                            Rank
                                        </th>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Marks Obtained
                                        </th>
                                        <th>
                                            Correct
                                        </th>
                                        <th>
                                            Incorrect
                                        </th>
                                        <th>
                                            Skipped
                                        </th>
                                        <th>
                                            Total Time
                                        </th>
                                    </tr>
                                    {
                                        leaderList[segment] !== undefined &&
                                        <>
                                        {leaderList[segment].map((list,i) =>{
                                            var pageno = i+1
                                            var result = JSON.parse(list.result)
                                            var userdata = JSON.parse(list.userdata)
                                            return(
                                                <tr key={i}>
                                                    <td>{pageno}</td>
                                                    <td>{userdata.name}</td>
                                                    <td>{result.result.earned}</td>
                                                    <td>{result.correct}</td>
                                                    <td>{result.incorrect}</td>
                                                    <td>{result.unattempted}</td>
                                                    <td>{(result.timetaken / 60).toFixed(2)}</td>
                                                </tr>
                                            )
                                        })}
                                        </>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
                </>
                }
            </div>
        </div>
    </section>
    }
    {feedback &&
        <Feedback type={videoData.file.type} purchaseid={purchaseid} folderid={folderid} courseid={courseid} userid={User.userid} p_id={videoData.file.p_id}/>
    }
    {(report == undefined || report == null || report == "") &&
        <Expiring user={User} purchaseid={purchaseid} folderid={folderid} courseid={courseid} token={newToken}/>
    }
    {videoData.showchat !== null && 
        <Chat pathString={"Inside_Course_Document"} user={cookies.user} path={{course_id: courseid, purchase_id:purchaseid, pathList: videoData.path, type:"Inside_Document",filetype:"Document"}}  newToken={newToken}/>
    }
    </>
  )
}
