import axios from 'axios'
import React,{useEffect,useState} from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Cookies, useCookies } from 'react-cookie';
import LoadingIndicator from 'react-loading-indicator';
import './manager.css'
import {BsArrowLeft,BsArrowRight,BsCheckCircleFill} from 'react-icons/bs'
import {AiOutlinePlus} from 'react-icons/ai'
import {FiMenu, FiUploadCloud, FiX} from 'react-icons/fi'
import bad from './images/bad.png'
import terrible from './images/terrible.png'
import ok from './images/ok.png'
import good from './images/good.png'
import great from './images/great.png'
import Expiring from './Expiring';
import Chat from '../chat/Chat';
import { BiDownload } from 'react-icons/bi';
import JsFileDownloader from 'js-file-downloader';
import ELoader from '../../eLoader';
import SidebarManager from './SidebarManager';

function crypt (salt, text){
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  
    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
  }
export default function Assignment() {
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [videoData,setVideoData] = useState("")
    const [encrypted, setEncrypted] = useState("")
    const [uploadPop,setUploadPop] = useState(false)
    const [imgSrc,setImgSrc] = useState("")
    const [orgImg,setOrgImg] = useState("")
    const [preview,setPreview] = useState(false)
    const [prevPdf,setPrevPdf] = useState(false)
    const [pdfSrc,setPdfSrc] = useState("")
    const [orgPdf,setOrgPdf] = useState("")
    const [refresh,setRefresh] = useState("Yes")
    const [uploadSuccess,setUploadSuccess] = useState(false)
    const [resultPop,setResultPop] = useState(false)
    const [resultType,setResultType] = useState("Accepted")
    let User = cookies.user;
    const navigate = useNavigate()
    const newToken = cookies.userToken
    const params = useParams()
    var purchaseid = params.purchaseid
    var courseid = params.courseid
    var folderid = params.folderid
    var a = 0;
    const location = useLocation()
    const [prevpath,setPrevPath] = useState("")
    useEffect(() =>{
        if(cookies.user == "" || cookies.user == null || cookies.user == undefined) {
            window.location.href = "/"
        }
        if(a == 0) {
            fetchFileData()
        }
        a++
    },[])
    const getImages = (e) =>{
        setOrgImg(e.target.files)
        var filesArr = e.target.files;
        var temp = []
        for(var i = 0;i<filesArr.length;i++){
            temp.push({
                "uri" : URL.createObjectURL(filesArr[i]),
                "type" : filesArr[i]['type'],
                "name" : filesArr[i]['name'],
                "index" : i
            })
        }
        setImgSrc(temp)
        setUploadPop(false)
        setPrevPdf(false)
        setPreview(true)
    }
    const removeItem = (i) =>{
        var temp = imgSrc
        temp.splice(i,1)
        setImgSrc(temp) 
        if(temp == null || temp == ""){
            setPreview(false)
        }
        setRefresh(refresh == "Yes" ? "No" : "Yes")
    }
    const getPdf = (e) =>{
        setOrgPdf(e.target.files)
        setPdfSrc({
            "uri" : URL.createObjectURL(e.target.files[0]),
            "type" : e.target.files[0]['type'],
            "name" : e.target.files[0]['name'],
            "index" : 0
        })
        setUploadPop(false)
        setPrevPdf(true)
        setPreview(true)
    }
    
    const uploadFile = () =>{
        setUploadLoading(true)
        const formData = new FormData();
            formData.append('uid', User.userid);
            formData.append('purchase_id', purchaseid);
            formData.append('course_id', courseid);
            formData.append('id', folderid);
            if(prevPdf){
                    formData.append('assignmentFiles[]', orgPdf[0]);
            }else{
                for(var i=0;i < imgSrc.length;i++){
                    formData.append('assignmentFiles[]', orgImg[imgSrc[i]["index"]]);
                }
            }
            formData.append('type', prevPdf ? "Pdf" : "Image");
            fetch(global.url + "api/user/uploadAssignment",{
                "headers" : {
                    // "Content-Type": "multipart/form-data",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
                },
                "method" : "POST",
                "body"  : formData
            })
            .then(res => res.json())
            .then((res) =>{
                setUploadLoading(false)
                setPreview(false)
                setUploadSuccess(true)
            },
            (error) =>{
                setError(error)
            })
    }
    const [pre,setPre] = useState("")
    const [post,setPost] = useState("")
    const [failed,setFailed] = useState(false)
    const [rejpop,setRejpop] = useState(false)
    const fetchFileData = () =>{
        setLoading(true)
        axios.post(global.url + "api/user/file_information_app",{
            uid: User.userid, 
            purchase_id: purchaseid,
            course_id: courseid,
            id: folderid
        },
        {headers : {
            "content-type": "application/json",   
            "accept": "application/json",
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }}
        )
        .then((res) => {
            if(res.data.status == "Failed"){
                setFailed(true)
            }
            else{
                setVideoData(res.data)
                if(location.state != undefined && location.state != ""){
                    setPrevPath(location.state.prev)
                } 
                setFailed(false)
                let inst = JSON.parse(res.data.file.assignment_data)
                setPre(inst.instruction)
                setPost("")
                setEncrypted(crypt("Omesh@558",res.data.file.fileid.toString()))
                if((res.data.file.reatt === 1) && (res.data.file.fullyrejectreason !== undefined) && (res.data.file.fullyrejectreason !== "") && (res.data.file.fullyrejectreason !== null)  ){
                    setRejpop(true)
                }
            }
            setLoading(false)
            savePercentage()
        },
        (error) =>{
            setError(error)
        })
    }
    const savePercentage = () => {
        fetch(global.url+"api/user/savePercentage", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            },
            "body": JSON.stringify({
                uid: User.userid,
                purchase_id: purchaseid,
                course_id: courseid,
            })
            })
            .then(response => response.json()) 
            .then(response => { })
    }
    const showResult = (type) =>{
        setResultPop(true)
        setResultType(type)
    }
    const [buttonloading,setButtonloading] = useState(false)
    function naming(name,slug){
        var newSlug
        if(slug == "" || slug == null){
            newSlug = name.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }else{
            newSlug = slug.replace(/ /g, "-")
            newSlug = newSlug.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        }
        return newSlug.toLowerCase()
    }
    const downloadFile = (url,namet,type) =>{
        setButtonloading(true)
        new JsFileDownloader({ 
            url: url,
            forceDesktopMode: true,
            nameCallback: function(name) {
                if(type == "result") {
                    return namet+' Result.pdf';
                }else{
                    return namet+' Assignment.pdf';
                }
            }
        })
        .then(function () {
            setTimeout(function(){
            setButtonloading(false)
            },500)
            setResultPop(false)
        })
        .catch(function (error) {
            // console.log(error)
        });
    }
    const downloadResult = (url,name,type) =>{
        downloadFile(global.url + url,name,type)
    }
    const hidePre = () =>{
        $('.prepop').hide()
    }
    const hidePost = () =>{
        $('.postpoppost').hide()
    }
    const uploaded = () =>{
        setUploadSuccess(false)
        setPost(JSON.parse(videoData.file.assignment_data).message) 
    }
    const [uploadLoading,setUploadLoading] = useState(false)
    
    const [actedMenu,setActedMenu] = useState(false)
    const clickMenu = () =>{
        $('.contentside').css("width","100%");
        $('.sidebarside').css("width","0%");
        setActedMenu(true)
    }
    const showSide = () =>{
        $('.contentside').css("width","calc(100% - 320px)");
        $('.sidebarside').css("width","320px");
        setActedMenu(false)
    }
    if(loading) 
    return(
        <section className='videopage'>
            <div className='container maxwidth'>
                <div className='w90 float-center  videobox-vidpage loadingbxvid'>
                    <ELoader />
                </div>
            </div>
        </section>
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
  return (
    <>
    <section className='sidebarmanager'>
        {failed ? 
        <div className='videopage container'>
            <div className='container topbuttonsvids maxwidth'>
                <div className='w90 float-center flx-vids'>
                    {prevpath != "" &&
                        <a href={prevpath} title='Go Back' className='backbutton'><BsArrowLeft /> Back</a>
                    }
                </div>
            </div>
            <div className='container maxwidth'>
                <div className='w90 float-center videobox-vidpage' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                    <div className='pdfcontainer container'>
                        <div className='nodataimg'>
                            <img src={noDataImg} alt="No data found" />
                        </div>
                        <div className='container nofiles'>
                            <p>This files was not found or has been removed</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        :
        <div className='my100 sidebarflexmng'>
            <div className='sidebarside'>
                <SidebarManager folderid={folderid} parentid={videoData.file.p_id} purchaseid={purchaseid} courseid={courseid}/>
            </div>
            <div className='contentside'>
                <div className='container topsidecontent'>
                    <div className='w90 float-center topflflex'>
                        <div className='topnameside'>
                            <div className='closemenu' onClick={() => (actedMenu) ? showSide() : clickMenu()}>
                                <FiMenu />
                            </div>
                            {/* <div className='topmname'>
                                <p>{videoData.file.name}</p>
                            </div> */}
                        </div>
                        <div className='lsideassing'>
                            
                            {
                            (videoData.file.reatt == 1 || videoData.file.reatt == "1") &&
                                <button title='Upload File' className='nextbutton nextupload' onClick={() => setUploadPop(true)}>{videoData.saved == 100 ? "Re-Upload " : "Upload "} <AiOutlinePlus /></button>
                            }
                            {videoData.file.reatt === null &&
                            <span className='uploadsuc'>
                                <BsCheckCircleFill className='uploaded'/>
                                <p>Assignment Uploaded</p>
                            </span>
                            }
                            {videoData.file.reatt === null &&
                                <button className='nextbutton nextupload' style={{background :  videoData.file.step != 3 ? "red" : "green"}} onClick={() => videoData.file.step != 3 ? (videoData.file.step == 5 ? showResult("Rejected") : "") : showResult("Accepted")}> 
                                {
                                    videoData.file.step != 3 ? (videoData.file.step == 5 ? "Assignmet Rejected" : "Result Not Generated Yet") : "Show Result"
                                }
                                </button>
                            }
                        </div>
                    </div>
                </div>
                <div className='videopage container'>
                    
                    <div className='container maxwidth'>
                        <div className='w90 float-center videobox-vidpage'>
                            <div className='container videonambox'>
                                <div className='w95 float-center videonmflx'>
                                    <p>{videoData.file.name}</p>
                                    <button title='Download File' className='nextbutton nextupload' onClick={() => downloadFile(global.url + videoData.file.document,videoData.file.name,"main")}>Download File <BiDownload /></button>
                                </div>
                            </div>
                            <div className='pdfcontainer container'>
                                <iframe src={'/pdfview/web/viewer.html?src='+encrypted+'&e='+crypt("Omesh@558", User.email)+'&c='+crypt("Omesh@558", (User.contact === null) ? ' ' : User.contact)} style={{width:"100%", height:"calc(100vh - 100px)",border:0}}></iframe>
                            </div>
                            {videoData.file.description != "" && videoData.file.description != null && videoData.file.description != undefined &&
                            <div className='videodata container'>
                                <div className='w95 float-center ck-content' dangerouslySetInnerHTML={{__html : videoData.file.description}}>
                                    
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
    </section>
    {preview &&
    <>{
    prevPdf ?
    <div className='container uploadpop previewpop'>
        <div className='uploadpopbx container'>
            <div className='pdfprevbox w90 float-center maxwitdh2'>
                <iframe src={pdfSrc.uri} style={{width:"100%", height:"calc(100vh - 200px)",border:0,boxShadow:"0 0 10px rgba(0,0,0,0.3)"}}></iframe>
                <div className='container proceedbox center-text'>
                    <button onClick={uploadFile} disabled={uploadLoading ? true : false}>{uploadLoading ? "Uploading" : "Submit"}</button>
                </div>
                <div className='closeupload' onClick={() => setPreview(false)}>
                    <FiX/>
                </div>
            </div>
        </div>
    </div>
    :
    <div className='my100 uploadpop'>
        <div className='uploadpopbx my100 imagepops'>
            <div className='uploadbox imageuploads'>
                <div className='previewImages container'>
                    {
                    imgSrc !== "" && imgSrc.length > 0 &&
                    imgSrc.map((img,i) =>(
                        <div className='imagebox-upload' key={i}>
                            <img src={img.uri} />
                            <div className='deleteimg' title='Remove Image' onClick={() => removeItem(i)}><FiX/></div>
                        </div>
                    ))}
                </div>
                <div className='my100 fixedbottombr'>
                    <div className='my90 float-center fixbximgs'>
                        <label htmlFor="addmore">
                            <span >Change Images</span>
                        </label>
                        <input type="file" id="addmore" multiple onChange={getImages} hidden accept="image/png, image/jpeg,image/jpg"/>
                        <button onClick={uploadFile}>Submit</button>
                    </div>
                </div>
                <div className='closeupload' onClick={() => setPreview(false)}>
                    <FiX/>
                </div>
            </div>
        </div>
    </div>
    }
    </>
    }
    {uploadSuccess &&
    <div className='container uploadpop'>
        <div className='uploadpopbx container'>
            <div className='uploadbox sucessassign'>
                <div className='w85 float-center'>
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                        <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                    </svg>
                    <div className='container feedhead center-text'>
                        <p>Submitted Successfully</p>
                    </div>
                    <div className='container oktext center-text'>
                        <button onClick={() => uploaded()}>Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    {uploadPop &&
    <div className='container uploadpop'>
        <div className='uploadpopbx container'>
            <div className='uploadbox'>
                <div className='w90 float-center uploadmbox'>
                        <div className='w90 float-center uplodflx center-text'>
                            <div className='container uploadheading'>
                                <p>Upload Your Assignment Here</p>
                            </div>
                            <div className='container uploadicon'>
                                <FiUploadCloud />
                            </div>
                            <div className='container browsebutton'>
                                <label htmlFor="upload">
                                    <span>Browse Images</span>
                                </label>
                                <p>OR</p>
                                <label htmlFor="uploadpdf">
                                    <span>Browse Documents</span>
                                </label>
                            </div>
                        </div>
                    <input type="file" id='upload' hidden accept=".png,.jpeg,.jpg" onChange={getImages} multiple/>
                    <input type="file" id='uploadpdf' hidden accept=".pdf" onChange={getPdf}/>
                    <div className='closeupload' onClick={() => setUploadPop(false)}>
                        <FiX/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    {resultPop &&
    <div className='container uploadpop'>
        <div className='uploadpopbx container'>
            <div className='uploadbox'>
                {resultType == "Accepted" && 
                    <div className='resultbox container sucessresul center-text'>
                        <div className='w90 float-center'>
                            <div className='container resultsuccess'>
                                <p>Result Generated</p>
                            </div>
                            <div className='container resultlist'>
                                <p>You Have Scored <span>{videoData.file.cgpa}%</span></p>
                            </div>
                            <div className='container congratstext'>
                                <p>
                                    Marks Obtained : {videoData.file.marks} / {videoData.file.outof}
                                </p>
                            </div>
                            {(videoData.file.grademsg != undefined && videoData.file.grademsg != null && videoData.file.grademsg != "") &&
                                <div className='container grdmsg'>
                                    <p>{videoData.file.grademsg}</p>
                                </div>
                            }
                            <div className='container oktext center-text'>
                                <button onClick={() =>buttonloading ? console.log("downloading..") :  downloadResult(videoData.file.answersheet,videoData.file.name,"result")}>{buttonloading ? "Downloading..." : "Download Result"}</button>
                            </div>
                        </div>
                    </div>
                    }
                    {resultType == "Rejected" && 
                    <div className='resultbox container boxrejected center-text'>
                        <div className='w90 float-center'>
                            <div className='container resultsuccess'>
                                <p>Your Assignment was Rejected</p>
                            </div>
                            {videoData.file.reason != ""  &&
                            <div className='container resultlist'>
                                <p>{videoData.file.reason}</p>
                            </div>
                            }
                            <div className='container oktext center-text'>
                                <button onClick={() => setResultPop(false)}>Ok</button>
                            </div>
                        </div>
                    </div>
                }
                <div className='closeupload' onClick={() => setResultPop(false)}>
                    <FiX/>
                </div>
            </div>
        </div>
    </div>
    }
    
    {pre!= "" && pre != null &&
        <div className='postpop container prepop'>
            <div className='postpopbox container maxwidth'>
                <div className='postpopdata'>
                    <div className='w98 float-center ck-content'>
                        <div className='container postmsg'  dangerouslySetInnerHTML={{__html : pre}}>

                        </div>
                        <div className='container postmsgbuttons'>
                            <button className='nextbutton' style={{margin: "10px auto"}} onClick={() => hidePre()}>OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    {post!= "" && post != null &&
    <div className='postpop container postpoppost'>
        <div className='postpopbox container maxwidth'>
            <div className='postpopdata'>
                <div className='w98 float-center ck-content'>
                    <div className='container postmsg'  dangerouslySetInnerHTML={{__html : post}}>

                    </div>
                    <div className='container postmsgbuttons'>
                        <button className='nextbutton' style={{margin: "10px auto"}} onClick={() => hidePost()}>OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    {rejpop &&
    <div className='postpop container postpoppost'>
        <div className='postpopbox container maxwidth'>
            <div className='postpopdata'>
                <div className='w98 float-center ck-content'>
                    <div className='container rejmessage'>
                        <p style={{
                            fontSize:"20px",
                            fontWeight : "500",
                            color:"rgba(0,0,0,0.8)",
                            lineHeight:"inherit",
                            padding:"10px 0",
                            textAlign:"center"}}>
                            Your Assignment was Rejected
                        </p>
                    </div>
                    <div className='container postmsg'  dangerouslySetInnerHTML={{__html : videoData.file.fullyrejectreason}}>
                        
                    </div>
                    <div className='container postmsgbuttons'>
                        <button className='nextbutton' style={{margin: "10px auto"}} onClick={() => hidePost()}>OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    <Expiring user={User} purchaseid={purchaseid} folderid={folderid} courseid={courseid} token={newToken}/>
    {videoData.showchat !== null && 
        <Chat pathString={"Inside_Course_Assignment"} user={cookies.user} path={{course_id: courseid, purchase_id:purchaseid, pathList: videoData.path, type:"Inside_Course_Assignment",filetype:"Assignment"}}  newToken={newToken}/>
    }
    </>
  )
}
