import React from 'react'

export default function ELoader() {
  return (
    <div className='eloader container'>
        <div className='eloadimg'>
            {/* <img src={process.env.PUBLIC_URL+'/iconbg.png'} alt='' className='loadbg'/> */}
            <img src={process.env.PUBLIC_URL+'/iconlogo.png'} alt='Loading...'  className='loadfront'/>
        </div>
    </div>
  )
}
