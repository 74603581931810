import React, { useEffect, useRef, useState  } from 'react'
import Banners from './Banners'
import axios from 'axios'
import basedata from '../baseData.js'
import {FiChevronRight} from 'react-icons/fi'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Testimonials from './testimonials'
import { Cookies,useCookies } from 'react-cookie'
import CategoryProducts from './CategoryProducts'
import LoadingIndicator from 'react-loading-indicator'
import DownloadApp from './DownloadApp'
import mapPeople from './mappeople.png'
import mapBg from './mapbg.png'
import school from './school.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import BlogPage from '../blogs/BlogPage'
import ELoader from '../eLoader'
import seoTags from '../SeoTags'
import Founder from './Founder'
import PopUpCr from './PopUpCr'
import Menu from '../Menu';
import Footer from '../footer/Footer'
 
export default function Homepage() {
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [banners,setBanners] = useState("")
    const [actCount,setActCount] = useState()
    const [courseLoading,setCourseLoading] = useState(true)
    const [catNames,setCatNames] = useState("")
    const [catData,setCatData] = useState("")
    const [testActCount,setTestActCount] = useState("")
    const [testLoad,setTestLoad] = useState(false)
    const [testData,setTestData] = useState("")
    const [cookies,setCookie,removeCookie] = useCookies('')
    var a = 0;
    useEffect(() =>{
        if(a == 0){
            setCookie('callback',('/'),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
            fetchBanners()
            if($(window).width() < 900) {
                $(window).scroll(function(){
                    handleScroll()
                })
            }
        }
        a++
    },[])
    const fetchBanners = () =>{
        axios.post(global.url + "api/user/getbanners",null,{
            headers : {
                'Authorization' : "Bearer " + cookies.maintoken,
                'Accept' : 'application/json'
            },
            params : {
                "mode" : ($(window).width() < 960) ? "Mobile" : Mode,
                type: ($(window).width() < 960) ? "mobile" : "pc"
            }
        })
        .then((res) =>{
            setBanners(res.data)
            fetchCategories()
        },(error) =>{
            setError(error)
            setLoading(false)
        }
        )
    }
    const fetchCategories = () =>{
        axios.post(global.url + "api/user/getcategories",null,{
            headers : {
                'Authorization' : "Bearer " + cookies.maintoken,
                'Accept' : 'application/json'
            },
            params : {
                "mode" : Mode
            }
        })
        .then((res) =>{
            setActCount(res.data.active)
            if(res.data.categories.length > 0) {
                showCourses(res.data.active,res.data.categories[0].mobilelimit,res.data.categories[0].weblimit)
                setCatNames(res.data)
            }
            // setTestActCount(res.data["0"].id)
            
            // fetchTestimonials(res.data["0"].id)
        },(error) =>{
            setError(error)
        }
        )
    }
    const showCourses = (x,ml,wl) =>{
        setActCount(x)
        setCourseLoading(true)
        axios.post(global.url + "api/user/getactivecategory",null,{
            headers : {
                'Authorization' : "Bearer " + cookies.maintoken,
                'Accept' : 'application/json'
            },
            params:{
                categoryid : x,
                limit : (screen.width < 900) ? ml : wl,
                mode : Mode
            }
        })
        .then((res) =>{
            setCatData(res.data)
            setCourseLoading(false)
            if(testActCount == ""){
                fetchTestimonials(x)
            }
        },(error)=>{
            setError(error)
        }
        )
    }
    
    const fetchTestimonials = (x) =>{
        setTestActCount(x)
        setTestLoad(true)
        axios.post(global.url + "api/user/feedback_list",null,{
            headers : {
                'Authorization' : "Bearer " + cookies.maintoken,
                'Accept' : 'application/json'
            },
            params : {
                limit : 6,
            }
        })
        .then((res) =>{
            setTestData(res.data)
            setTestLoad(false)
            getBasic()
        },(error) =>{
            setError(error)
            setLoading(false)
        }
        )
    }
    const setTestAct = (x) =>{
        setTestActCount(x)
    }
    const [basic,setBasic] = useState("")
    const [autoplays,setAutoplays] = useState(false)
    const getBasic = () =>{
        axios.post(global.url + "api/user/homepage",null,{
            headers : {
                'Authorization' : "Bearer " + defaultToken,
                'Accept' : 'application/json'
            }
        })
        .then((res) =>{
            console.log(res.data)
            setBasic(res.data)
            seoTags(res.data.rules.home_seo_description,res.data.rules.home_seo_keywords,res.data.rules.home_seo_title,"","")
            setLoading(false)
            if(res.data.popup.show_crausel){
                setTimeout(function(){
                    $('.carouselpop').fadeIn()
                    setAutoplays(true)
                },res.data.popup.showin)
                if(res.data.popup.autohide > 0){
                    setTimeout(function() {
                        $('.carouselpop').fadeOut()
                        setAutoplays(false)
                    },(res.data.popup.showin + res.data.popup.autohide))
                }
            }
        },(error) =>{
            console.log(error.message)
        })
    }
    const [catshowed,setCatshowed] = useState(true)
    
    
    const myref = useRef();
    const handleScroll = () =>{
        var a = $(window).scrollTop();
        var b = myref.current.offsetTop;
        if((a + 200) > b){
                setCatshowed(false)
        }
        else{
            setCatshowed(true)
        }
    }
    if(loading) {
        return(
            <div className='container big-loading bigtwo'>
                <ELoader />
            </div>
        )
    }
    else if(error) {
        return(
            <p>{error.message}</p>
        )
    }
    else
    return (
        <>
        <Menu />
            <Banners data={banners} bannerType="Top Banner" className="topbanners"/>
            <div className='container pc_hide' id='coursesbox2' style={{marginTop : "-150px"}}></div>
             <section className='allcategoriesbox' id="coursesbox"  ref={myref}>
                <div className='my100 float-left  bottomside-normal'>
                    <div className='my100 float-left coursescatbox-normal'>
                        <div className='w90 float-center coursescat-normal'>
                                {catshowed ? 
                                <div className='container'>
                                    <ul>
                                        {catNames.categories.map((names,i)=>(
                                        <a href={(screen.width < 900 ? '#coursesbox2' : '#coursesbox') } title={names.categoryName} key={i} >
                                            <li title='CA Inter' className={`${actCount == names.categoryid ? "actcat " : "" }`} onClick={() => showCourses(names.categoryid,names.mobilelimit,names.weblimit)} >
                                                <span className='circleact'></span><p>{names.categoryName}</p>
                                            </li>
                                        </a>
                                        ))}
                                    </ul>
                                </div>
                                    :
                                <div className='container catactsbox'>
                                    <div className='actnameside'>
                                        <ul>
                                        {catNames.categories.map((names,i)=>(
                                            actCount == names.categoryid &&
                                            <a href='#coursesbox' title={names.categoryName} key={i} >
                                                <li title='CA Inter' className={`${actCount == names.categoryid ? "actcat " : "" }`} onClick={() => showCourses(names.categoryid,names.mobilelimit,names.weblimit)} >
                                                    <span className='circleact'></span><p>{names.categoryName}</p>
                                                </li>
                                            </a>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className='changeside'>
                                        <a href='#coursesbox2'>Change
                                        </a>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    <div className='w90 float-center coursesflex maxwidth'>
                        <CategoryProducts loading={courseLoading} dataCourse={catData}/>
                    </div>
                </div>
            </section>
            {!loading &&
                <>
                {basic.rules.home_founder_status == "Yes" &&
                    <Founder data={basic}/>
                }
                </>
            }
            <Banners data={banners} bannerType="Middle Banner" className="middlebanners"/>
            <section className='testimonials'>
                <div className='container maxwidth'>
                    <div className='container headings left-text'>
                        <div className='w90 float-center'>
                            <div className='mainheading container'>
                            {basic.rules != "" && basic.rules != null &&
                                <>
                                {basic.rules.testimonials_heading != "" ?
                                <p>{basic.rules.testimonials_heading}</p>
                                :
                                <p>Testimonials</p>
                                }</> 
                            }
                            </div>
                            <div className='container subtext'>
                                {basic.rules != "" && basic.rules != null &&
                                    <>
                                    {basic.rules.testimonials_sub_heading != "" ?
                                    <p>{basic.rules.testimonials_sub_heading}</p>
                                    :
                                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form</p>
                                    }</> 
                                }   
                            </div>
                        </div>
                    </div>
                    <div className='container testimonialsmain overflow-visible'>
                        <div className='w90 float-center overflow-visible'>
                            <div className='container videocat coursescat-normal'>
                                <ul>
                                    {testData.categories.map((names,i)=>(
                                    <li key={i} title='CA Inter' className={`${testActCount == names.categoryid ? "actcat " : "" }`} onClick={() => setTestAct(names.categoryid)}>
                                        <span className='circleact'></span><p>{names.categoryName}</p>
                                    </li>
                                    ))}
                                </ul>
                            </div>
                            <Testimonials loading={testLoad} data={testData} act={testActCount}/>
                            <div className="container viewall"><a alt="View All Testimonials" href="/testimonials"><button className="clickbutton orangebut"><span>View All Testimonials</span> </button></a></div>
                    </div>
                </div>
            </div>
        </section>
        <section className='counter' style={{background : `url(${mapBg})`}} >
            <div className='container'>
                <div className='peopleside container'>
                    <div className='mappeopleimage'>
                        <img src={mapPeople} alt={(basic.rules.counters_heading != "") ? basic.rules.counters_heading : ""} />
                    </div>
                </div>
                <div className='w80 float-center'>
                    <div className='container countermainbox'>
                        <div className='w40 float-left'>
                            <div className='w90 float-left counterside'>
                                <div className='container headings left-text'>
                                    <div className='mainheading container' >
                                        {basic.rules != "" && basic.rules != null &&
                                            <>
                                            {basic.rules.counters_heading != "" ?
                                            <p>{basic.rules.counters_heading}</p>
                                            :
                                            <p>Impact. At scale</p>
                                            }</> 
                                        }
                                    </div>
                                    <div className='container subtext'>
                                        {basic.rules != "" && basic.rules != null &&
                                            <>
                                            {basic.rules.counters_sub_heading != "" ?
                                            <p>{basic.rules.counters_sub_heading}</p>
                                            :
                                            <p>Making education affordable and accessible across the country</p>
                                            }
                                            </> 
                                        }   
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w60 float-left'>
                            <div className='w90 float-right countryside'>
                                <div className='container countermain'>
                                    {basic.rules != "" && basic.rules != null &&
                                    <>
                                    {basic.rules.counter1title != "" ?
                                    <div className='w33 float-left'>
                                        <div className='container countcardbox'>
                                            <div className='w90 countcard' >
                                                <div className='w90 float-center'>
                                                    <div className='container counter-icon'>
                                                        <div className='counticon'>
                                                            <img src={basic.rules.counter1icon != ""  ? global.asset+basic.rules.counter1icon.path : school} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='container counter-count'>
                                                        <p>
                                                            {basic.rules.counter1title}
                                                        </p>
                                                    </div>
                                                    <div className='container countersub'>
                                                        <p>
                                                            {basic.rules.counter1body}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                    }
                                    </>}
                                    <div className='w33 float-left'>
                                        
                                    {basic.rules.counter2title != "" ?
                                        <div className='container countcardbox'>
                                            <div className='w90 countcard' >
                                                <div className='w90 float-center'>
                                                    <div className='container counter-icon'>
                                                        <div className='counticon'>
                                                            <img src={basic.rules.counter2icon != ""  ? global.asset+basic.rules.counter2icon.path : school} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='container counter-count'>
                                                        <p>
                                                            {basic.rules.counter2title}
                                                        </p>
                                                    </div>
                                                    <div className='container countersub'>
                                                        <p>
                                                            {basic.rules.counter2body}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                    <></>
                                    }
                                    {basic.rules.counter3title != "" ?
                                        <div className='container countcardbox'>
                                            <div className='w90 countcard' >
                                                <div className='w90 float-center'>
                                                    <div className='container counter-icon'>
                                                        <div className='counticon'>
                                                            <img src={basic.rules.counter3icon != ""  ? global.asset+basic.rules.counter3icon.path : school} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='container counter-count'>
                                                        <p>
                                                            {basic.rules.counter3title}
                                                        </p>
                                                    </div>
                                                    <div className='container countersub'>
                                                        <p>
                                                            {basic.rules.counter3body}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                    <></>
                                    }
                                    </div>
                                    <div className='w33 float-left'>
                                    {basic.rules.counter4title != "" ?
                                        <div className='container countcardbox'>
                                            <div className='w90 countcard' >
                                                <div className='w90 float-center'>
                                                    <div className='container counter-icon'>
                                                        <div className='counticon'>
                                                            <img src={basic.rules.counter4icon != ""  ? global.asset+basic.rules.counter4icon.path : school} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='container counter-count'>
                                                        <p>
                                                            {basic.rules.counter4title}
                                                        </p>
                                                    </div>
                                                    <div className='container countersub'>
                                                        <p>
                                                            {basic.rules.counter4body}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                    <></>
                                    }
                                    {basic.rules.counter5title != "" ?
                                        <div className='container countcardbox'>
                                            <div className='w90 countcard' >
                                                <div className='w90 float-center'>
                                                    <div className='container counter-icon'>
                                                        <div className='counticon'>
                                                            <img src={basic.rules.counter5icon != ""  ? global.asset+basic.rules.counter5icon.path : school} alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='container counter-count'>
                                                        <p>
                                                            {basic.rules.counter5title}
                                                        </p>
                                                    </div>
                                                    <div className='container countersub'>
                                                        <p>
                                                            {basic.rules.counter5body}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                    <></>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <BlogPage page="Homepage" heading={basic.rules.blogs_heading} subheading={basic.rules.blogs_sub_heading}/>
        <DownloadApp appimg={basic.rules.app_image} heading={basic.rules.download_app_heading} subheading={basic.rules.download_app_sub_heading} iosurl={basic.rules.ios_app_url} andurl={basic.rules.android_app_url}/> 
        <PopUpCr data={basic} play={autoplays}/>
        <Footer />
        </>
    )
}
