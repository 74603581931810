import React,{useEffect} from 'react'
import Hls from 'hls.js';
import $ from "jquery"
import Plyr from 'plyr';
import { useState } from 'react';
import axios from 'axios';
import { Cookies, useCookies } from 'react-cookie';

export default function VideoTest() {
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    
    const [url,setURL] = useState("https://escholar588.s3.ap-south-1.amazonaws.com/Test/playlist_0_8000.m3u8")
    
    const [playingCount, setPlayingCount] = useState({
        time:0,
        oldtime:0
    })
    let User = cookies.user;
    const newToken = cookies.userToken
    
    
      var a = 0
    useEffect(() => {

        if(a === 0){
            var video = document.getElementById('player');
            var videoSrc =  url;
            var startTime = 0;
            const defaultOptions = {
                "storage": { enabled: false },
                "currentTime" : "122",
                "controls": [
                    'play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'airplay', 'fullscreen','rewind','fast-forward'
                ],
                "seekTime" : 10
                // "forward": 10,
                // "markers": {
                //     enabled: true,
                //     points: [
                //         {
                //             time:10,
                //             label:"Lable Time"
                //         }
                //     ]
                // }
            };

            if (Hls.isSupported()) {
                var hls = new Hls();
                hls.loadSource(videoSrc);

                hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
                    
                    // Transform available levels into an array of integers (height values).
                    const availableQualities = hls.levels.map((l) => l.height)
                    availableQualities.unshift(0)

                    // Add new qualities to option
                    defaultOptions.quality = {
                    // default: availableQualities[1],
                    default: 0,
                    options: availableQualities,
                    // this ensures Plyr to use Hls to update quality level
                    forced: true,        
                    onChange: (e) => updateQuality(e),
                    }
                    // Add Auto Label 
                    defaultOptions.i18n = {
                        qualityLabel: {
                            0: 'Auto',
                        },
                    }
            
                    // Initialize here
                    
                    const player = new Plyr(video, defaultOptions);
                    player.on('ended', function() {
                    })

                    player.on('ready', function() {
                        $("#player").click(); 
                        
                        player.play();

                        $('.engbutton').on("click",function(){
                            $('.languagebutton').removeClass("actlang");
                            $(this).addClass("actlang");
                            player.currentTime = pauseTime
                        })
                        $('.hindbut').on("click",function(){
                            $('.languagebutton').removeClass("actlang");
                            $(this).addClass("actlang");
                            player.currentTime = pauseTime
                        })
                        
                        setInterval(() =>{
                            saveProgress(player.currentTime)
                        },10000)
                    })
                    
                    player.on('play', function() {
                        if(startTime !== 0){
                            player.currentTime = startTime
                        }
                    })
                    player.on('loadeddata', function() {
                        player.currentTime = startTime
                    });
                    player.on('timeupdate', function(currentTime) {
                        // console.log(currentTime.timeStamp)
                    })
                    
                    player.on('ratechange', function(ratechange) {
                        // console.log(player.speed)
                    })
                    player.on('enterfullscreen', function() {
                    })
                    player.on('exitfullscreen', function() {
                    })
                    player.on('pause',function(){
                        pauseTime = player.currentTime
                    })
                });
                
                hls.attachMedia(video);
                window.hls = hls;
                
                setTimeout(function(){
                    callAgain()
                },2000)
                

                
                // hls.attachMedia(video);
            }
            else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                // video.src = videoSrc;
                const player = new Plyr(video, defaultOptions);
            }
            
            a++
        }
    }, [url])
    
    
    
  return (
    <>
    <div className='mainvideobox'>
        <video
            id="player"
            src={url}
            type="application/vnd.apple.mpegurl"
            // muted
            // autoPlay={true}
            >
        </video>
    </div>
    </>
  )
}
