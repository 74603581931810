import React from 'react'
import Sidebar from './Sidebar'
import TopBar from './TopBar'
import { useState } from 'react'
import ELoader from '../eLoader'
import { Cookies, useCookies } from 'react-cookie';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react'
import noPurc from './images/nopurchase.png'

export default function PurchaseHistory() {
    
    const [act,setAct] = useState("1")
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [purchaseInfo, setPurchaseInfo] = useState([]);
    let User = cookies.user;
    const newToken = cookies.userToken
    const location = useLocation()
    var a = 0;
    useEffect(() =>{
        if(a == 0){
            getUserData()
        }
    },[])
    const getUserData = () => {
        fetch(global.url+"api/user/getPurchaseHistory", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            },
            "body": JSON.stringify({
                userid: User.userid,
            })
            })
            .then(response => response.json()) 
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setPurchaseInfo(response.history)
                    setLoading(false)
                }
            })
            .catch(err => {
                console.log(err)
            });
      }
    if(loading) 
    return(
        <section className='userdashboard '>
            <div className='my100 float-left dashboxmain'>
                <div className='sidebar-box float-left'>
                    <Sidebar act={11}/>
                </div>
                <div className='dashcontent float-left'>
                    <TopBar heading="Purchase History"/>
                    <div className='insideloading container'>
                        <ELoader /> 
                    </div>
                </div>  
            </div>
        </section>
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
  return (
    <section className='userdashboard '>
        <div className='my100 float-left dashboxmain'>
            <div className='sidebar-box float-left'>
                <Sidebar act={11}/>
            </div>
            <div className='dashcontent float-left'>
                <TopBar heading="Purchase History"/>
                {purchaseInfo.length > 0 ? 
                <div className='my100 dashwhitebox resultsbox purchaseboxmain'>
                    <div className='container historypurbox'>
                        <table id="filmanagertable" className="purchasetable">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Payment ID</th>
                                    <th>Summary</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                    purchaseInfo !== null && purchaseInfo.length > 0 &&
                                    purchaseInfo.map((item, index) => {
                                        return(
                                            <tr key={"key"+index}>
                                                <td>{index+1}</td>
                                                <td dangerouslySetInnerHTML={{__html:item.name}} className='ck-content'></td>
                                                <td>{item.payment_id}</td>
                                                <td>
                                                    {
                                                        item.summary.paidamnt > 0 &&
                                                        <>  
                                                        <div className='container'>
                                                            <strong>Amount Paid:</strong> {item.summary.paidamnt}
                                                        </div>
                                                        </>
                                                    }
                                                    {
                                                        item.summary.coinused > 0 &&
                                                        <>
                                                        <div className='container'>
                                                            <strong>Coin Used: </strong>{item.summary.coinused}
                                                        </div>
                                                        </>
                                                    }
                                                    {
                                                        (item.summary.paidamnt < 1 && item.summary.coinused < 1) &&
                                                        <div className='container'>
                                                            "<strong>Free</strong>"
                                                        </div>
                                                    }
                                                </td>
                                                <td className='datepurch'>{item.created_at}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <div className='my100 dashwhitebox resultsbox purchaseboxmain'>
                    <div className='emptybox container'>
                        <div className='emptyimg'>
                            <img src={noPurc} alt="No Data Found" />
                        </div>
                        <div className='emptypara container'>
                            <p>No Purchase History</p>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </section>
  )
}
