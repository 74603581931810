import React, { useState } from 'react'
import Video from '../dashboard/coursefiles/Video'
import { Cookies, useCookies } from 'react-cookie';

function crypt (salt, text){
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

  return text
    .split("")
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join("");
}
export default function Preview(props) {
  console.log(props)
  const [cookies, setCookie, removeCookie] = useCookies(''); 
  let User = cookies.user;
  return (
    <section className='previewpage'>
          {props.data.type == "Youtube" &&
            <iframe src={props.data.youtube_url} className="youtubevideopage"></iframe>
          }
          {props.data.type == "Video" &&
            <Video path={props.data.encrypted_path} type={"encrypt"} preview={true} hindi={props.data.encrypted_hindi_path}/>
          }
          {props.data.type == "Document" &&
              <div className='pdfcontainer container'>
                  <iframe src={'/pdfview/web/viewer.html?src='+crypt("Omesh@558", props.data.fileid.toString())+'&e='+crypt("Omesh@558", "Escholar Preview")+'&c='+crypt("Omesh@558", "Escholar Preview")} style={{width:"100%", height:"calc(100vh - 100px)",border:0}}  allow="autoplay; fullscreen"></iframe>
              </div>
          }
    </section>
  )
}
