import React, { useEffect ,useState} from 'react'
import defaultProfile from './images/dashprofile.png'
import {BsGearFill,BsBookmarks,BsQuestionSquareFill,BsFillFilePdfFill,BsFillChatTextFill,BsWallet2} from 'react-icons/bs'
import {FaBars,FaFileAlt,FaRegBell,FaUserTag} from 'react-icons/fa'
import {TbHistory, TbReportAnalytics} from 'react-icons/tb'
import {SiPagespeedinsights} from 'react-icons/si'
import {FiChevronLeft, FiLogOut,FiMapPin} from 'react-icons/fi'
import {BiStore} from 'react-icons/bi'
import {ImProfile} from 'react-icons/im'
import {CgProfile} from 'react-icons/cg'
import {ImEarth} from 'react-icons/im'
import $ from 'jquery'
import {Link} from 'react-router-dom'
import { Cookies, useCookies } from 'react-cookie';
import axios from 'axios'

export default function Sidebar(props) {
  const [topLoading,setTopLoading] = useState(true)
  const [actTab,setActTab] = useState(props.act)
  const [collapsed,setCollapsed] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(''); 
  const [newNotif,setNewNotif] = useState("")
  let newToken = cookies.userToken
  var a =0;
  useEffect(()=>{
    if(a == 0){
        $(document).ready(function(){
            setTimeout(function(){
            $('.dropable').on('click',function(){
            });
            },700)
        });
        undreadNot()
    }
    a++
    if(cookies.user == null || cookies.user == undefined ||  cookies.user == "" ) {
        window.location.href = "/"
    }
  },[])
  const slideFunc = () =>{
    window.$(".fxdash").css("left","0");
  }
  const [dropped,setDropped] = useState("")
  const dropList = (x) =>{
    var a = x
    if(dropped == x){
        $("#"+a).slideUp()
        setDropped("")
    }else{
        $("#"+a).slideDown()
        setDropped(x)
    }
  }
  
    const undreadNot = () =>{
        axios.post(global.url + "api/user/getUnreadNotifications",{
            userid : cookies.user.userid
        },{
            headers : {
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            if(res.data.count > 0){
                setNewNotif(res.data.count)
            }
        },(err) =>{
            console.log(err.message)
        })
    }
  const logOut = () =>{
        removeCookie('user',{path : "/"})
        removeCookie('userToken',{path : "/"})
        window.location.href = '/'
    }
  if(!topLoading){
      ""
  }
  else 
  return (
    (cookies.user != "" || cookies.user != null || cookies.user != undefined) &&
    
    <div className="sidebar-box float-left">


        <div className='my100 sidebar-user'>
        <div className="container logoboxdash">
            <div className="w90 float-center logoflex">
                <div className="dashlogo">
                    <a href="/" title='Escholar'>
                        <img src={logoMain} alt="Logo"/>
                    </a>
                </div>
                <div className="dashcollapse pc_hide">
                    <FaBars className="fas fa-bars collapsebutton" onClick={slideFunc}/>
                </div>
            </div>
        </div>
        <div className='fxdash container'>
            <div className='eightyside container'>
                <div className="profileimage container">
                    <div className="w90 float-center">
                        <Link to="/dashboard/profile" title="">
                            <div className="container profilebx">
                                {cookies.user != undefined &&
                                <div className="dashprofileimg">
                                    <img src={(cookies.user.image != undefined && cookies.user.image != "" )? global.asset + cookies.user.image : defaultProfile} alt="Profile"/>
                                </div>
                                }
                                <div className="profilesetting">
                                    <BsGearFill title='Profile Settings'/>
                                </div>
                            </div>
                            <div className="container center-text profdetc">
                                <div className="profilename container">
                                    {cookies.user != undefined &&
                                    <p>{cookies.user.name}</p>
                                    }
                                </div>
                                <div className="container profilesubtext">
                                    {cookies.user != undefined &&
                                    <p>{cookies.user.email}</p>
                                    }
                                </div>
                                <div className="container profilesubtext">
                                    {cookies.user.email_verified_at != undefined || cookies.user.email_verified_at === "" || cookies.user.email_verified_at === null &&
                                    <p style={{color:"red", fontWeight:"bold", cursor:"pointer"}} >Verify Email</p>
                                    }
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                    <div className="container application-lists">
                        <div className="container apphead">
                            <div className="w90 float-center">
                                <p>Application</p>
                            </div>
                        </div>
                        <div className="container applists">
                            <ul>
                                <li className={`mainlink ${actTab == 1 ? "actived" : ""}`} hidden>
                                    <Link to="/dashboard" title='Dashboard'>
                                        <div className="droplevelmain container  ">
                                            <div className="w90 float-center mlinkflex">
                                                <div className="micon">
                                                    <SiPagespeedinsights />
                                                </div>
                                                <div className="mtext">
                                                    <p>Dashboard</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li className={`mainlink ${actTab == 2 ? "actived" : ""}`}>
                                    <Link to="/d/courses" title='My Courses'>
                                        <div className="droplevelmain container " >
                                            <div className="w90 float-center mlinkflex">
                                                <div className="micon">
                                                    <FaFileAlt />
                                                </div>
                                                <div className="mtext">
                                                    <p>My Courses</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li className={`mainlink ${actTab == 3 ? "actived" : ""}`}>
                                    <div className="droplevelmain container dropable">
                                        <Link to="/d/bookmarks" title='My Bookmarks'>
                                            <div className="w90 float-center mlinkflex">
                                                <div className="micon">
                                                    <BsBookmarks />
                                                </div>
                                                <div className="mtext">
                                                    <p>My Bookmarks</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li className={`mainlink ${actTab == 4 ? "actived" : ""}`}>
                                    <div className="droplevelmain container " >
                                        <Link to="/d/discussions" title='Support'>
                                            <div className="w90 float-center mlinkflex">
                                                <div className="micon">
                                                    <BsFillChatTextFill />
                                                </div>
                                                <div className="mtext">
                                                    <p>Support</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li className={`mainlink ${actTab == 9 ? "actived" : ""}`}>
                                    <div className="droplevelmain container " >
                                        <Link to="/dashboard/notifications" title="Notifications">
                                            <div className="w90 float-center mlinkflex">
                                                <div className="micon">
                                                    <FaRegBell />
                                                </div>
                                                <div className="mtext">
                                                    <p>Notifications {newNotif != "" &&<span className='notcnt'>{newNotif}</span>}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li className={`mainlink ${actTab == 5 ? "actived" : ""}`}>
                                    <div className="droplevelmain container " >
                                        <a href="/" title="Homepage">
                                            <div className="w90 float-center mlinkflex">
                                                <div className="micon">
                                                    <ImEarth />
                                                </div>
                                                <div className="mtext">
                                                    <p>Homepage</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </li>
                                <li className={`mainlink ${actTab == 6 ? "actived" : ""}`}>
                                    <div className="droplevelmain container " >
                                        <Link to="/dashboard/profile" title="Profile">
                                            <div className="w90 float-center mlinkflex">
                                                <div className="micon">
                                                    <ImProfile />
                                                </div>
                                                <div className="mtext">
                                                    <p>Profile</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li className={`mainlink ${actTab == 7 ? "actived" : ""}`}>
                                    <div className="droplevelmain container " >
                                        <Link to="/dashboard/wallet" title="My Wallet">
                                            <div className="w90 float-center mlinkflex">
                                                <div className="micon">
                                                    <BsWallet2 />
                                                </div>
                                                <div className="mtext">
                                                    <p>My Wallet</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li className={`mainlink ${actTab == 8 ? "actived" : ""}`}>
                                    <div className="droplevelmain container " >
                                        <Link to="/dashboard/address" title="Manage Address(s)">
                                            <div className="w90 float-center mlinkflex">
                                                <div className="micon">
                                                    <FiMapPin />
                                                </div>
                                                <div className="mtext">
                                                    <p>Manage Address(s)</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li className={`mainlink ${actTab == 10 ? "actived" : ""}`}>
                                    <div className="droplevelmain container " >
                                        <Link to="/dashboard/reports" title="My Reports">
                                            <div className="w90 float-center mlinkflex">
                                                <div className="micon">
                                                    <TbReportAnalytics />
                                                </div>
                                                <div className="mtext">
                                                    <p>My Reports</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li className={`mainlink ${actTab == 11 ? "actived" : ""}`}>
                                    <div className="droplevelmain container " >
                                        <Link to="/dashboard/purchase-history" title="Purchase History">
                                            <div className="w90 float-center mlinkflex">
                                                <div className="micon">
                                                    <TbHistory />
                                                </div>
                                                <div className="mtext">
                                                    <p>Purchase History</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </li>
                                <li className="logoutbutton pc_hide" onClick={logOut}>
                                    <p><FiLogOut/> <span>Log out</span></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='dashblackside pc_hide'  onClick={() => window.$(".fxdash").css("left","-100%") }></div>
            </div>
        </div>
    </div>
  )
}
