import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Cookies, useCookies } from 'react-cookie';
import LoadingIndicator from 'react-loading-indicator'
import { useParams } from 'react-router-dom'
import seoTags from '../SeoTags';
import Menu from '../Menu';
import Footer from './Footer';
import LandingOne from '../landing/Landing One/LandingOne';
import LandingTwo from '../landing/Landing Two/LandingTwo';
import LandingThree from '../landing/Landing Three/LandingThree';
import LandingFour from '../landing/Landing Four/LandingFour';

export default function Useful() {
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [blogsData,setBlogsData] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies('');
    const newToken = cookies.userToken
    const params = useParams()
    var a =0;
    useEffect(() =>{
        if(a==0){
            fetchBlog()
        }
        a++
    },[])
    const fetchBlog = () =>{
        axios.post(global.url + "api/user/singlepage",{
            mode:Mode,
            slug:params.slug
        },{
            headers : {
                'Content-Type': 'application/json',
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            setBlogsData(res.data.page)
            seoTags(res.data.page.seodescription,res.data.page.seokeywords,res.data.page.seotitle,"","")
            setLoading(false)
        },(error) =>{
            setError(error)
            setLoading(false)
        })
    } 
    if(loading) return(
        <div className='container blogsloading'>
            <LoadingIndicator segmentWidth={4} segmentLength={9}/>
        </div>
    )
    else
  return (

    (blogsData.template === "" || blogsData.template === null) ?
    <>
        <Menu />
        {error ?
            <section className='blogs blogpage'>
                <div className='container maxwidth'>
                    <div className='w90 float-center blogpgbox'>
                        <div className='container blogscards'>
                            <p className='center-text'>{error.message}</p>
                        </div>
                    </div>
                </div>
            </section>
        :
        blogsData != "" && blogsData != null &&
        <>
            <section className='blogs blogpage'>
                <div className='container maxwidth'>
                    <div className='w90 float-center blogpgbox'>
                        <div className='container headings center-text'>
                            <div className='mainheading container' >
                                <p>{blogsData.title}</p>
                            </div>
                        </div>
                        <div className='container blogdata ck-content' dangerouslySetInnerHTML={{__html : blogsData.body}}>
                        </div>
                    </div>
                </div>
            </section>
        </>
        }
        <Footer />
    </>
    :
    <>
        {
            blogsData.template === "landingone" &&
            <LandingOne data={(blogsData.template !== null) ? JSON.parse(blogsData.templatedata) : ""} />
        }
        {
            blogsData.template === "landingtwo" &&
            <LandingTwo data={(blogsData.template !== null) ? JSON.parse(blogsData.templatedata) : ""} />
        }
        {
            blogsData.template === "landingthree" &&
            <LandingThree data={(blogsData.template !== null) ? JSON.parse(blogsData.templatedata) : ""} />
        }
        {
            blogsData.template === "landingfour" &&
            <LandingFour data={(blogsData.template !== null) ? JSON.parse(blogsData.templatedata) : ""} />
        }
    </>
  )
}