import React,{useEffect, useState} from 'react'
import { FiPlus, FiX } from 'react-icons/fi'
import CourseCard from './components/CourseCard'
import Sidebar from './Sidebar'
import TopBar from './TopBar'
import { Cookies, useCookies } from 'react-cookie';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import LoadingIndicator from 'react-loading-indicator';
import axios from 'axios'
import Chat from './chat/Chat'
import { BsCheckCircleFill } from 'react-icons/bs'
import { FiPhoneCall } from 'react-icons/fi'
import ELoader from '../eLoader'
import noActCrs from './images/actcrs.png'
import noExpCrs from './images/expcrs.png'
import noArcCrs from './images/arccrs.png'

export default function CoursesPage() {
    const [act,setAct] = useState("1")
    const [cardLoading,setCardLoading] = useState(true)
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [showMainC ,setShowMainC] = useState(true)
    const [showExpC ,setShowExpC] = useState(false)
    const [actCourses,setActCourses] = useState("")
    const [expCourses,setExpCourses] = useState("")
    const [arcCourses,setArcCourses] = useState("")
    const [showArc,setShowArc] = useState("")
    const [archiveT,setArchiveT] = useState(false)
    const [archTxt,setArchTxt] = useState(true)
    const [helpPop,setHelpPop] = useState(false)
    const [helpLoading,setHelpLoading] = useState(false)
    let User = cookies.user;
    const newToken = cookies.userToken
    var a = 0;
    useEffect(() =>{
        if(a == 0) {
            fetchCourses(1)
        }
        a++
    },[])
    const fetchCourses = (x) =>{
        setCardLoading(true)
        axios.post(global.url + "api/user/myOrders",{
            uid : User.userid,
            tab:x,
        },
        {headers : {
            'Content-Type': 'application/json',
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }})
        .then((res) =>{
            if(x == 1){
                setActCourses(res.data.courses)
            }
            if(x == 2){
            setExpCourses(res.data.courses)
            }
            if(x == 3){
                setArcCourses(res.data.courses)
            }
            setCardLoading(false)
            setLoading(false)
        },
        (error) =>{
            setError(error)
        }
        )
    }
    const showExpired = () =>{
        setAct(2)
        setShowMainC(false)
        setShowArc(false)
        setShowExpC(true)
        fetchCourses(2)
    }
    const showAct = () =>{
        setAct(1)
        setShowMainC(true)
        setShowExpC(false)
        setShowArc(false)
        fetchCourses(1)
    }
    const showArchived = () =>{
        setAct(3)
        setShowMainC(false)
        setShowArc(true)
        setShowExpC(false)
        fetchCourses(3)
    }
    const archiveCourse = (val,id) =>{
        axios.post(global.url + "api/user/archiveCourse",{
            uid : User.userid,
            id:id,
            val:val
        },
        {headers : {
            'Content-Type': 'application/json',
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }})
        .then((res) =>{
            fetchCourses()
            setArchiveT(true)
            if(val == 0){
                setArchTxt(false)
            }else{
                setArchTxt(true)
            }
            setTimeout(function(){
                setArchiveT(false)
            },3000)
        },(error) =>{
            console.log(error.message)
        })
    }
    const [helppurchId,setHelppurchId] = useState("")
    const [helpsent,setHelpSent] = useState(false)
    const clickHelp = (x) =>{
        setHelppurchId(x)
        setHelpPop(true)
    }
    const needHelp = () =>{
        setHelpLoading(true)
        axios.post(global.url + "api/user/needHelp",{
            userid : User.userid,
            purchase_id:helppurchId
        },{
            headers:{
                'Content-Type': 'application/json',
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            setHelpLoading(false)
            setHelpPop(false)
            setHelpSent(true)
            setTimeout(function(){
                setHelpSent(false)
            },2000)
        },(error) =>{
            console.log(error.message)
        })
    }
    if(loading) 
    return(
        <section className='userdashboard '>
            <div className='my100 float-left dashboxmain'>
                <div className='sidebar-box float-left'>
                    <Sidebar act={2}/>
                </div>
                <div className='dashcontent float-left'>
                    <TopBar heading="My Courses"/>
                    <div className='insideloading container'>
                        <ELoader /> 
                    </div>
                </div>  
            </div>
        </section>
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
  return (
    <>
    <section className='userdashboard '>
        <div className='my100 float-left dashboxmain'>
            <div className='sidebar-box float-left'>
                <Sidebar act={2}/>
            </div>
            <div className='dashcontent float-left'>
                <TopBar heading="My Courses"/>
                <div className='container dashwhitebox'>
                    <div className='container'>
                        <div className=' float-center tabs-top '>
                            <div className={` float-left tabsmain ${(act == "1") ? "acttab2" : ""}`} onClick={() => showAct()}>
                                <p>Active</p>
                            </div>
                            <div className={` float-left tol tabsmain ${(act == "2") ? "acttab2" : ""}`}  onClick={() => showExpired()}>
                                <p>Expired</p>
                            </div>
                            <div className={` float-left tol tabsmain ${(act == "3") ? "acttab2" : ""}`}  onClick={() => showArchived()}>
                                <p>Archived</p>
                            </div>
                        </div>
                    </div>
                    <div className='container maxwidth2'>
                        <div className='w95 float-center dashcardsflex'>
                            {showMainC && 
                            <>
                                {actCourses != "" ?
                                <CourseCard loading={cardLoading} page="Courses" data={actCourses} clickable={true} type="Active" acrh={archiveCourse} help={clickHelp}/>
                                :
                                <div className='emptybox container'>
                                    <div className='emptyimg'>
                                        <img src={noActCrs} alt="No Data Found" />
                                    </div>
                                    <div className='emptypara container'>
                                        <p>No Active Courses Present</p>
                                    </div>
                                    <div className='container start-shopbutton'>
                                        <button className="check-drop" onClick={() => window.location.href = "/"}>Start Learning</button>
                                    </div>
                                </div>
                                }
                            </>
                            }
                            {showExpC && 
                            <>
                                {expCourses != ""  ?
                                <CourseCard loading={cardLoading} page="Courses" data={expCourses} clickable={false} type="Expired"/>
                                :
                                <div className='emptybox container'>
                                    <div className='emptyimg'>
                                        <img src={noExpCrs} alt="No Data Found" />
                                    </div>
                                    <div className='emptypara container'>
                                        <p>No Expired Courses Present</p>
                                    </div>
                                </div>
                                }
                            </>
                            }
                            {showArc && 
                            <>
                                {arcCourses != ""  ?
                                <CourseCard loading={cardLoading} page="Courses" data={arcCourses} clickable={true} type="Archived" acrh={archiveCourse}/>
                                :
                                <div className='emptybox container'>
                                    <div className='emptyimg'>
                                        <img src={noArcCrs} alt="No Data Found" />
                                    </div>
                                    <div className='emptypara container'>
                                        <p>No Archived Courses Present</p>
                                    </div>
                                </div>
                                }
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Chat pathString={"Courses"} user={cookies.user} path={null}  newToken={newToken}/>
    </section>
    {archiveT &&
    <div className='repotedbox archdone'>
        <p>< BsCheckCircleFill /> Course {archTxt ? "Archived" : "Unarchived"} Successfully</p>
    </div>
    }
    {helpsent &&
    <div className='repotedbox archdone helpdone'>
        <p>< BsCheckCircleFill /> We've received your request and will get back to you shortly</p>
    </div>
    }
    {helpPop &&
        <div className='container helppop'>
            <div className='helppopflex container'>
                <div className='helppopbox'>
                    <div className='container helphead center-text'>
                        <p>Need Help</p>
                    </div>
                    {helpLoading ? 
                        <div className='helploading container'>
                            <ELoader />
                        </div>
                    : 
                    <>
                    <div className='neddhelppara container ck-content'>
                        <p>
                        Kindly note that address change facility is available only if your courier is not dispatched.
                        <br/>
                        Click on below button to send change address request.
                        </p>
                    </div>
                    <div className='container callbuthelp center-text'>
                        <button onClick={() => needHelp()}><FiPhoneCall /> Get a Call</button>
                    </div>
                    </>
                    }
                    <div className='closebut helpclose' onClick={() => setHelpPop(false)}>
                        <FiX />
                    </div>
                </div>
            </div>
        </div>
    }
    </>
  )
}
