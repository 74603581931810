import React, { useEffect, useState } from 'react';
import {Route,Routes,Navigate, useNavigate, useParams } from 'react-router-dom';
import Homepage from './homepage/Homepage';
import ScrollToTop from './scrollTop';
import { Cookies, useCookies } from 'react-cookie';
import axios from 'axios';
import Productpage from './product/Productpage';
import Category from './Category/Category';
import LoadingIndicator from 'react-loading-indicator'
import UserLogin from './auth/Login';
import SignUp from './auth/Signup';
import Cart from './cart/Cart';
import Dashboard from './dashboard/Dashboard';
import ForumPage from './dashboard/ForumPage';
import CoursesPage from './dashboard/CoursesPage';
import ForgotPass from './auth/ForgotPass';
import OTPverify from './auth/otpverify';
import Address from './cart/Address';
import Checkout from './cart/Checkout';
import OrderSuccess from './cart/OrderSuccess';
import Profile from './dashboard/profile/Profile';
import Wallet from './dashboard/wallet/Wallet';
import AddressProfile from './dashboard/profile/AddressProfile';
import PackagePage from './dashboard/PackagePage';
import CourseManager from './dashboard/coursefiles/CourseManager';
import VideoPage from './dashboard/coursefiles/VideoPage';
import Document from './dashboard/coursefiles/Document';
import ImageFile from './dashboard/coursefiles/ImageFile';
import Assignment from './dashboard/coursefiles/Assignment';
import Instructions from './dashboard/coursefiles/quiz/Instructions';
import Quiz from './dashboard/coursefiles/quiz/Quiz';
import Result from './dashboard/coursefiles/quiz/Result';
import Expired from './dashboard/coursefiles/Expired';
import NotificationsPage from './dashboard/NotificationsPage';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { app,messaging } from './Firebase';
import { getMessaging ,getToken } from "firebase/messaging";
import Blog from './blogs/Blog';
import BlogPage from './blogs/BlogPage';
import Useful from './footer/Useful';
import Reports from './dashboard/Reports';
import Bookmarks from './dashboard/Bookmarks';
import LiveClass from './dashboard/coursefiles/LiveClass';
import ELoader from './eLoader';
import TestimonialsPage from './TestimonialsPage';
import SidebarManager from './dashboard/coursefiles/SidebarManager';
import TrackOrder from './TrackOrder';
import PurchaseHistory from './dashboard/PurchaseHistory';
import NotFound from './NotFound';
import Landing from './landing/Landing';
import VideoTest from './dashboard/coursefiles/VideoTest';

function App() {
  const [cookies,setCookie,removeCookie] = useCookies('')
  const [loading,setLoading] = useState(true)
  const [error,setError] = useState(null)
  var a = 0
  useEffect(() =>{
    if(a == 0){
      generateToken()
      let myInterval = setInterval(function(){
        if(cookies.userToken !== null && cookies.userToken !== "" && cookies.userToken !== undefined ){
          checkToken(myInterval)
        }else{
          
        }
      },15000)
      if(cookies.user != null && cookies.user != undefined && cookies.user != ""){
        requestPermission()
      }
    }
    a++
  },[])
  const generateToken = () =>{
      setCookie("maintoken",JSON.stringify(defaultToken),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
      setLoading(false)
  }
  const checkToken = (myInterval) =>{
    axios.post(global.url + "api/user/checkToken",{
      token : cookies.userToken,
      userid : cookies.user.userid
    },
    {headers :{
        "content-type": "application/json",
        "accept": "application/json"
      }
    })
    .then((res) =>{
      if(res.data.user > 0){
        // console.log("Logged In")
      }else{
        removeCookie('user',{path : "/"})
        removeCookie('userToken',{path : "/"})
        setCookie("loggedOut","1",{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
        clearInterval(myInterval)
        window.location.href = '/user-login'
      }
    },
    (error) =>{
      setError(error)
    }
    )
  }


function requestPermission() {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      getToken(messaging, { vapidKey: "BMor-FXW9Jubc7LEk2gCGgFnWYvSGgQqSGgt4lJNKZeu7UNxblZJcHh2cXODDi5PKXgHH2fF34VHjp2oJQamHnk"}).then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // ...
          registerToken(currentToken)
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
    }
    else{
      console.log("Permission not granted")
    }
  })
}
const newToken = cookies.userToken
const registerToken = (currentToken) =>{
  
  fetch(global.url+"api/user/registerToken", {
    "method": "POST",
    "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
    },
    "body": JSON.stringify({
        userid: cookies.user.userid,
        col: "webtoken",
        token: currentToken
    })
    })
    .then(response => response.json()) 
    .then(response => {
      
    })
}
  if(error) {
    return <p>{error.message}</p>
  }
  else if(loading){
    return(
      <div className='container big-loading'>
          <ELoader /> 
      </div>
      )
  }
  else
  return (
    <React.Fragment>
      <ScrollToTop />
      <Routes>
          <Route exact path='/' element={<Homepage />} />
          <Route exact path='/p/:slug/:id' element={<Productpage />} />
          <Route exact path='/store/:slug/:id' element={<Category />} />
          <Route exact path='/user-login' element={<UserLogin />} />
          <Route exact path='/signup' element={<SignUp />} />
          <Route exact path='/forgot-password' element={<ForgotPass />} />
          <Route exact path='/verify-otp' element = {<OTPverify />} />
          <Route exact path='/cart' element={<Cart />} />
          <Route exact path='/dashboard' element={<Dashboard />} />
          <Route exact path='/d/discussions' element={<ForumPage />} />
          <Route exact path='/d/courses' element={<CoursesPage />} />
          <Route exact path='/d/bookmarks' element={<Bookmarks />} />
          <Route exact path='/mycart' element={<Cart/>} />
          <Route exact path='/address' element={<Address/>} />
          <Route exact path='/checkout' element={<Checkout/>} />
          <Route exact path='/success' element={<OrderSuccess/>}  forceRefresh={true}/>
          <Route exact path='/dashboard/profile' element={<Profile/>} />
          <Route exact path='/dashboard/wallet' element={<Wallet/>} />
          <Route exact path='/dashboard/address' element={<AddressProfile/>} />
          <Route exact path='/dashboard/notifications' element={<NotificationsPage/>} />
          <Route exact path='/dashboard/p/:slug/:purchaseid' element={<PackagePage/>} />
          <Route exact path='/dashboard/c/manager/:courseid/:purchaseid/:folderid' element={<CourseManager/>} />
          <Route exact path='/dashboard/c/:courseid/:purchaseid/video/:folderid' element={<VideoPage/>} />
          <Route exact path='/dashboard/c/:courseid/:purchaseid/live/:folderid' element={<LiveClass/>} />
          <Route exact path='/dashboard/c/:courseid/:purchaseid/doc/:folderid' element={<Document/>} />
          <Route exact path='/dashboard/c/:courseid/:purchaseid/assign/:folderid' element={<Assignment/>} />
          <Route exact path='/dashboard/c/:courseid/:purchaseid/img/:folderid' element={<ImageFile/>} />
          <Route exact path='/dashboard/c/:courseid/:purchaseid/quiz/:folderid' element={<Instructions/>} />
          <Route exact path='/dashboard/c/quiz/:attemptid/:courseid/:purchaseid/:folderid' element={<Quiz/>} />
          <Route exact path='/dashboard/c/result/:courseid/:purchaseid/:folderid' element={<Result/>} />
          <Route exact path='/dashboard/c/expired/:courseid/:purchaseid/:folderid' element={<Expired/>} />
          <Route exact path='/dashboard/reports' element={<Reports />} />
          <Route exact path='/dashboard/purchase-history' element={<PurchaseHistory />} />
          <Route exact path="/blog/:slug" element={<Blog />} />
          <Route exact path="/blogs" element={<BlogPage />} />
          <Route exact path="/pages/:slug" element={<Useful />} />
          <Route exact path="/testimonials" element={<TestimonialsPage />} />
          <Route exact path="/track-order" element={<TrackOrder />} />
          <Route exact path='/notfound' element={<NotFound />} />
          <Route exact path='/landing' element={<Landing />} />
          <Route path="*" element={<Navigate to="/notFound" />} />
          <Route path="/videotest" element={<VideoTest />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
