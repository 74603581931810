import React, { useState } from 'react'
import { FiChevronRight } from 'react-icons/fi'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function CourseCard(props) {
    const checkClickable = (clickable,slug,purchaseid,type,courseid) =>{
        if(clickable) {
            if(type == "Course") window.location.href = "/dashboard/c/manager/"+courseid+"/"+purchaseid+"/0"
            else window.location.href = "/dashboard/p/"+slug+"/"+purchaseid
        }
    }
  return (
    props.loading ?
    [...Array(4)].map((i,index) => {
        return (
    <div className={`dashboard-card float-left ${props.page=="Courses" ? "w33" : "w50"}`} key={index}>
        <div className='w90 float-center course-card'>
            <a href=""  title='' >
                {props.page =="Courses" &&
                <div className='container course-image'>
                    <div className='cimage'>
                        <Skeleton width="100%" height={200}/>
                    </div>
                </div> 
                }
                <div className='container cousrse-details'>
                    <div className='w90 float-center'>
                        <div className='coursename container'>
                            <p>
                                <Skeleton width="100%" height={30}/>
                            </p>
                        </div>
                        <div className='cousetags container'>
                            <ul>
                                <Skeleton width="100%" height={20}/>
                            </ul>
                        </div>
                        <div className='progress-bar container progress60'>
                            <Skeleton width="100%" height={10}/>
                        </div>
                        <div className='container cbox'>
                            <div className='container float-left'>
                                <div className='w98 float-center cardtogo'>
                                    <Skeleton width="20%" height={40}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
    )})
    :
    <>
    {props.data.map((course , i) =>{
        var coursedata = JSON.parse(course.information)
        let progclass = "";
        if(course.percentage < 30){
            progclass = "progress10"
        }
        else if(course.percentage >= 30 && course.percentage < 60){
            progclass = "progress40"
        }
        else if(course.percentage >= 60 && course.percentage < 80){
            progclass = "progress60"
        }
        else if(course.percentage >= 80){
            progclass = "progress80"
        }
    return(
    <div className={`dashboard-card coursespg float-left ${props.page=="Courses" ? "w33 coursecrd" : "w50"}`} key={i}>
        <div className='w90 float-center course-card' onClick={() => checkClickable(props.clickable,coursedata.course.slug,course.purchase_id,course.type,course.course_id)}>
            <div className='container course-image'>
                {coursedata.course.thumbnail == "" || coursedata.course.thumbnail == null || coursedata.course.thumbnail == undefined ?
                <div className={`cimage defaultimage ${props.type=="Expired" ? "expiredcourse" : ""}`}>
                    <img src={logoDef} alt={coursedata.course.name} />
                </div>
                :
                <div className={`cimage ${props.type=="Expired" ? "expiredcourse" : ""}`}>
                    <img src={global.asset + coursedata.course.thumbnail} alt={coursedata.course.name} />
                </div>
                }
            </div>  
            {coursedata.activeclass &&
                <div className='livetag'>
                    <img className='liveimg' src={liveNow} alt='' />
                </div>
            }
            <div className='container cousrse-details'>
                <div className='w90 float-center'>
                    <div className='coursename container'>
                        <p>
                            {coursedata.course.name}
                        </p>
                    </div>
                    {props.type == "Active" || props.type == "Archived" ?
                    <>
                    <div className={`progress-bar container ${progclass}`}>
                        <div className='porgbg'></div>
                        <div className='progf' style={{width : course.percentage != null ? course.percentage+"%" : "0%"}}></div>
                        <div className='container progresstext'>
                            <p>{course.percentage != null ? course.percentage : "0"}% Completed</p>
                        </div>
                    </div>
                    <div className="container validtxt">
                        <div className='container exptxt'>
                            <p>Valid Till - <span>{course.exp}</span></p>
                        </div>
                    </div>
                    <div className='container cbox'>
                        <div className='container float-left'>
                            <div className='w98 float-center cardtogo remainbx'>
                                <FiChevronRight />
                                {course.remaining_hours != "" && course.remaining_hours > 0 &&
                                    <div className='remainside'>
                                        <p>{course.remaining_hours} Hours Remaining</p>
                                    </div>
                                    }
                            </div>
                        </div>
                    </div>
                    </>
                    :
                    <></>
                    }
                </div>
            </div>
        </div>
                {props.page=="Courses" && 
                <>
                <div className='container archivebuts'>
                    <div className='w90 float-center archflex'>
                        {props.type !=="Expired" &&
                        <>
                        <div className='container'>
                            <div className='w50 float-left'>
                                <div className='archivethis'>
                                    <button onClick={() => props.type == "Active" ? props.acrh(1,course.id) : props.acrh(0,course.id)}>{props.type == "Active" ? "Archive Course" : "Unarchive Course"}</button>
                                </div>
                            </div>
                            <div className='w50 float-left'>
                            {(course.books == "1" ) &&
                                <div className='archivethis'>
                                    <button onClick={() => window.location.href = "/track-order?orderid="+course.prefixid}>Track Order</button>
                                </div>
                            }
                            </div>
                        </div>
                        <div className='container'>
                            {(course.books == "1" && course.process == "unprocessed") &&
                            <div className='archivethis'>
                                <button onClick={() => props.help(course.purchase_id)}>Change Address ?</button>
                            </div>
                            }
                        </div>
                        </>
                        }
                    </div>
                </div>
                </>}
    </div>
    )
    })}
    </>
  )
}
