import axios from 'axios'
import React,{useEffect,useState} from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Cookies, useCookies } from 'react-cookie';
import LoadingIndicator from 'react-loading-indicator';
import './manager.css'
import {BsArrowLeft,BsArrowRight} from 'react-icons/bs'
import Video from './Video';
import Feedback from './Feedback';
import Expiring from './Expiring';
import Chat from '../chat/Chat';
import ELoader from '../../eLoader';
import { FiChevronDown, FiMenu } from 'react-icons/fi';
import SidebarManager from './SidebarManager';

export default function VideoPage() {
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [videoData,setVideoData] = useState("")
    const [hindi,setHindi] = useState("")
    const [feedback,setFeedback] = useState(false)
    let User = cookies.user;
    const newToken = cookies.userToken
    const params = useParams()
    var purchaseid = params.purchaseid
    var courseid = params.courseid
    var folderid = params.folderid
    var a = 0;
    useEffect(() =>{
        if(cookies.user == "" || cookies.user == null || cookies.user == undefined) {
            window.location.href = "/"
        }
        if(a == 0) {
            fetchFileData()
        }
        a++
    },[])
    let saved = 1;
    const saveNext = (saved) =>{
        axios.post(global.url + "api/user/save_and_viewmore",{
            uid: User.userid, 
            purchase_id: purchaseid,
            course_id: courseid,
            id: folderid
        },{
            headers :{
                "content-type": "application/json",   
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        }
        )
        .then((res) =>{
            if(saved == null || saved == "" || saved == undefined) {
                setFeedback(true)
            }
            else{
                window.location.href = "/dashboard/c/manager/"+courseid+"/"+purchaseid+"/"+videoData.file.p_id
            }
        },
        (error) =>{
            setError(error)
        })
    }
    const [failed,setFailed] = useState(false)
    const location = useLocation()
    const [prevpath,setPrevPath] = useState("")

    const fetchFileData = () =>{
        axios.post(global.url + "api/user/file_information_app",{
            uid: User.userid, 
            purchase_id: purchaseid,
            course_id: courseid,
            id: folderid
        },
        {headers : {
            "content-type": "application/json",   
            "accept": "application/json",
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }}
        )
        .then((res) => {
            if(res.data.status == "Failed"){
                setFailed(true)
            }
            else{
                if(location.state != undefined && location.state != ""){
                    setPrevPath(location.state.prev)
                }
                setVideoData(res.data)
                console.log(res.data)
                if(res.data.file.encrypted_hindi_path != "" || res.data.file.encrypted_hindi_path != null || res.data.file.encrypted_hindi_path != undefined  ) setHindi(res.data.file.encrypted_hindi_path)
                else setHindi("")
            }
            setLoading(false)
            savePercentage()
        },
        (error) =>{
            setError(error)
        })
    }
    const savePercentage = () => {
        fetch(global.url+"api/user/savePercentage", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            },
            "body": JSON.stringify({
                uid: User.userid,
                purchase_id: purchaseid,
                course_id: courseid,
            })
            })
            .then(response => response.json()) 
            .then(response => { })
    }
    const opacityOne = () =>{
        $('.languagesbox').show();
        $('.forwardbutton').show()
        if($('.languagesbox').is(":visible")){
            setTimeout(function(){
                $('.languagesbox').hide();
                $('.forwardbutton').hide()
            },5000)
        } else{
            $('.languagesbox').show();
            $('.forwardbutton').show()
        }
    }
    const opacityZero = () =>{
        $('.languagesbox').hide()
        $('.forwardbutton').hide()
    }
    const [actedMenu,setActedMenu] = useState(false)
    const clickMenu = () =>{
        if($(window).width() < 900){
            $('.sidebarside').css("left","0%");
        }else{
            $('.contentside').css("width","100%");
            $('.sidebarside').css("width","0%");
            setActedMenu(true)
        }
    }
    const showSide = () =>{
        setActedMenu(false)
        if($(window).width() > 900){
            $('.contentside').css("width","calc(100% - 320px)");
            $('.sidebarside').css("width","320px");
        }
    }
    
    const faqSlides = (id) =>{
        var icon = window.$(".tabbox[data-bind="+id+"]").find(".tabicon");
        if(icon.hasClass("showed")){
            window.$("#"+id).slideUp()
            icon.removeClass("showed")
        }else{
            window.$("#"+id).slideDown()
            icon.addClass("showed")
        }
    }
    if(loading) 
    return(
        <section className='videopage'>
            <div className='container'>
                <div className='w90 float-center  videobox-vidpage loadingbxvid'>
                    <ELoader /> 
                </div>
            </div>
        </section>
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
  return (
    <>
    
    <section className='sidebarmanager'>
        {failed ? 
            <div className='videopage container'>
                <div className='container topbuttonsvids maxwidth'>
                    <div className='w90 float-center flx-vids'>
                        {prevpath != "" &&
                            <a href={prevpath} title='Go Back' className='backbutton'><BsArrowLeft /> Back</a>
                        }
                    </div>
                </div>
                <div className='container'>
                    <div className='w90 float-center videobox-vidpage' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <div className='pdfcontainer container'>
                            <div className='nodataimg'>
                                <img src={noDataImg} alt="No data found" />
                            </div>
                            <div className='container nofiles'>
                                <p>This files was not found or has been removed</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :
        <div className='my100 sidebarflexmng'>
            <div className='sidebarside'>
                <SidebarManager folderid={folderid} parentid={videoData.file.p_id} purchaseid={purchaseid} courseid={courseid}/>
            </div>
            <div className='contentside'>
                <div className='container topsidecontent'>
                    <div className='w90 float-center topflflex'>
                        <div className='topnameside'>
                            <div className='closemenu' onClick={() => (actedMenu) ? showSide() : clickMenu()}>
                                <FiMenu />
                            </div>
                            <div className='topmname'>
                                <p>{videoData.file.name}</p>
                            </div>
                        </div>
                        <div className='rightvidbuttons'>
                            {videoData.file.prevvideo != null &&
                                <a href={"/dashboard/c/" + courseid + "/"+ purchaseid + "/video/"  + videoData.file.prevvideo.id} title="Previous">
                                    <button title='Save and Continue' className='nextbutton'><BsArrowLeft />Previous </button>
                                </a>
                            }
                            {videoData.file.nextvideo != null &&
                                <a href={"/dashboard/c/" + courseid + "/"+ purchaseid + "/video/"  + videoData.file.nextvideo.id} title="Next">
                                    <button title='Save and Continue' className='nextbutton'>Next <BsArrowRight /></button>
                                </a>
                            }
                            <button title='Save and Continue' className='nextbutton' onClick={() => saveNext(videoData.feedback)}>Save and Continue <BsArrowRight /></button>
                        </div>
                    </div>
                </div>
                <div className='videopage container'>
                    <div className='container'>
                        <div className='w90 float-center videobox-vidpage'>
                            <div className='videocontainer container' onMouseEnter={opacityOne} onMouseLeave={opacityZero}>
                                {videoData.file.type == "Youtube" ?
                                <iframe src={videoData.file.youtube_url} className="youtubevideopage"></iframe>
                                :
                                <>
                                    <Video from="courseinside" seek={videoData.file.seek} visitid={videoData.file.visitid} vidid={videoData.file.id} id={videoData.file.fileid} type={"encrypt"} user={User} hindi={hindi} courseid={courseid} purchaseid={purchaseid} nextUrl={videoData.file.nextvideo}/>
                                </>
                                }
                            </div>
                            {(videoData.file.description != undefined &&  videoData.file.description != null && videoData.file.description != "" ) && 
                            <div className='videodata container'>
                                <div className='w95 float-center ck-content' dangerouslySetInnerHTML={{__html : videoData.file.description}}>
                                    
                                </div>
                            </div>
                            }
                            {(videoData.file.faqs != undefined && videoData.file.faqs != null &&  videoData.file.faqs != "" && videoData.file.faqs.length > 0 ) &&
                            <div className='container videofaqs'>
                                <div className='container faqqstitle center-text'>
                                    <p>Frequently Asked Questions</p>
                                </div>
                                <div className='container faqslist'>
                                    <div className='w90 float-center'>
                                        {videoData.file.faqs.map((faq,i) =>
                                        { 
                                        var question = faq.faqdata.question.replace(/(<img[^>]+>(?:<\/img>)?)/g, "..."); 
                                        question = question.replace(/<\/?[^>]+(>|$)/g, "");
                                        return(
                                        <div className='container tabboxes' key={i}>
                                            <div className='container tabbox' data-bind={`faq${i}`} onClick={() => faqSlides(`faq${i}`)}>
                                                <div className='w10 float-left center-text iconside'>
                                                    <div className='w90 float-center tabicon'>
                                                        <FiChevronDown />
                                                    </div>
                                                </div>
                                                <div className='w90 float-left'>
                                                    <div className='w98 float-left'>
                                                        <p>{question}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container tabcontent" id={`faq${i}`}>
                                                <div className='w95 float-center ck-content description-box'>
                                                    <div className='question container'>
                                                        <p style={{fontWeight:600,fontSize:16,color:"rgba(0,0,0,0.8)"}}>Question</p>
                                                    </div>
                                                    <div className='ck-content quest' style={{marginBottom : 15}} dangerouslySetInnerHTML={{__html:faq.faqdata.question}}>

                                                    </div>
                                                    <div className='question container'>
                                                        <p style={{fontWeight:600,fontSize:16,color:"rgba(0,0,0,0.8)"}}>Answer</p>
                                                    </div>
                                                    <div className='ck-content quest' dangerouslySetInnerHTML={{__html:faq.faqdata.answer}}>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        )})}
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
    </section>
    
    {feedback &&
        <Feedback type={videoData.file.type} purchaseid={purchaseid} folderid={folderid} courseid={courseid} userid={User.userid} p_id={videoData.file.p_id}/>
    }
    
    <Expiring user={User} purchaseid={purchaseid} folderid={folderid} courseid={courseid} token={newToken}/>
    {videoData.showchat !== null && 
        <Chat pathString={"Inside_Course_Video"} user={cookies.user} path={{course_id: courseid, purchase_id:purchaseid, pathList: videoData.path, type:"Inside_Course_Video",filetype:"Video"}}  newToken={newToken}/>
    }
    </>
  )
}
