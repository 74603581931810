import axios from 'axios'
import React,{useEffect,useState} from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Cookies, useCookies } from 'react-cookie';
import LoadingIndicator from 'react-loading-indicator';
import './manager.css'
import {BsArrowLeft,BsArrowRight} from 'react-icons/bs'
import Video from './Video';
import Feedback from './Feedback';
import Expiring from './Expiring';
import Chat from '../chat/Chat';
import { BiDownload } from 'react-icons/bi';
import JsFileDownloader from 'js-file-downloader';
import ELoader from '../../eLoader';
import { FiMenu } from 'react-icons/fi';
import SidebarManager from './SidebarManager';

function crypt (salt, text){
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  
    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
  }
export default function Document() {
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [videoData,setVideoData] = useState("")
    const [hindi,setHindi] = useState("")
    const [feedback,setFeedback] = useState(false)
    const [encrypted, setEncrypted] = useState("")
    let User = cookies.user;
    const newToken = cookies.userToken
    const params = useParams()
    var purchaseid = params.purchaseid
    var courseid = params.courseid
    var folderid = params.folderid
    var a = 0;
    const location = useLocation()
    const [prevpath,setPrevPath] = useState("")
    useEffect(() =>{
        if(cookies.user == "" || cookies.user == null || cookies.user == undefined) {
            window.location.href = "/"
        }
        if(a == 0) {
            fetchFileData()
        }
        a++
    },[])
    let saved = 1;
    const saveNext = (saved) =>{
        axios.post(global.url + "api/user/save_and_viewmore",{
            uid: User.userid, 
            purchase_id: purchaseid,
            course_id: courseid,
            id: folderid
        },{
            headers :{
                "content-type": "application/json",   
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        }
        )
        .then((res) =>{
            if(saved == null || saved == "" || saved == undefined) {
                setFeedback(true)
            }
            else{
                window.location.href = "/dashboard/c/manager/"+courseid+"/"+purchaseid+"/"+videoData.file.p_id
            }
        },
        (error) =>{
            setError(error)
        })
    }
    const [failed,setFailed] = useState(false)
    const fetchFileData = () =>{
        axios.post(global.url + "api/user/file_information_app",{
            uid: User.userid, 
            purchase_id: purchaseid,
            course_id: courseid,
            id: folderid
        },
        {headers : {
            "content-type": "application/json",   
            "accept": "application/json",
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }}
        )
        .then((res) => {
            if(res.data.status == "Failed"){
                setFailed(true)
            }
            else{
                setVideoData(res.data)
                if(location.state != undefined && location.state != ""){
                    setPrevPath(location.state.prev)
                } 
                setEncrypted(crypt("Omesh@558",res.data.file.fileid.toString()))
                if(res.data.file.encrypted_hindi_path != "" || res.data.file.encrypted_hindi_path != null || res.data.file.encrypted_hindi_path != undefined  ) setHindi(res.data.file.encrypted_hindi_path)
                else setHindi("")
            }
            setLoading(false)
            savePercentage()
        },
        (error) =>{
            setError(error)
        })
    }
    const savePercentage = () => {
        fetch(global.url+"api/user/savePercentage", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            },
            "body": JSON.stringify({
                uid: User.userid,
                purchase_id: purchaseid,
                course_id: courseid,
            })
            })
            .then(response => response.json()) 
            .then(response => { })
    }
    const downloadFile = (url,namet) =>{
      new JsFileDownloader({ 
        url: url,
        forceDesktopMode: true,
        nameCallback: function(name) {
                return namet+'.pdf';
        }
      })
      .then(function () {
        setTimeout(function(){
          setButtonloading(false)
        },500)
      })
      .catch(function (error) {
        console.log(error)
      });
    }
    const [actedMenu,setActedMenu] = useState(false)
    const clickMenu = () =>{
        $('.contentside').css("width","100%");
        $('.sidebarside').css("width","0%");
        setActedMenu(true)
    }
    const showSide = () =>{
        $('.contentside').css("width","calc(100% - 320px)");
        $('.sidebarside').css("width","320px");
        setActedMenu(false)
    }
    if(loading) 
    return(
        <section className='videopage'>
            <div className='container maxwidth'>
                <div className='w90 float-center  videobox-vidpage loadingbxvid'>
                    <ELoader /> 
                </div>
            </div>
        </section>
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
  return (
    <>
    <section className='sidebarmanager'>
    {failed ? 
    <div className='videopage container'>
        <div className='container topbuttonsvids maxwidth'>
            <div className='w90 float-center flx-vids'>
                {prevpath != "" &&
                    <a href={prevpath} title='Go Back' className='backbutton'><BsArrowLeft /> Back</a>
                }
            </div>
        </div>
        <div className='container maxwidth'>
            <div className='w90 float-center videobox-vidpage' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div className='pdfcontainer container'>
                    <div className='nodataimg'>
                        <img src={noDataImg} alt="No data found" />
                    </div>
                    <div className='container nofiles'>
                        <p>This files was not found or has been removed</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    :
    <div className='my100 sidebarflexmng'>
        <div className='sidebarside'>
            <SidebarManager folderid={folderid} parentid={videoData.file.p_id} purchaseid={purchaseid} courseid={courseid}/>
        </div>
            <div className='contentside'>
                <div className='my100'>
                    <div className='container topsidecontent'>
                        <div className='w90 float-center topflflex'>
                            <div className='topnameside'>
                                <div className='closemenu' onClick={() => (actedMenu) ? showSide() : clickMenu()}>
                                    <FiMenu />
                                </div>
                            </div>
                            <div className='rightvidbuttons'>
                                <button title='Save and Continue' className='nextbutton' onClick={() => saveNext(videoData.feedback)}>Save and Continue <BsArrowRight /></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='videopage container'>
                    <div className='container topbuttonsvids maxwidth'>
                        <div className='w90 float-center flx-vids'>
                            {prevpath != "" &&
                                <a href={prevpath} title='Go Back' className='backbutton'><BsArrowLeft /> Back</a>
                            }
                            
                        </div>
                    </div>
                    <div className='container maxwidth'>
                        <div className='w90 float-center videobox-vidpage'>
                            <div className='container videonambox'>
                                <div className='w95 float-center videonmflx'>
                                    <p>{videoData.file.name}</p>
                                    {videoData.file.download == 1 && 
                                        <button title='Download File' className='nextbutton nextupload' onClick={() => downloadFile(global.url + videoData.file.document , videoData.file.name )}>Download File <BiDownload /></button>
                                    }
                                </div>
                            </div>
                            <div className='pdfcontainer container'>
                                <iframe src={'/pdfview/web/viewer.html?src='+encrypted+'&e='+crypt("Omesh@558", User.email)+'&c='+crypt("Omesh@558", (User.contact === null) ? ' ' : User.contact)} style={{width:"100%", height:"calc(100vh - 100px)",border:0}}></iframe>
                            </div>
                            {videoData.file.description != "" && videoData.file.description != null && videoData.file.description != undefined &&
                            <div className='videodata container'>
                                <div className='w95 float-center ck-content' dangerouslySetInnerHTML={{__html : videoData.file.description}}>
                                    
                                </div>
                            </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
</section>
    {feedback &&
        <Feedback type={videoData.file.type} purchaseid={purchaseid} folderid={folderid} courseid={courseid} userid={User.userid} p_id={videoData.file.p_id}/>
    }
    <Expiring user={User} purchaseid={purchaseid} folderid={folderid} courseid={courseid} token={newToken}/>
    {videoData.showchat !== null && 
        <Chat pathString={"Inside_Course_Document"} user={cookies.user} path={{course_id: courseid, purchase_id:purchaseid, pathList: videoData.path, type:"Inside_Document",filetype:"Document"}}  newToken={newToken}/>
    }
    </>
  )
}
