import React, { useState } from 'react'
import { useEffect } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { useLocation, useNavigate } from 'react-router-dom';
import shopped from './shopped.png'
import { Cookies, useCookies } from 'react-cookie';
import Menu from '../Menu';
import Footer from '../footer/Footer';

export default function OrderSuccess() {
    const [fireAnimation,setFireAnimation] = useState(false)
    const [cookies,setCookie,removeCookie] = useCookies('')
    const style = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1,
        top:"0px",
        left:0
      };
      const navigate = useNavigate()
      const location = useLocation()
      const user = cookies.user
      useEffect(() =>{
        if(user == "" || user == null || user == undefined) {
            navigate("/")
        }
        else{
            onClickFire()
        }
      },[])
      const onClickFire = () => {
        // set any value that is cast to the logical true and will differ from the previous one.
        setFireAnimation(true)
        console.log("run")
      }
  return (
    <React.Fragment>
        <Menu />
        <section className='sucesspage'>
            <div className='container maxwidth'>
            <ReactCanvasConfetti
                // set the styles as for a usual react component
                style={style}
                // set the class name as for a usual react component
                className={'yourClassName'}
                // if value in this.state.fire cast to the logical true and will differ from the previous, then will be called new animation
                fire={fireAnimation}
                particleCount = {300}
                spread = {150}
                origin = {0}
                />
                <div className='orderporp float-center center-text'>
                    <div className='w90 float-center'>
                        <div className='successimage container'>
                            <div className='sucimg'>
                                <img src={shopped} alt="Success"/>
                            </div>
                        </div>
                        <div className='container orplacedtext'>
                            <p>Your Course / Package has been added to your library</p>
                        </div>
                        <div className='container continueshop'>
                            <a href='/' title=''>Continue Shopping</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </React.Fragment>
  )
}
