import React,{useState,useEffect} from 'react'
import Sidebar from '../Sidebar'
import TopBar from '../TopBar'
import { BiEditAlt } from 'react-icons/bi'
import { MdPassword } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import {HiArrowRight} from 'react-icons/hi'
import {FiEdit,FiCheck, FiX} from 'react-icons/fi'
import { Cookies, useCookies } from 'react-cookie';
import LoadingIndicator from "react-loading-indicator";
import defaultProfile from '../images/dashprofile.png'
import sideImage1 from '../images/ep.png'
import sideImage2 from '../images/cp.png'
import axios from 'axios'
import CheckLogin from '../../auth/CheckLogin'
import PhoneInput from 'react-phone-input-2'

export default function Profile() {
    const [act,setAct] = useState("1")
    const [userName,setUserName] = useState("")
    const [newNumber,setNewNumber] = useState("")
    const [displays,setDisplays] = useState(false)
    const [passpop,setPassPop] = useState(false)
    const [userImg,setUserImg] = useState("")
    const [ErruserImg,setErruserImg] = useState("")
    const [ErruserName,setErruserName] = useState("")
    const [ErrnewNumber,setErrNewNumber] = useState("")
    const [email,setEmail] = useState("")
    const [src2,setsrc2] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [isLoading,setIsLoading] = useState(true)
    const [error,setError] = useState("")
    const [errormsg,setErrormsg] = useState("")
    const [accountPop,setAccountPop] = useState(false)
    const [deletedSuc,setDeletedSuc] = useState(false)
    const [deletedMsg,setDeletedMsg] = useState("")
    const [requested,setRequested] = useState(false)
    const navigate = useNavigate()
    
    let User = cookies.user;
    const newToken = cookies.userToken
    
    useEffect(() =>{
            fetchUser()
    },[])
    const fetchUser = () =>{
        setIsLoading(true)
        if(User == "" || User == null){
            setUserImg(defaultProfile)
            navigate("/",{replace : true})
        }
        else if(cookies.user.emailverified == "" && cookies.user.emailverified == null){
            navigate("/verify-otp")
        }else{
            setUserName(User.name)
            setEmail(User.email)
            setNewNumber(User.contact)
            setUserImg(User.image)
            getUser()
        }
        setIsLoading(false)
    }
    const updateProfile = (e) =>{
        e.preventDefault()
        setErrormsg("")
        if(userName == ""){
            setErruserName("Please enter valid name")
        }else if(newNumber == "" || newNumber.length < 10){
            setErrNewNumber("Please enter valid Number")
        }else{
            setIsLoading(true)  
            const formData = new FormData()
            formData.append('userid',User.userid)
            formData.append('img',userImg)
            formData.append('name',userName)
            // formData.append('contact',newNumber)
            fetch(global.url + "api/user/updateProfile",{
                headers : {
                    "accept": "application/json",
                    "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
                },
                method : "POST",
                body  : formData
            })
            .then(res => res.json())
            .then(res =>{
                if(res.status == "OK"){ 
                    setCookie('user',JSON.stringify(res.user),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
                    setIsLoading(false)
                    setDisplays(true)
                }
                else if(res.status === "Failed"){ 
                    setErrormsg(res.message)
                }
                setIsLoading(false)
            }).catch(error =>{
                setError(error)
            })
        }
    }
    const nameNew = (val) =>{
        setUserName(val.target.value)
        $('#applybut').prop("disabled", false)
    }
    const getNum = (val) =>{
        setNewNumber(val.target.value)
        $('#applybut').prop("disabled", false)
    }
    const getImg = (val) =>{
        setUserImg(val.target.files[0])
        $('#applybut').prop("disabled", false)
        setsrc2(URL.createObjectURL(val.target.files[0]))
    }
    // ****************************Password  *************************
    const [oldPass,setOldPass] = useState("")
    const [erroldPass,setErrOldPass] = useState("")
    const [confPass,setConfPass] = useState("")
    const [errConfPass,setErrConfPass] = useState("")
    const [newPass,setNewPass] = useState("")
    const [errnewPass,setErrNewPass] = useState("")
    
    const getoldPass = (val) =>{
        setOldPass(val.target.value)
    }
    const getnewPass = (val) =>{
        setNewPass(val.target.value)
    }
    const getconfPass = (val) =>{
        setConfPass(val.target.value)
    }

    const changePass = (e) =>{
        e.preventDefault()
        setErrNewPass("")
        setErrConfPass("")
        setErrOldPass("")
        if(newPass < 8){
            setErrNewPass("Password must be at least 8 characters long")
        }
        if(newPass != confPass){ 
            setErrConfPass("Confirm Passwords don't match")
        }
        else{
            setIsLoading(true)
            axios({
                method: 'post',
                url: global.url+"api/user/updatePassword",
                data: {
                    userid : User.userid,
                    old : oldPass,
                    password : newPass,
                    confpassword : confPass
                },
                headers: {
                    "accept": "application/json",
                    "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
                }
            })
            .then(res =>{
                if(res.data.status == "OK"){
                    setOldPass("")
                    setNewPass("")
                    setConfPass("")
                    setErrOldPass("")
                    setDisplays(true)
                    setPassPop(true)
                }else{
                    setErrOldPass(res.data.message)
                }
            })
            .then(() => {
                setIsLoading(false)
            }).catch(error =>{
                setError(error)
            })
        }
    }
    const sendDeleteReq = () =>{
        axios.post(global.url + "api/user/removeMyAccount",{
            userid : User.userid
        },{
            headers: {
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            setDeletedMsg(res.data.message)
            setRequested(true)
            setAccountPop(false)
            setDeletedSuc(true)
        },(error) =>{
            console.log(error.message)
        })
    }
    const getUser = () =>{
        axios.post(global.url + "api/user/getMyUserProfile",{
            userid : User.userid
        },{
            headers: {
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            if(res.data.user.removerequest == "1"){
                setRequested(true)
            }else{
                setRequested(false)
            }
        },(error) =>{
            console.log(error.message)
        })
    }
    
    const [emailPopup, setEmailPopup] = useState(false)
    const [contactPopup, setContactPopup] = useState(false)
    const [emailNew, setEmailNew] = useState(cookies.user.email)
    const [contactNew, setContactNew] = useState(cookies.user.contact)
    const [otp, setOtp] = useState("")
    const [showOtpBox, setShowOtpBox] = useState(false)
    const [otpLoading, setOtpLoading] = useState("")
    const [timecount, setTimeCount] = useState(90)
    const [myErr, setMyerr] = useState("")
    const [cc, setCC] = useState( (cookies.user.ccinfo !== null) ? (JSON.parse(cookies.user.ccinfo)['cc'] !== null) ? JSON.parse(cookies.user.ccinfo)['cc'] : "91" : "91")
    const [ccf, setCCF] = useState( (cookies.user.ccinfo !== null) ? (JSON.parse(cookies.user.ccinfo)['ccf'] !== null) ? JSON.parse(cookies.user.ccinfo)['ccf'] :"IN" : "IN")

    const sendOTP = (type) => {
        if(!otpLoading) {
            setOtpLoading(true)
            fetch(global.url + "api/user/sendOtp", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
                },
                "body": JSON.stringify({
                    userid : User.userid,
                    type: type,
                    val: (type === "email") ? emailNew : contactNew
                })
                })
                .then(response => response.json()) 
                .then(response => {
                    if(response.status === "OK"){
                        setShowOtpBox(true)
                        setTimeCount(90)
                    }else if(response.status === "Failed"){
                        setMyerr(response.message)
                    }
                    setOtpLoading(false)
                })
                .catch(err => {
                    console.log(err)
            });
        }
    }

    const resendOtp = (type) => {
        if(!otpLoading){
            setOtpLoading(true)
            fetch(global.url+"api/user/resend_otp", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                },
                "body": JSON.stringify({
                    "user" : User.userid,
                    "type" : type,
                })
                })
                .then(response => response.json())
                .then(response => {
                    if(response.status === "OK"){
                        setTimeCount(90)
                    }else if(response.status === "Failed"){
                        setMyerr(response.message)
                    }
                    setOtpLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setOtpLoading(false)
            });
        }
    }


    const verifyOtpInside = (type) => {
        if(!otpLoading){
            console.log("Asdasdasd", global.url+"api/user/verifyOtpInside")
            setOtpLoading(true)
            fetch(global.url+"api/user/verifyOtpInside", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                },
                "body": JSON.stringify({
                    "userid" : User.userid,
                    "type" : type,
                    "val" : type === "email" ?  emailNew : contactNew,
                    "otp" : otp,
                    "cc" : cc,
                    "ccf" : ccf.toUpperCase()
                })
                })
                .then(response => response.json())
                .then(response => {
                    if(response.status === "OK"){
                        setShowOtpBox(false)
                        setEmailPopup(false)
                        setContactPopup(false)
                        setTimeCount(0)
                        getDashboard()
                    }else if(response.status === "Failed"){
                        setMyerr(response.message)
                    }
                    setOtpLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setOtpLoading(false)
            });
        }
    }
    const getDashboard = () => {
        
        fetch(global.url+"api/user/dashboard", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            },
            "body": JSON.stringify({
                userid: User.userid,
            })
            })
            .then(response => response.json()) 
            .then(response => {
                if(response.status === "OK"){
                    setCookie('user',JSON.stringify(response.dashboard.user),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
                }
            })
            .catch(err => {
                
            }); 
    }

  return (
    <>
    {
        emailPopup &&
        <div className='myPopup'>
            <div className='myPopupBody'>
                <div className='closeMyPopup' onClick={() => setEmailPopup(false)}>
                    <FiX />
                </div>
                <p className='center-text'><strong>Verify Email Address</strong></p>
                
                {
                    myErr !== "" &&
                    <div >
                        <p className='center-text' style={{fontSize:14, padding:"10px", cursor:"pointer"}}><strong style={{color:"red"}}>{myErr}</strong></p>
                    </div>
                }
                {
                    !showOtpBox &&
                    <>
                        <div className='container ins'>
                            <input 
                                className='myInput center-text'
                                type="text" 
                                onChange={(e) => setEmailNew(e.target.value)}
                                value={emailNew}
                                required
                                placeholder='Enter Your Email'
                            ></input>
                        </div>
                        <div>
                            <button className='myBtn' onClick={() => sendOTP("email")}>
                                {
                                    otpLoading ?
                                    "Sending OTP..."
                                    :
                                    "Send OTP"
                                }
                                
                            </button>
                        </div>
                    </>
                }
                
                {
                    showOtpBox &&
                    <>
                        <div className='container ins'>
                            <input 
                                className='myInput center-text'
                                type="text" 
                                onChange={(e) => setOtp(e.target.value)}
                                value={otp}
                                required
                                placeholder='Enter Your OTP'
                            ></input>
                        </div>
                        <div>
                            <button className='myBtn' onClick={() => verifyOtpInside("email")}>
                                {
                                    otpLoading ?
                                    "Verifying OTP..."
                                    :
                                    "Verify OTP"
                                }
                                
                            </button>
                        </div>
                        <div onClick={() => resendOtp("email")} >
                            <p className='center-text' style={{fontSize:14, padding:"10px", cursor:"pointer"}}>Didn't Received OTP?&nbsp;<strong>Resend?</strong></p>
                        </div>
                    </>
                }
            </div>
        </div>
    }

{
        contactPopup &&
        <div className='myPopup'>
            <div className='myPopupBody'>
                <div className='closeMyPopup' onClick={() => setContactPopup(false)}>
                    <FiX />
                </div>
                <p className='center-text'><strong>Change Phone no</strong></p>
                
                {
                    myErr !== "" &&
                    <div >
                        <p className='center-text' style={{fontSize:14, padding:"10px", cursor:"pointer"}}><strong style={{color:"red"}}>{myErr}</strong></p>
                    </div>
                }
                {
                    !showOtpBox &&
                    <>
                        <div className='container ins' style={{border:"0.5px solid #000000", borderRadius:"5px", margin:"10px 0"}}>
                            <PhoneInput
                                country={ccf.toLowerCase()}
                                value={contactNew}
                                enableSearch={true}
                                inputProps={{
                                    name: 'contact',
                                    required: false
                                }}
                                onChange={(phone, country) => {
                                    setContactNew(phone)
                                    setCC(country.dialCode)
                                    setCCF(country.countryCode)
                                }}
                            />
                            {/* <input 
                                className='myInput center-text'
                                type="text" 
                                onChange={(e) => setContactNew(e.target.value)}
                                value={contactNew}
                                required
                                placeholder='Enter Your Contact No'
                            ></input> */}
                        </div>
                        <div>
                            <button className='myBtn' onClick={() => sendOTP("contact")}>
                                {
                                    otpLoading ?
                                    "Sending OTP..."
                                    :
                                    "Send OTP"
                                }
                                
                            </button>
                        </div>
                    </>
                }
                
                {
                    showOtpBox &&
                    <>
                        <div className='container ins'>
                            <input 
                                className='myInput center-text'
                                type="text" 
                                onChange={(e) => setOtp(e.target.value)}
                                value={otp}
                                required
                                placeholder='Enter Your OTP'
                            ></input>
                        </div>
                        <div>
                            <button className='myBtn' onClick={() => verifyOtpInside("contact")}>
                                {
                                    otpLoading ?
                                    "Verifying OTP..."
                                    :
                                    "Verify OTP"
                                }
                                
                            </button>
                        </div>
                        <div onClick={() => resendOtp("contact")} >
                            <p className='center-text' style={{fontSize:14, padding:"10px", cursor:"pointer"}}>Didn't Received OTP?&nbsp;<strong>Resend?</strong></p>
                        </div>
                    </>
                }
            </div>
        </div>
    }
    <section className='userdashboard '>
        <div className='my100 float-left dashboxmain'>
            <div className='sidebar-box float-left'>
                <Sidebar act={6}/>
            </div>
            <div className='dashcontent float-left'>
                <TopBar heading="My Profile"/>
                <div className='container profilebox maxwidth2'>
                    <div className='container'>
                        <div className='container'>
                            <div className=' float-center tabs-top '>
                                <div className={` float-left tabsmain ${(act == "1") ? "acttab2" : ""}`} onClick={() => setAct(1)}>
                                    <p> <BiEditAlt /> Edit Your Profile</p>
                                </div>
                                <div className={` float-left tol tabsmain ${(act == "2") ? "acttab2" : ""}`}  onClick={() => setAct(2)}>
                                    <p><MdPassword />Change Password</p>
                                </div>
                            </div>
                        </div>
                        <div className='container'>
                            <div className='w70 float-center profbx'>
                                {isLoading ? 
                                <div className='insideloadinf w90 float-center'>
                                    <LoadingIndicator segmentWidth={4} segmentLength={9}/> 
                                </div>
                                :
                                <div className='w90 float-center profilemain'>
                                    {(act == "1") &&
                                    <>
                                    <div className='container profile-heading'>
                                        <div className='w90 float-center center-text'>
                                            <p>Edit your Profile</p>
                                        </div>
                                    </div>
                                    <div className='profiles container'>
                                        <div className='container '>
                                            <div className='w98 float-center details'>
                                                <div className='w60 float-left'>
                                                    <form onSubmit={updateProfile}>
                                                        <div className='profileimg container'>
                                                            <div className='profileimageinside'>
                                                                {(User.image == "" || User.image == null) ?
                                                                    <img src={(src2 == "") ? defaultProfile : src2} alt='Profile Image'  referrerPolicy='no-referrer'/>
                                                                    :
                                                                    (
                                                                    (User.image.startsWith("https")) ?
                                                                    <img src={(src2 == "") ? User.image : src2} alt='Profile Image' referrerPolicy='no-referrer' />
                                                                    :
                                                                    <img src={(src2 == "") ? global.asset + User.image : src2} alt='Profile Image' referrerPolicy='no-referrer' />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='container changeimg'>
                                                            <input type="file"  onChange={getImg} id="changeimg"></input>
                                                            <label htmlFor='changeimg'><FiEdit/> Change Image</label>
                                                        </div>
                                                        <div className='container ins'>
                                                            <input 
                                                            type="text" 
                                                            onChange={nameNew}
                                                            value={userName}
                                                            required
                                                            placeholder='Enter Your Name'
                                                            ></input>
                                                            <p>{ErruserName}</p>
                                                        </div>
                                                        {/* <div className='container ins'>
                                                            <input type="number"
                                                            required
                                                            onChange={getNum}
                                                            value={newNumber}
                                                            placeholder='Enter Your Phone Number'
                                                            ></input>
                                                            <p>{ErrnewNumber}</p>
                                                        </div> */}
                                                        <div className='container ins notedit'>
                                                            <input type="email"
                                                            required
                                                            readOnly
                                                            value={email}
                                                            ></input>
                                                            <div className="container profilesubtext">
                                                                {cookies.user.email_verified_at != undefined || cookies.user.email_verified_at === "" || cookies.user.email_verified_at === null &&
                                                                <p style={{color:"red", fontWeight:"bold", cursor:"pointer"}} >Verify Email</p>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='errormessage container'>
                                                            <p>{errormsg}</p>
                                                        </div>
                                                        
                                                        <div onClick={() => setContactPopup(true)} >
                                                            <p className='center-text' style={{fontSize:14, padding:"10px", cursor:"pointer"}}>+{contactNew}&nbsp;<strong style={{color:"red"}}>Change?</strong></p>
                                                        </div>

                                                        <div className='container ins applybuttons'>
                                                            <button disabled id='applybut'>Apply <HiArrowRight /></button>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className='w40 float-left'>
                                                    <div className='w90 float-center'>
                                                        <div className='prof-img'>
                                                            <img src={sideImage1} alt="Edit Profile" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </> 
                                    }
                                    {
                                    (act == "2") &&
                                    <>
                                    <div className='container profile-heading'>
                                        <div className='w90 float-center center-text'>
                                            <p>Change Password</p>
                                        </div>
                                    </div>
                                    <div className='profiles container'>
                                        <div className='container '>
                                            <div className='w98 float-center details'>
                                                <div className='w60 float-left'>
                                                    <form onSubmit={changePass}>
                                                        <div className='container ins'>
                                                            <input 
                                                            type="password" 
                                                            placeholder='Enter Your Old Password '
                                                            value={oldPass}
                                                            onChange={getoldPass}
                                                            required
                                                            ></input>
                                                        </div>
                                                        <div className='container ins'>
                                                            <input 
                                                            type="password" 
                                                            placeholder='Enter Your New Password '
                                                            value={newPass}
                                                            onChange={getnewPass}
                                                            required
                                                            ></input>
                                                            <p>{errnewPass}</p>
                                                        </div>
                                                        <div className='container ins'>
                                                            <input 
                                                            type="password" 
                                                            placeholder='Confirm Your New Password '
                                                            value={confPass}
                                                            onChange={getconfPass}
                                                            required
                                                            ></input>
                                                            <p>{errConfPass}</p>
                                                        </div>
                                                        <div className='errormessage container'>
                                                            <p>{erroldPass}</p>
                                                        </div>
                                                        <div className='container ins applybuttons'>
                                                            <button id='applybutton2'>Apply <HiArrowRight /></button>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className='w40 float-left'>
                                                    <div className='w90 float-center'>
                                                        <div className='prof-img'>
                                                            <img src={sideImage2} alt="Change Password" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    }
                                </div>
                                }
                            </div>
                        </div>
                        <div className='container deleteaccc center-text'>
                            {((User.removerequest != null && User.removerequest != null) || requested) ? 
                            <button style={{background:"#00BF11"}}>Account Deletion Requested</button>
                            :
                            <button onClick={() => setAccountPop(true)}>Delete My Account</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {accountPop &&
    <div className='delete-pop container change deleteaccpop' style={{display:"block"}}>
        <div className='container del-pop'>
            <div className='w30 float-center poptextbox center-text'>
                <div className='w90 float-center'>
                    <div className='surehead container'>
                        <p>Are You Sure you want to Delete your Account ?</p>
                    </div>
                    <div className='container deleteaccbuttons'>
                        <button style={{background : "var(--maincolor)",color:"#fff"}} onClick={() => sendDeleteReq()}>Yes</button>
                        <button style={{background : "var(--textmaincolor)",color:"#fff"}} onClick={() => setAccountPop(false)}>No</button>
                    </div>
                </div>
                <div className='closepop'  onClick={() => setAccountPop(false)}>
                    <FiX />
                </div>
            </div>
        </div>
    </div>
    }
    {deletedSuc && 
    <div className='delete-pop container change' >
        <div className='container del-pop'>
            <div className='w30 float-center poptextbox center-text'>
                <div className='w90 float-center'>
                    <div className='animation-wrapper container center-text'>
                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                        <p>{(deletedMsg != null && deletedMsg != "") ? deletedMsg : "Request Sent Successfully"}</p>
                    </div>
                    <div className='container gobackbut applybuttons'>
                        <button onClick={() => setDeletedSuc(false)}>Okay</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    {displays &&
        <div className='delete-pop container change' >
            <div className='container del-pop'>
                {(passpop) ? 
                <div className='w30 float-center poptextbox center-text'>
                    <div className='w90 float-center'>
                        <div className='animation-wrapper container center-text'>
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <p>Password Updated Succesfully</p>
                        </div>
                        <div className='container gobackbut applybuttons'>
                            <button onClick={() => setDisplays(false)}>Okay</button>
                        </div>
                    </div>
                </div>
                : 
                <div className='w30 float-center poptextbox center-text'>
                    <div className='w90 float-center'>
                        <div className='animation-wrapper container center-text'>
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                            <p>Profile Updated Succesfully</p>
                        </div>
                        <div className='container gobackbut applybuttons'>
                            <button onClick={() => setDisplays(false)}>Okay</button>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    }
    </>
  )
}
