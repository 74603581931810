import axios from 'axios';
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Cookies, useCookies } from 'react-cookie';
import Sidebar from '../Sidebar';
import TopBar from '../TopBar';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import LoadingIndicator from 'react-loading-indicator';
import noAddress from './address.png'
import {BiHomeSmile,BiBuilding} from 'react-icons/bi'
import { Link,useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function AddressProfile() {
  
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    let User = cookies.user
    const newToken = cookies.userToken
    const [newName,setNewName] = useState(User.name)
    const [ErrName,setErrName] = useState("")

    const [id,setId] = useState("")
    const [newPhone,setNewPhone] = useState("")
    const [ErrPhone,setErrPhone] = useState("")
    const [newPhoneCC,setNewPhoneCC] = useState("91")
    const [newPhoneCCF,setNewPhoneCCF] = useState("IN")

    const [newEmail,setNewEmail] = useState(User.email)
    const [ErrEmail,setErrEmail] = useState("")
    const [address,setAddress] = useState("")
    const [ErrAddress,setErrAddress] = useState("")
    const [pincode,setPincode] = useState("")
    const [ErrPincode,setErrPincode] = useState("")
    const [city,setCity] = useState("")
    const [ErrCity,setErrCity] = useState("")
    const [state,setState] = useState("")
    const [ErrState,setErrState] = useState("")
    const [landmark,setLandmark] = useState("")
    const [addType,setAddType] = useState("Home")
    const [existingAddress,setExistingAddress] = useState("")
    
    const [altPhone,setAltPhone] = useState("")
    const [altPhoneCC,setAltPhoneCC] = useState("IN")
    var a = 0;
    useEffect(()=>{
        if(a == 0){
            fetchAddress()
        }
        a++
    },[])
    
    const fetchAddress = () =>{
        axios.post(global.url + "api/user/addresslist",{
            userid : User.userid
        },
        {headers : {
            'Content-Type': 'application/json',
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }})
        .then((res) =>{
            if(res.data.status == "OK"){
                setExistingAddress(res.data.address)
                if(res.data.address == "" || res.data.address == null){
                    setNewName(cookies.user.name)
                    setNewEmail(cookies.user.email)
                    setNewPhone(cookies.user.contact)
                }else{
                    // setNewName("")
                    // setNewEmail("")
                    // setNewPhone("")
                    setId("")
                    setAddress("")
                    setCity("")
                    setState("")
                    setPincode("")
                    setLandmark("")
                }
            }
            setLoading(false)
        },
        (err) =>{
            setError(err)
        })
    }
    function getNewName(val) {
        setNewName(val.target.value) 
    }
    function getNewPhone(val) {
        setNewPhone(val.target.value) 
    }
    function getAltPhone(val) {
        setAltPhone(val.target.value) 
    }
    function getNewEmail(val) {
        setNewEmail(val.target.value) 
    }
    function getAddress(val) {
        setAddress(val.target.value) 
    }
    function getPincode(val) {
        var pin = val.target.value
        if(pin.length > 6){
            pin = pin.substring(0,6);
        }
        setPincode(pin) 
        if(pin.length === 6){
            callPincodeApi(pin)
        }
    }
    function getCity(val) {
        setCity(val.target.value) 
    }
    function getState(val) {
        setState(val.target.value) 
    }
    function getLandmark(val) {
        setLandmark(val.target.value) 
    }
    function getAddType(val) {
        setAddType(val.target.value) 
    }
    const NewAddress = (e) =>{
        e.preventDefault();
    // perform all neccassary validations
    if(newName == ""){
        setErrName("Enter valid name")
    }
    else if((newPhone == "") || (newPhone.length < 10  && newPhone.length > 10)) {
        setErrPhone("Enter valid phone number")
    }
    else if(/\S+@\S+\.\S+/.test(newEmail) == false){
        setErrEmail("Enter valid email")
    }
    else if(newEmail == ""){
        setErrEmail("Enter valid email")
    }
    else if(address == ""){
        setErrAddress("Enter valid address")
    }
    else if(city == ""){
        setErrCity("Enter valid city")
    }
    else if(pincode == "" || pincode.length < 6){
        setErrPincode("Enter valid pincode")
    }
    else{
        setLoading(true)
        axios.post(global.url + "api/user/addaddress",{
            authToken : cookies.user.myToken,
            id : id,
            userid : cookies.user.userid,
            name : newName,
            email : newEmail,
            contact : newPhone,
            alternative_no : altPhone,
            address : address,
            pincode : pincode,
            city: city,
            state : state,
            landmark : landmark,
            addressType : addType,
            cc: newPhoneCC,
            ccf: newPhoneCCF.toUpperCase(),
        },
        {headers : {
            'Content-Type': 'application/json',
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }}
        )
        .then(res =>{
            if(res.data.status == "OK"){
                fetchAddress();
                setLoading(false)
            }else{
                console.log("Error Occured")
            }
        }).catch(error =>{
            console.log(error.message)
            // setLoading(false)
        })
    }
    }

    const callPincodeApi = (pincode) => {
        if(pincode.length === 6){
            fetch("https://api.postalpincode.in/pincode/" + pincode, {
                "method": "GET",
                })
                .then(response => response.json())
                .then(response => {
                    if(response[0]['Status'] === "Success"){
                        setCity(response[0]['PostOffice'][0]['District'])
                        setState(response[0]['PostOffice'][0]['State'])
                        // console.log("Done")
                        // setCity(response[0]['PostOffice'][0]['District'])
                        // setState(response[0]['PostOffice'][0]['State'])
                        // setRefresh(refresh === "No" ? "Yes" : "No")
                    }
                })
                .catch(err => {
                    console.log(err)
                });
            }
    }

    const editAddress = (item) => {
        setId(item.id)
        setNewName(item.name)
        setNewEmail(item.email)
        setAddress(item.address)
        setPincode(item.pincode)
        setCity(item.city)
        setState(item.state)
        setLandmark(item.landmark)
        setNewPhone(item.contact)
        setAltPhone((item.alternative_no === "" || item.alternative_no === null) ? "" : item.alternative_no)
        var ccinfo = (item.ccinfo !== null) ? JSON.parse(item.ccinfo) : {cc: "91", ccf: "in"}
        setNewPhoneCC((ccinfo.cc !== null) ? ccinfo.cc : "91" )
        setNewPhoneCCF((ccinfo.ccf !== null) ? ccinfo.ccf : "in" )
    }

    const remAddress = (x) => {
        if(confirm("Are you sure you want to remove? You won't be able to Revert it back!")){
        fetch(global.url + "api/user/removeAddress", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            },
            "body": JSON.stringify({
                userid: User.userid,
                id: x,
            })
            })
            .then(response => response.json())
            .then(response => {
                if(response.status === "OK"){
                    fetchAddress()
                }else if(response.status === "Failed"){ 
                }
                setAddressLoading(false)
            })
        }
    }

    if(loading) 
    return(
        <section className='userdashboard '>
            <div className='my100 float-left dashboxmain'>
                <div className='sidebar-box float-left'>
                    <Sidebar act={8}/>
                </div>
                <div className='dashcontent float-left'>
                    <TopBar heading="My Wallet"/>
                    <div className='insideloading container'>
                        <LoadingIndicator segmentWidth={4} segmentLength={9}/> 
                    </div>
                </div>  
            </div>
        </section>
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
  return (
    <>
    <section className='userdashboard '>
        <div className='my100 float-left dashboxmain'>
            <div className='sidebar-box float-left'>
                <Sidebar act={8}/>
            </div>
            <div className='dashcontent float-left'>
                <TopBar heading="My Address(s)"/>
                 <div className="addressdashbox container maxwidth">
                    <div className='w90 float-center'>
                        <div className='w60 float-left saved-address'>
                            {(existingAddress == "" || existingAddress == null) ? (
                                <div className='empty-address container center-text'>
                                    <div className='emptyadd'>
                                        <img src={noAddress} alt="No address" />
                                    </div>
                                    <div className='container noaddress-p'>
                                        <p>No Address available</p>
                                    </div>
                                    <div className='container label-name'>
                                        <label htmlFor='name' >Add New Address</label>
                                    </div>
                                </div>
                            ) :
                            (
                            <div className='container addresses-box'>
                                {
                            existingAddress.map((add,index) => (
                                <div className='w50 float-left' key={index}>
                                    <div className='w98 addressbox'>
                                        <div className='w90 float-center'>
                                            <div className='container address-name'>
                                                <p>{add.name}</p>
                                            </div>
                                            <div className='container addressfull'>
                                                <p className='address-p'>
                                                    {add.address}
                                                </p>
                                                <p className='address-city'>
                                                    {add.city}
                                                </p>
                                                <p className='address-state'>
                                                    {add.state} - {add.pincode}
                                                </p>
                                                <p className='address-email'>
                                                    {add.email}
                                                </p>
                                                <p className='address-num'>
                                                    +{add.contact} {(add.alternative_no != undefined && add.alternative_no != null && add.alternative_no != "") && <> , +{add.alternative_no} </>}
                                                </p>
                                            </div>
                                        </div>
                                        <p className='address-type'>{(add.type == "Home") ? <BiHomeSmile /> : <BiBuilding />} {add.type}</p>

                                        <div className='right-text addressEdiRemove'>
                                            <p className='EditAddress' onClick={() => editAddress(add)} >Edit</p>
                                            <p className='removeAddress' onClick={() => remAddress(add.id)} >Remove</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div>
                            )}
                        </div>
                        <div className='w40 float-left addformbox'>
                            <div className='w90 float-center create-address'>
                                <div className='container cart-heading'>
                                    <div className='w95 float-center'>
                                        <p> {id !== "" ? "Update Delivery Address" : "Add a New Delivery Address" } </p>
                                    </div>
                                </div>
                                <div className='container new-address-form'>
                                    <div className='w95 float-center'>
                                        <form onSubmit={NewAddress}>
                                            <div className='container infields'>
                                                <div className='w95 float-center'>
                                                    <input
                                                        type="text"
                                                        required
                                                        placeholder="Name *"
                                                        value={newName}
                                                        onChange={getNewName}
                                                        id="name"
                                                        />
                                                </div>
                                                <div className='error-message container'>
                                                    <p>{ErrName}</p>
                                                </div>
                                            </div>
                                            <div className='container infields overflow-visible float-left inphone'>
                                                <div className='w95 float-center overflow-visible'>
                                                    <label htmlFor='altnumber'>Phone No.</label>
                                                    <PhoneInput
                                                        country={newPhoneCCF.toLowerCase()}
                                                        value={newPhone}
                                                        enableSearch={true}
                                                        inputProps={{
                                                            name: 'contact',
                                                            required: true
                                                        }}
                                                        onChange={(newPhone, country) => {
                                                            setNewPhone(newPhone)
                                                            setNewPhoneCCF(country.countryCode)
                                                            setNewPhoneCC(country.dialCode)
                                                        }}
                                                    />
                                                </div>
                                                <div className='error-message container'>
                                                    <p>{ErrPhone}</p>
                                                </div>
                                            </div>
                                            <div className='container infields overflow-visible float-left inphone'>
                                                <div className='w95 float-center overflow-visible'>
                                                    <label htmlFor='altnumber'>Alternate Phone No.</label>
                                                    <PhoneInput
                                                        country={newPhoneCCF.toLowerCase()}
                                                        value={altPhone}
                                                        enableSearch={true}
                                                        inputProps={{
                                                            name: 'contact',
                                                            id : "altnumber",
                                                            required: false,
                                                            placeholder : "Enter alt Phone No."
                                                        }}
                                                        onChange={(altPhone, country) => {
                                                            setAltPhone(altPhone)
                                                            // setAltPhoneCC(country.countryCode)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className='container infields' hidden>
                                                <div className='w95 float-center'>
                                                    <input
                                                        type="email"
                                                        required
                                                        placeholder="Email *"
                                                        value={newEmail}
                                                        onChange={getNewEmail}
                                                        
                                                        />
                                                </div>
                                                <div className='error-message container'>
                                                    <p>{ErrEmail}</p>
                                                </div>
                                            </div>
                                            <div className='container infields'>
                                                <div className='w95 float-center'>
                                                    <input
                                                        type="text"
                                                        required
                                                        placeholder="Address *"
                                                        value={address}
                                                        onChange={getAddress}
                                                        
                                                        />
                                                </div>
                                                <div className='error-message container'>
                                                    <p>{ErrAddress}</p>
                                                </div>
                                            </div>
                                            <div className='container infields'>
                                                <div className='w95 float-center'>
                                                    <input
                                                        type="number"
                                                        placeholder="Pincode *"
                                                        value={pincode}
                                                        onChange={getPincode}
                                                        required
                                                        />
                                                </div>
                                                <div className='error-message container'>
                                                    <p>{ErrPincode}</p>
                                                </div>
                                            </div>
                                            <div className='w50 float-left infields'>
                                                <div className='w90 float-center'>
                                                    <input
                                                        type="text"
                                                        required
                                                        placeholder="City *"
                                                        value={city}
                                                        onChange={getCity}
                                                        
                                                        />
                                                </div>
                                                <div className='error-message container'>
                                                    <p>{ErrCity}</p>
                                                </div>
                                            </div>
                                            <div className='w50 float-left  infields'>
                                                <div className='w90 float-center'>
                                                    <input
                                                        type="text"
                                                        required
                                                        placeholder="State *"
                                                        value={state}
                                                        onChange={getState}
                                                        
                                                        />
                                                </div>
                                                <div className='error-message container'>
                                                    <p>{ErrState}</p>
                                                </div>
                                            </div>
                                            <div className='container infields'>
                                                <div className='w95 float-center'>
                                                    <input
                                                        type="text"
                                                        placeholder="Landmark"
                                                        value={landmark}
                                                        onChange={getLandmark}
                                                        
                                                        />
                                                </div>
                                            </div>
                                            <div className='container infields addresstypes'>
                                                <div className='w95 float-center'>
                                                    <div className='container addt-head'>
                                                        <p>Select an address type</p>
                                                    </div>
                                                    <div className='container typesofadd'>
                                                        <input
                                                            type="radio"
                                                            placeholder="Address Type"
                                                            value="Home"
                                                            id="Home"
                                                            name='address-type'
                                                            onChange={getAddType}
                                                            checked ={addType === "Home"}
                                                            />
                                                        <label htmlFor="Home"><BiHomeSmile /> Home</label>
                                                        <input
                                                            type="radio"
                                                            placeholder="Address Type"
                                                            value="Office"
                                                            id="Office"
                                                            name='address-type'
                                                            onChange={getAddType}
                                                            checked ={addType === "Office"}
                                                            />
                                                        <label htmlFor="Office"><BiBuilding /> Office</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='container deliverhere'>
                                                <button>{id !== "" ? "Update Address" : "Add Address" }</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
