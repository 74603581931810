import React from 'react'
import { useEffect } from 'react'
import './manager.css'
import {FaAngleLeft,FaListUl} from 'react-icons/fa'
import {BsGridFill,BsCheck2Circle,BsArrowRightCircle} from 'react-icons/bs'
import {GoSettings} from 'react-icons/go'
import filesm from './images/files.png'
import assignmentsm from './images/assignments.png'
import livem from './images/live.png'
import axios from 'axios'
import { Cookies, useCookies } from 'react-cookie';
import { Link, useLocation, useParams } from 'react-router-dom'
import { useState } from 'react'
import folder3 from './images/folder3.png'
import videoBig from './images/videobig.png'
import pdf2 from './images/pdf2.png'
import quiz from './images/quiz.png'
import image from './images/image.png'
import questions from './images/question.png'
import recording from './images/recording.png'
import youtubes from './images/youtube.png'
import assignment2 from './images/assignment.png'
import live2 from './images/live.png'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import LoadingIndicator from 'react-loading-indicator'
import Expiring from './Expiring'
import Video from './Video'
import Chat from '../chat/Chat'
import defaultProfile from '../images/dashprofile.png'
import ELoader from '../../eLoader'
import Countdown from "react-countdown";
import moment from 'moment/moment';
import { FiX } from 'react-icons/fi'
import noPre from '../images/norecord.png'
import noTest from '../images/notests.png'
import noLive from '../images/nolivecls.png'

function crypt (salt, text){
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
  
    return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
}
export default function CourseManager() {
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [mainPackage,setMainPackage] = useState("")
    const [files,setFiles] = useState("")
    const [assignments,setAssignments] = useState("")
    const [liveClasses,setLiveClasses] = useState("")
    const [pathList,setPathList] = useState("")
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [knowMore,setKnowMore] = useState(false)
    const [grids,setGrids] = useState(false)
    const [fileLoading,setFileloading] = useState(false)
    const [courseData,setCourseData] = useState("")
    const [acttop,setActtop] = useState(0)
    const [compulsary,setCompulsary] = useState(false)
    const [newfolder,setNewFolder] = useState(false)
    const [notAvialPop,setNotAvailPop] = useState(false)
    let User = cookies.user;
    const newToken = cookies.userToken
    const params = useParams()
    var purchaseid = params.purchaseid
    var courseid = params.courseid
    var folderid = params.folderid
    var a = 0;
    
    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }
    const [availDate,setAvailDate] = useState("")
    const query = useQuery();
    const avail = query.get("avail");
    const location = useLocation()
    useEffect(() =>{
        setFileloading(true)
        
        if(cookies.user == "" || cookies.user == null || cookies.user == undefined) {
            window.location.href = "/"
        }
        if(window.location.href.indexOf("manager")){
            setNewFolder(true)
        }
        if(a == 0) {
            fetchCourse(folderid)
        }
        // if($(window).width() < 960){
        //     setGrids(true)
        // }
        a++
    },[folderid])
    const [scheduleData,setScheduleData] = useState("")
    const [topLive,setTopLive] = useState("")
    const fetchCourse = (pid)=>{
        setFileloading(true)
        axios.post(global.url + "api/user/myCourseContent",{
            uid : User.userid,
            purchase_id : purchaseid,
            course_id : courseid,
            p_id : pid
        },
        {headers : {
            "content-type": "application/json",
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }}
        )
        .then((res) =>{
            console.log(res.data)
            setTopLive(res.data.liveArray)
            setActtop(res.data.course.main_tab)
            setMainPackage(res.data.packageCourse)
            setFiles(res.data.files)
            setAssignments(res.data.assignments)
            setLiveClasses(res.data.liveclasses)
            setPathList(res.data.path)
            setCourseData(res.data.course)
            if(res.data.course.compalsary) setCompulsary(true)
            else setCompulsary(false)
            setFileloading(false)
            setLoading(false)
            savePercentage()
        },
        (error) =>{
            console.log(error)
            setError(error)
            setFileloading(false)
            setLoading(false)
        }
        )
    }
    const savePercentage = () => {
        fetch(global.url+"api/user/savePercentage", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            },
            "body": JSON.stringify({
                uid: User.userid,
                purchase_id: purchaseid,
                course_id: courseid,
            })
            })
            .then(response => response.json()) 
            .then(response => { })
    }
    const [compDone,setCompDone] = useState(false)
    const [searchQuery,setSearchQuery] = useState("")
    const submitCompulsary = () =>{
        setCompDone(true)
        axios.post(global.url + "api/user/compalsaryViewDone",{
            purchase_id : purchaseid,
            uid : User.userid
        },{
            headers : {
                "content-type": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            if(res.data.status == "OK"){
                setCompDone(false)
                setCompulsary(false)
            }
        },(error) =>{
            setError(error)
        })
    }
    const showNotAvail = (tag,scheduledata) =>{
        if(tag != undefined) setAvailDate(tag)
        if(scheduledata != undefined) setScheduleData(JSON.parse(scheduledata)), console.log(JSON.parse(scheduledata))
        setNotAvailPop(true)
    }
    const hideNotAvailPop = () =>{
        setAvailDate("")
        setScheduleData("")
        setNotAvailPop(false)
    }
    // ***************************Timer**********************************
    // Renderer callback with condition
   const renderer = ({ days, hours, minutes, seconds,completed  }) => {
        if (completed) {
            {window.location.reload()}   
        }else
       // Render a countdown
       return (
         <div className='container saletimer'>
           <div className='container salthead'>
             <p>Available In </p>
           </div>
           <div className='container'>
               <div className='timebox'>
                   <div className='timer center-text'>
                       <div className='timer-time container'>
                           <p>{days}</p>
                           <p className='timersub'>Days</p>
                       </div>
                   </div>
                   <div className='timer center-text'>
                       <div className='timer-time container'>
                           <p>{hours}</p>
                           <p className='timersub'>Hours</p>
                       </div>
                   </div>
                   <div className='timer center-text'>
                       <div className='timer-time container'>
                           <p>{minutes}</p>
                           <p className='timersub'>Minutes</p>
                       </div>
                   </div>
                   <div className='timer center-text'>
                       <div className='timer-time container'>
                           <p>{seconds}</p>
                           <p className='timersub'>Seconds</p>
                       </div>
                   </div>
               </div>
           </div>
         </div>
       );
   };
 // ***************************Timer end**********************************
    if(loading) 
    return(
        <section className='coursemanager'>
            <div className='my100 maxwidth managerbottombox'>
                <div className='my90 float-center cmanbox'>
                    <div className='my30 float-left cdatabox'>
                        <div className='w90 float-center cmanginfobox'>
                            <div className='container cmanginfo_image'>
                                <Skeleton width={"100%"} height={250} borderRadius={"10px"} style={{lineHeight:"initial"}}/>
                            </div>
                            <div className='manag-databox container'>
                                <div className='w95 float-center'>
                                    <div className='manegmname container center-text'>
                                        <Skeleton width={"100%"} height={35} borderRadius={"10px"} style={{lineHeight:"initial"}}/>
                                    </div>
                                    <div className='manegmsub container center-text'>
                                        <Skeleton width={"100%"} height={20} borderRadius={"5px"} style={{lineHeight:"initial"}}/>
                                        <Skeleton width={"100%"} height={20} borderRadius={"5px"} style={{lineHeight:"initial"}}/>
                                        <Skeleton width={"100%"} height={20} borderRadius={"5px"} style={{lineHeight:"initial"}}/>
                                    </div>
                                    <div className='mangeinstrutor container'>
                                        <ul>
                                            <li>
                                                <p><Skeleton width={"100%"} height={25} borderRadius={"10px"} style={{lineHeight:"initial"}}/></p>
                                                <p><Skeleton width={"100%"} height={15} borderRadius={"10px"} style={{lineHeight:"initial"}}/></p>
                                            </li>
                                            <li>
                                                <p><Skeleton width={"100%"} height={25} borderRadius={"10px"} style={{lineHeight:"initial"}}/></p>
                                                <p><Skeleton width={"100%"} height={15} borderRadius={"10px"} style={{lineHeight:"initial"}}/></p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w70 float-left leftbox'>
                        <div className='w98 float-center coursemanagerbox'>
                            <div className='searchbox-manager container' style={{height:"80vh",backgroundColor:"#fff",display:"flex",alignItems:"center",justifyContent:"center"}}>
                                <ELoader />  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
    return (
    <>
    <section className='coursemanager'>
        <div className='my100 maxwidth managerbottombox'>
            <div className='my90 float-center cmanbox'>
                <div className='my30 float-left cdatabox'>
                    <div className='w90 float-center cmanginfobox'>
                        <div className='container cmanginfo_image'>
                            {mainPackage.course.thumbnail == "" || mainPackage.course.thumbnail == null || mainPackage.course.thumbnail == undefined 
                            ? 
                            <div className='cmangemain_image managerdefimg'>
                                <img src={logoDef} alt={mainPackage.course.name} />
                            </div>
                            :
                            <div className='cmangemain_image'>
                                <img src={global.asset + mainPackage.course.thumbnail} alt={mainPackage.course.name} />
                            </div>
                            }
                        </div>
                        <div className='manag-databox container'>
                            <div className='w95 float-center'>
                                <div className='manegmname container center-text'>
                                    <p>{mainPackage.course.name}</p>
                                </div>
                                <div className='manegmsub container center-text'>
                                    <p>{mainPackage.course.coursedata.subheading}</p>
                                </div>
                                <div className='mangeinstrutor container'>
                                    <ul>
                                        <li>
                                            <p>Language</p>
                                            <p className='maininsttxt'>{mainPackage.course.coursedata.language}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {knowMore &&
                        <>
                        <div className='container datasmanegbx'>
                            <div className='container'>
                                <div className='w95 float-center '>
                                    <div className='w95 float-center datasmgtop'>
                                        <p>Order Summary</p>
                                    </div>
                                </div>
                            </div>
                            <div className='datasmang container'>
                                <ul>
                                    <li>
                                        <p>Purchased On</p>
                                        <p className='othertxtmange'>{mainPackage.order.date}</p>
                                    </li>
                                    <li>
                                        <p>Payment ID</p>
                                        <p className='othertxtmange'>{mainPackage.order.payment_id}</p>
                                    </li>
                                    <li>
                                        <p>Order ID</p>
                                        <p className='othertxtmange'>{mainPackage.order.prefixid}</p>
                                    </li>
                                    <li>
                                        <p>Including Books</p>
                                        <p className='othertxtmange'>{mainPackage.course.books == 1 ? "Yes" : "No"}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='container datasmanegbx'>
                            <div className='container'>
                                <div className='w95 float-center '>
                                    <div className='w95 float-center datasmgtop'>
                                        <p>Payment Summary</p>
                                    </div>
                                </div>
                            </div>
                            <div className='datasmang container'>
                                <ul>
                                    <li>
                                        <p>Price</p>
                                        <p className='othertxtmange'>{mainPackage.price.price}</p>
                                    </li>
                                    <li>
                                        <p>Discount</p>
                                        <p className='othertxtmange'>{mainPackage.price.discount}</p>
                                    </li>
                                    <li>
                                        <p>Additional Discount</p>
                                        <p className='othertxtmange'>{mainPackage.price.additional}</p>
                                    </li>
                                    <li>
                                        <p>GST ( {mainPackage.price.gstper + "%"} )</p>
                                        <p className='othertxtmange'>{mainPackage.price.gst}</p>
                                    </li>
                                    <li>
                                        <p>Final</p>
                                        <p className='othertxtmange'>{mainPackage.price.final}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='container datasmanegbx'>
                            <div className='container'>
                                <div className='w95 float-center '>
                                    <div className='w95 float-center datasmgtop'>
                                        <p>Points Summary</p>
                                    </div>
                                </div>
                            </div>
                            <div className='datasmang container'>
                                <ul>
                                    <li>
                                        <p>Points Used</p>
                                        <p className='othertxtmange'>{mainPackage.price.deduct}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </>
                        }
                        {knowMore ?
                        <div className='container knowmoremaneg center-text'>
                            <span onClick={() => setKnowMore(false)}>Show Less</span>
                        </div>
                        :
                        <div className='container knowmoremaneg center-text'>
                            <span onClick={() => {setKnowMore(true)}}>More Info</span>
                        </div>
                        }
                    </div>
                </div>
                <div className='w70 float-left leftbox'>
                    {compulsary ? 
                    <div className='w98 float-center compulasrybox coursemanagerbox'>
                        {compDone ? 
                        <div className='comploading container'>
                            <ELoader /> 
                        </div>
                        :
                         <>
                        <div className='container headings-compul'>
                            <div className='w95 float-center compulsflx'>
                                <p>Compulsary Files</p>
                                <div className="backboxcomp">
                                    <button onClick={() => submitCompulsary()}>Finish</button>
                                    <div className='leftside mtop'>
                                        <a href={'/d/courses'} title='Go back to Library'><FaAngleLeft /> Back to Library</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {courseData.compalsary_video != null && courseData.compalsary_video != "" && courseData.compalsary_video != undefined &&
                        <div className='container vidbox'>
                            <Video id={courseData.compalsary_video} type={"encrypt"} user={User}/>
                        </div>
                        }
                        {courseData.compalsary_document != undefined && courseData.compalsary_document != null && courseData.compalsary_document != "" && 
                        <div className='container vidbox'>
                            <iframe src={'/pdfview/web/viewer.html?src='+crypt("Omesh@558", courseData.compalsary_document.toString())+'&e='+crypt("Omesh@558", "Escholar Preview")+'&c='+crypt("Omesh@558", "Escholar Preview")} style={{width:"100%", height:"calc(100vh - 100px)",border:0}}  allow="autoplay; fullscreen"></iframe>
                        </div>
                        }
                        </>
                        }
                    </div>
                    :
                    <div className='w98 float-center coursemanagerbox'>
                        <div className='searchbox-manager container'>
                            <div className='w98 float-center sboxflex'>
                                <div className='leftside-searchbox'>
                                    <div className='search-manager'>
                                        <input type="text" placeholder='Search File' name='search-manager' onChange={(e) => setSearchQuery(e.target.value)}></input><GoSettings />
                                    </div>
                                    <div className='viewtype'>
                                        <button className={`viewdesign ${grids ? "" : "actdesign"}`}  onClick={() => setGrids(false)}>
                                            <FaListUl /> List
                                        </button>
                                    </div>
                                    <div className='viewtype'>
                                        <button className={`viewdesign ${grids ? "actdesign" : ""}`} onClick={() => setGrids(true)}>
                                            <BsGridFill/> Grid
                                        </button>
                                    </div>
                                </div>
                                <div className="rightside-searcbox">
                                    <div className='profile-circle' title='Go to profile'>
                                        {(User.image == "" || User.image == null) ?
                                            <img src={defaultProfile} alt='Profile Image'  referrerPolicy='no-referrer'/>
                                            :
                                            <img src={global.asset + User.image} alt='Profile Image' referrerPolicy='no-referrer' />
                                        }
                                    </div>
                                    <div className='leftside mtop'>
                                        <a href={'/d/courses'} title='Go back to Library'><FaAngleLeft /> Back to Library</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container tabonsmanager'>
                            <div className='container float-center tabsmanagerflx'>
                                <div className='w33 float-left'>
                                    <div className={`w95 float-center tabmaneg ${(acttop == "Files") ? "activemtab" : ""}`} onClick={() => setActtop("Files")}>
                                        <Link to={"/dashboard/c/manager/" + courseid + "/"+ purchaseid + "/" + 0} title="View Files" >
                                            <div className='tabmangeicon'>
                                                <img src={filesm} alt='File' />
                                            </div>
                                            <div className='tabmangetxt'>
                                                <p>Pre-recorded</p>
                                            </div>
                                            {topLive.filemanager != null && topLive.filemanager.classstarted != undefined && topLive.filemanager.classstarted == 1 &&
                                                <span className='newcircle livetxt'>Class is Live</span>
                                            }
                                        </Link>
                                    </div>
                                </div>
                                {assignments != "" && assignments != null &&
                                <div className='w33 float-left'>
                                    <div className={`w95 float-center tabmaneg ${acttop == "Assignment" ? "activemtab" : ""}`} onClick={() => setActtop("Assignment") }>
                                        <Link to={"/dashboard/c/manager/" + courseid + "/"+ purchaseid + "/" + assignments} title="View Assignments" >
                                            <div className='tabmangeicon'>
                                                <img src={assignmentsm} alt='Assignments' />
                                            </div>
                                            <div className='tabmangetxt'>
                                                <p>Test Series</p>
                                            </div>
                                            {topLive.assignment != null && topLive.assignment.classstarted != undefined && topLive.assignment.classstarted == 1 &&
                                                <span className='newcircle livetxt'>Class is Live</span>
                                            }
                                        </Link>
                                    </div>
                                </div>
                                }
                                {liveClasses != "" && liveClasses != null &&
                                <div className='w33 float-left'>
                                    <div className={`w95 float-center tabmaneg ${acttop == "Live" ? "activemtab" : ""}`} onClick={() => setActtop("Live") }>
                                        <Link to={"/dashboard/c/manager/" + courseid + "/"+ purchaseid + "/" + liveClasses} title="View Assignments" >
                                            <div className='tabmangeicon'>
                                                <img src={livem} alt='Live Classes' />
                                            </div>
                                            <div className='tabmangetxt'>
                                                <p>Live Classes</p>
                                            </div>
                                            {topLive.liveclass != null && topLive.liveclass.classstarted != undefined && topLive.liveclass.classstarted == 1 &&
                                                <span className='newcircle livetxt'>Class is Live</span>
                                            }
                                        </Link>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className='container filepath'>
                            <div className='w95 float-center pathflx'>
                                <ul>
                                    {pathList == "" || pathList == null || pathList == undefined ?
                                    <li title={courseData.name}>{courseData.name}</li>
                                    :
                                    <>
                                    <li><Link to={"/dashboard/c/manager/" + courseid + "/"+ purchaseid + "/" + 0} title={courseData.name} > {courseData.name} </Link> /</li>
                                    {pathList.map((path,i) =>(
                                        <li key={i} title={path.name}>
                                            {(pathList.length === i+1) ? 
                                            <p >{path.name} /</p> 
                                            : 
                                            <>
                                            <Link to={"/dashboard/c/manager/" + courseid + "/"+ purchaseid + "/" + path.id} title={path.name} > {path.name}</Link> /
                                            </>
                                            }
                                        </li>
                                    ))}
                                    </>
                                    }
                                </ul>
                                {folderid != 0 &&
                                <div className='managerbck'>
                                    <button onClick={() => history.back()}>Back</button>
                                </div>
                                }
                            </div>
                        </div>
                        <div className='container fileslist'>
                            {!grids && 
                            <div className='topfilebar container'>
                                <div className='w95 float-center topbarflx'>
                                    <div className='fileimagebox'>
                                        {/* <p>File</p> */}
                                    </div>
                                    <div className='w50 float-left filenambox'>
                                        <p>File Name</p>
                                    </div>
                                    <div className='w30 float-left fileavailable'>
                                        <p>Availability</p>
                                    </div>
                                    <div className='float-left getfl'>
                                        {/* <p>Completed</p> */}
                                    </div>
                                </div>
                            </div>
                             }
                            {fileLoading ?
                            [...Array(4)].map((i,index) => {
                                return (
                                <div className={`mainlistbox ${grids ? "gridmainbox" : ""}`} key={index} >
                                    <div className='w95 float-center listboxflx loadingbxmaneg'>
                                        <div className='fileimagebox'>
                                            <div className='fileimg'>
                                                <Skeleton width={(screen.width > 900) ? 50 : 30} height={(screen.width > 900) ? 50 : 30} borderRadius={"50%"}/>
                                            </div>
                                        </div>
                                        <div className='datasidebox'>
                                            <div className='w50 float-left filenambox'>
                                                <p><Skeleton width={"90%"} height={25}/></p>
                                            </div>
                                            <div className='w30 float-left fileavailable'>
                                                <p><Skeleton width={"90%"} height={25}/></p>
                                            </div>
                                            <div className='float-left getfl'>
                                                <Skeleton width={25} height={25} borderRadius={"50%"}/>
                                            </div>
                                        </div>
                                    </div>
                                 </div>
                                )})
                            :
                            <>
                            {(files == "" || files == undefined || files == null) ?
                                <div className="nodatabox container" >
                                    <div className='w95 float-center emptyfilex'>
                                        <div className='nodataimg'>
                                            {(acttop == "Files") &&
                                                <img src={(avail != undefined && avail != null && avail == "not") ? pagesImg : noPre} alt="No data found" />
                                            }
                                            {(acttop == "Assignment") &&
                                                <img src={(avail != undefined && avail != null && avail == "not") ? pagesImg : noTest} alt="No data found" />
                                            }
                                            {(acttop == "Live") &&
                                                <img src={(avail != undefined && avail != null && avail == "not") ? pagesImg : noLive} alt="No data found" />
                                            }
                                        </div>
                                        <div className='container nofiles'>
                                            {(avail != undefined && avail != null && avail == "not") ?
                                            <div className='countdownbox container'>
                                                <p>Not available yet. <br/> {availDate}</p>
                                                <Countdown date={moment(scheduleData.startdate)} renderer={renderer} />
                                            </div>
                                            :
                                            <p>No files found </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            :
                            files.filter(post =>{
                                if(searchQuery == ""){
                                    return post
                                }
                                else if(post.name.toLowerCase().includes(searchQuery.toLocaleLowerCase())){
                                    return post
                                }
                            }).map((file , i) => {
                                let url = "";
                                let url2 = "";
                                if(file.type == "Document" && file.available == 1)  {
                                 url = "/dashboard/c/" + courseid + "/"+ purchaseid + "/doc/"  + file.id + "/";
                                }
                                else if (file.type == "Image" && file.available == 1) {
                                    url = "/dashboard/c/" + courseid + "/"+ purchaseid + "/img/"  + file.id;
                                }
                                else if ((file.type == "Video" || file.type == "Youtube" || file.type == "Recording")  && file.available == 1){
                                    url = "/dashboard/c/" + courseid + "/"+ purchaseid + "/video/"  + file.id;
                                }
                                else if ((file.type == "Live")  && file.available == 1){
                                    url = "/dashboard/c/" + courseid + "/"+ purchaseid + "/live/"  + file.id;
                                }
                                else if (file.type == "Assignment" && (file.available == 2 || file.available == 1)) {
                                    url = "/dashboard/c/" + courseid + "/"+ purchaseid + "/assign/"  + file.id;
                                }
                                else if (file.type == "Quiz" && file.available == 1) {
                                    url = "/dashboard/c/" + courseid + "/"+ purchaseid + "/quiz/"  + file.id;
                                }
                                else if(file.available == 0){
                                    url = "/dashboard/c/manager/" + courseid + "/"+ purchaseid + "/"  + file.id + "?avail=not";
                                    url2 ="manager";
                                }
                                else{
                                    url = "/dashboard/c/manager/" + courseid + "/"+ purchaseid + "/"  + file.id;
                                    url2 ="manager"
                                }
                                return(
                            <div className={`mainlistbox ${grids ? "gridmainbox" : ""}`} title={file.name} key={i} onClick={() => url2 == "manager" ? ((file.available != undefined && file.available != null && file.available != 1) ? showNotAvail(file.tag,file.scheduledata) : fetchCourse(file.id)) : console.log("notcalled")}>
                                <Link to={(file.available != undefined && file.available != null && file.available != 1) ? "#" : url} state={{prev : location.pathname}} title={file.name} >
                                    <div className='w95 float-center listboxflx'>
                                        <div className='fileimagebox'>
                                            <div className='fileimg'>
                                                {(file.type == "Folder") &&
                                                    <img src={folder3} alt="" />
                                                }
                                                {file.type == "Document"  &&
                                                    <img src={pdf2} alt="" />
                                                }
                                                {(file.type == "Quiz") &&
                                                    <img src={quiz} alt="" />
                                                }
                                                {(file.type == "Image") &&
                                                    <img src={image} alt="" />
                                                }
                                                {(file.type == "Video") &&
                                                    <img src={videoBig} alt="" />
                                                }
                                                {(file.type == "Question") &&
                                                    <img src={questions} alt="" />
                                                }
                                                {(file.type == "Recording") &&
                                                    <img src={recording} alt="" />
                                                }
                                                {(file.type == "Youtube") &&
                                                    <img src={youtubes} alt="" />
                                                }
                                                {(file.type == "Live") &&
                                                    <img src={live2} alt="" />
                                                }
                                                {(file.type == "Assignment") &&
                                                    <img src={assignment2} alt="" />
                                                }
                                            </div>
                                        </div>
                                        <div className='datasidebox'>
                                            <div className='w50 float-left filenambox'>
                                                <div className='container'>
                                                    <p>{file.name}</p>
                                                </div>
                                                {file.subtext != undefined && file.subtext != null && file.subtext != "" && <>
                                                <div className='filesubtxt container'>
                                                    <p>{file.subtext}</p>
                                                </div>
                                                </> }
                                            </div>
                                            <div className='w30 float-left fileavailable'>
                                                {file.tag == undefined || file.tag == null || file.tag == "" ? 
                                                <p style={{paddingLeft : "10px"}}> - </p>
                                                :
                                                (() => {
                                                    const str = file.tag.split(":-");
                                                    const first = str[0];
                                                    const second = str[1];
                                                        return <p><span className='availgrdtxt'>{first}</span><br/>{second}</p>;
                                                })()
                                                }
                                            </div>
                                            <div className='float-left getfl'>
                                                {file.read ?
                                                    <p className='checked'>
                                                        <BsCheck2Circle  />
                                                    </p>
                                                    : 
                                                    <>
                                                    <span className='newcircle'>new</span>
                                                    <button className='getstartfile'><BsArrowRightCircle /></button>
                                                    </>
                                                }
                                                {file.activeclass != undefined && file.activeclass &&
                                                    <span className='newcircle livetxt'>Class is Live</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            )
                            })}
                            </>
                            }
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    </section>
    {notAvialPop &&
        <div className='container notavailpop'>
            <div className='notavailbox container'>
                <div className="nodatabox notavailpflex" >
                    <div className='w95 float-center emptyfilex'>
                        <div className='nodataimg'>
                            <img src={pagesImg } alt="No data found" />
                        </div>
                        <div className='container nofiles'>
                            <div className='countdownbox container'>
                                <p>Not available yet. <br/> {availDate}</p>
                                <Countdown date={moment(scheduleData.startdate)} renderer={renderer} />
                            </div>
                        </div>
                    </div>
                    <div className='closenot'>
                        <FiX  onClick={() => hideNotAvailPop()}/>
                    </div>
                </div>
            </div>
        </div>
    }
    <Expiring user={User} purchaseid={purchaseid} folderid={folderid} courseid={courseid} token={newToken}/>
    {mainPackage.course.showchat !== null && 
        <Chat pathString={"Inside_Course"} user={cookies.user} path={{course_id: courseid, purchase_id:purchaseid, pathList: pathList, type:"Inside_Course"}}  newToken={newToken}/>
    }
    </>
  )
}
