import React,{useRef,useState,useEffect} from 'react'
import { FiShoppingCart, FiMenu,FiUser,FiUserPlus, FiLogOut, FiX} from 'react-icons/fi';
import {MdDashboardCustomize} from 'react-icons/md'
import {FaStore} from 'react-icons/fa'
import EmptyCart from './emptycart.svg';
import { Cookies, useCookies } from 'react-cookie';
import axios from 'axios';
import { Link ,useNavigate,useLocation} from 'react-router-dom';
import SearchComponent from './searchComponent';
import $ from 'jquery'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CartFetch from './cart/CartFetch';
import CheckLogin from './auth/CheckLogin';
import cookieImg from './cookies.png'
import seoTags from './SeoTags';

export default function Menu(){
    const [isLoading,setIsLoading] = useState(false)
    const [error,setError] = useState("")
    const [user,setUser] = useState("")
    const [catNames,setCatNames] = useState("")
    const [cookies,setCookie,removeCookie] = useCookies('')
    const [catLoading,setCatLoading] = useState(true)
    const [topBar,setTopBar] = useState("")
    const ref = useRef(null);
    const location  = useLocation()
    const navigate = useNavigate()
    const [cookieFadeIn,setCookieFadeIn] = useState(false)
    const [socials,setSocials] = useState("")
    let accepted = cookies.accepted;
    var a = 0;
    
    useEffect(()=>{
        if(a==0){
            fetchCategories()
            if(accepted != undefined){
                setCookieFadeIn(false)
            }else{
                setTimeout(function(){
                    setCookieFadeIn(true)
                },5000)
            }
        }
        a++
    },[])
    
    const setAccepted = () =>{
        setCookie('accepted',"accepted",{maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
        setCookieFadeIn(false)
    }
    const fetchCategories = () =>{
        axios.post(global.url + "api/user/getcategories",null,{
            headers : {
                'Authorization' : "Bearer " + defaultToken,
                'Accept' : 'application/json'
            },
            params : {
                "mode" : Mode
            }
        })
        .then((res) =>{
            setCatNames(res.data)
            getTopBar()
        },(error) =>{
            setError(error)
        }
        )
    }
    const getTopBar = () =>{
        axios.post(global.url + "api/user/homepage",null,{
            headers : {
                'Authorization' : "Bearer " + defaultToken,
                'Accept' : 'application/json'
            },
            params : {
                "mode" : (window.innerWidth > 900) ? Mode : "mobile"
            }
        })
        .then((res) =>{
            setTopBar(res.data)
            window.$('head').append(res.data.rules.all_page_head_script)
            window.$('body').append(res.data.rules.all_page_body_script)
            getSocials()
        },(error) =>{
            console.log(error.message)
        })
    }
    const socialArray = ["Facebook", "Youtube", "Twitter", "Instagram", "Pinterest", "Skype", "Linkedin", "Whatsapp", "Contact Support", "Email Support"]
    const getSocials = () =>{
        axios.post(global.url + "api/getSocialLinksUsers",{
            socialArray : socialArray
        },{
            
            headers : {
                "Authorization" : "Bearer "+defaultToken,
                'Accept' : 'application/json'
            }
        })
        .then((res) =>{
            setSocials(res.data.links)
        },(error) =>{
            console.log(error.message)
        })
    }
    const {cartItems,isLoadingCart} = CartFetch()
    const {loggedIn,currentUser} = CheckLogin()
    const logOut = () =>{
        removeCookie('user',{path : "/"})
        removeCookie('userToken',{path : "/"})
        window.location.href = '/'
    }
    
    const [popData,setPopData] = useState("")
    const [popsType,setPopsType] = useState("")
    const [showSocials,setShowSocials] = useState(false)
    const openSocial = (data,type) =>{
        setPopsType(type)
        setPopData(data)
        setShowSocials(true)
    }
    // *******************responsive*****************
    const menuSlides = () =>{
        window.$('.linkbox').css("left","0")
    }
    const menuClose = () =>{
        window.$('.linkbox').css("left","-100%")
    }
    const options ={
        dots : false,
        loop : true,
        nav : true,
        autoplay : true,
        autoplaySpeed  : 1000,
        autoplayTimeout: 5000,
        autoplayHoverPause:true,
        dotsSpeed:1000,
        responsive : {
            0:{
                items : 1,
                margin : 10,
                nav : false,
            },
            900:{
                items : 1,
                margin : 10,
                nav : false
            },
            1000 : {
                items : 1,
                margin : 10,
            }
        }
    }
    if(error)
    return (
        <p>{error.message}</p>
    )
    else
    return(
        <React.Fragment>
            {topBar !="" && topBar != null &&
            <div className="newtop-bar container" id='maintopbar'>
                <div className="w95 float-center">
                    <div className='container toprunning'>
                        <OwlCarousel className='owl-theme owl-height' {...options}>
                            {
                            [...Array(10)].map((data,i) => {
                                let txt = i+1;
                                let toptxt = "menutop"+txt
                                let topurl = "menutop"+txt+"url"
                                let menushow = "menutopshow"+txt
                            return(
                            ((topBar.rules[toptxt]) != "" && topBar.rules[menushow].includes(((window.innerWidth > 900) ? "Web" : "Mobile"))) &&
                            <div className='item topitem' key={i}>
                                {topBar.rules[topurl] != "" ?
                                <a href={topBar.rules[topurl]} title={topBar.rules[toptxt]}>
                                    {topBar.rules[toptxt]} 
                                </a>
                                :
                                <p>{topBar.rules[toptxt]}</p>
                                }
                            </div>
                            )})}
                        </OwlCarousel>
                    </div>
                </div>
            </div>
            }
            <nav className="container menurk" id='mainmenu'>
                <div className='container sticky-innermenu'>
                    <div className="mfix">
                        <div className="container menulinks-box">
                            <div className="w95 overflow-visible mobile-menu-top">
                                    <div className="logobox">
                                        <a href="/" title="">
                                            <div className="mobile-logo">
                                                <img src={logoMain} alt="escholar" />
                                            </div>
                                        </a>
                                    </div>
                                    <div className="mid-search w40 mob_hide">
                                        <SearchComponent />
                                    </div>
                                    <div className="pc_hide cart-top mobsvgs">
                                        <Link to="/mycart" title='mycart'>
                                            <div className="cartsmob">
                                                <FiShoppingCart /> 
                                                <p className="cart-conts">
                                                ({(cartItems != "" && cartItems != undefined) ? cartItems.cart.length : "0"})
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="moblog mobsvgs pc_hide">
                                        {
                                         (currentUser == "") ? 
                                         <Link to="/user-login" title='Sign In'  onClick={menuClose}>
                                            <FiUser />
                                        </Link>
                                        : 
                                        <Link to="/d/courses" title='Sign In'  onClick={menuClose}>
                                           <MdDashboardCustomize />
                                       </Link>
                                        }
                                    </div>
                                    <div className="pc_hide menu-bars mobsvgs" onClick={menuSlides}>
                                        <FiMenu />
                                    </div>
                                <div className="pc_hide container downs overflow-visible">
                                    <div className="w98 float-center search-mobile overflow-visible">
                                        <div className="mid-search w40">
                                            <SearchComponent />
                                        </div>
                                    </div>
                                </div>
                                <div className="linkbox">
                                    <div className="white-area-menu">
                                        <ul>
                                            <li className="pc_hide">
                                                <a href="#" title="">
                                                    <div className="side-logo">
                                                        <img src={logoMain} alt="escholar" />
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <Link to="/" title="Home"  onClick={menuClose}>Home</Link>
                                            </li>
                                            <li >
                                                <Link to="#" title="Our Categories" >Our Categories </Link>
                                                {(catNames != "" && catNames != null) && 
                                                <div className='dropdown'>
                                                    <ul>
                                                        {catNames.categories.map((names,i) =>(
                                                        <li key={i}>
                                                            <a href={"/store/" + names.slug+"/"+names.categoryid+"?t=all"} title={names.categoryName}  onClick={menuClose}><span className='circleact'></span><p>{names.categoryName}</p></a>
                                                        </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                }
                                            </li>
                                            <li>
                                                <Link to="/blogs" title="Blogs"  onClick={menuClose}>Blogs</Link>
                                            </li>
                                            <li className="cart-hover">
                                            <Link to="/mycart" title=""  onClick={menuClose}><i data-feather="shopping-cart"></i>
                                             Cart 
                                             ({(cartItems != undefined && cartItems != "") ? cartItems.cart.length : "0"})
                                             </Link>
                                                <div className="drop-down cart-drop">
                                                    <div className="container">
                                                        {
                                                        (cartItems == "") ? (
                                                        <div className="container cart-card">
                                                            <div className="w90 float-center" style={{display:"flex",alignItems:"center"}}>
                                                                <div className="w30 float-left">
                                                                    <div className="cart-itemimg">
                                                                        <img src={EmptyCart} alt="Empty Cart" referrerPolicy='no-referrer' />
                                                                    </div>
                                                                </div>
                                                                <div className="w70 float-left">
                                                                    <div className="w90 float-right">
                                                                        <div className="container prod-name">
                                                                            <p>No Items In Your Cart</p>
                                                                        </div>
                                                                        <div className="container cart-prod-price">
                                                                            <p>Start Learning</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        ) : ( 
                                                        cartItems.cart.map((item , i) =>(
                                                        (i <= 1) &&(
                                                        <div className="container cart-card" key={i}>
                                                            <div className="w98 float-center">
                                                                <a href={`/p/` + item.course.slug + "/" + item.course.otherid} title={item.course.name} >
                                                                    <div className="w30 float-left">
                                                                        {(item.course.thumbnail == null || item.course.thumbnail == "") ?
                                                                        <div className="cart-itemimg defcartlogo">
                                                                            <img src={logoDef} alt={item.course.name} referrerPolicy='no-referrer'/>
                                                                        </div>
                                                                        :
                                                                        <div className="cart-itemimg">
                                                                            <img src={global.asset + item.course.thumbnail} alt={item.course.name} referrerPolicy='no-referrer'/>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                    <div className="w70 float-left">
                                                                        <div className="w98 float-right">
                                                                            <div className="container prod-name">
                                                                                <p>{item.course.name}</p>
                                                                            </div>
                                                                            <div className="container cart-prod-price">
                                                                                {item.price.final < 1 
                                                                                ? 
                                                                                <p className='freetag'>Free</p>
                                                                                :
                                                                                <p><span className='pricst'>₹</span> {item.price.final}</p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        )))) 
                                                    }
                                                    { 
                                                    (cartItems !== "") && (
                                                    (cartItems.cart.length > 2) &&
                                                    (
                                                    <div className="container center-text more-tag">
                                                        <Link to="/mycart" title="">{cartItems.cart.length - 2} More Items in Cart</Link>
                                                    </div>
                                                    )
                                                    )
                                                    }
                                                    </div>
                                                    {
                                                    (cartItems !== "") ?
                                                    (<div className="container subs-box">
                                                        <div className="w90 float-center">
                                                            <div className="container sub-total-cart">
                                                                <p>SUBTOTAL ({cartItems.cart.length} items)</p>
                                                                <p className="cartsubprice"><span className='pricst'>₹</span> {cartItems.price.final == 0 ? "0" : cartItems.price.final}</p>
                                                            </div>
                                                            <div className="container cart-drop-button">
                                                                <Link to="/mycart" title="Go to cart">
                                                                    <button className="check-drop">Go to Cart</button>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>) : (
                                                    <div className="container subs-box">
                                                        <div className="w90 float-center">
                                                            <div className="container sub-total-cart">
                                                                <p>SUBTOTAL (0 items)</p>
                                                                <p className="cartsubprice"><span className='pricst'>₹</span> 0</p>
                                                            </div>
                                                            <div className="container cart-drop-button">
                                                                <Link to="/mycart" title="Go to cart">
                                                                    <button className="check-drop">Go to Cart</button>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )
                                                    }
                                                </div>
                                            </li>
                                            <li className='loginbutton'>
                                                <Link to='/track-order' className='logouts' title='Track Order' onClick={menuClose}>Track Order</Link>
                                            </li>
                                            {
                                                (currentUser == "") ? (
                                                    <>
                                                        <li className='loginbutton'>
                                                            <Link to="/user-login" title="Sign In"  onClick={menuClose}><FiUser /> Log In</Link>
                                                        </li>
                                                        <li className='loginbutton'>
                                                            <Link to="/signup" title="Sign Up"  onClick={menuClose}><FiUserPlus /> Sign Up</Link>
                                                        </li>
                                                    </>
                                            ) : (
                                                <>
                                                <li className='dashButton  loginbutton'>
                                                    <a href="/d/courses" title={`My Courses`}  onClick={menuClose}>My Courses</a>
                                                </li>
                                                <li className='loginbutton lgoutmenu'>
                                                    <p  title='Log Out' className='logouts' onClick={logOut} >Log Out <FiLogOut /></p>
                                                </li>
                                                </>
                                            )
                                        }
                                            
                                            <li className="pc_hide social-side">
                                            {socials != "" &&
                                                <div className="social-side socialsidemenu">
                                                    <ul>
                                                        {socials.Facebook.length > 0 &&
                                                        <>
                                                        {
                                                        socials.Facebook.length == 1 ?
                                                        <li>
                                                            <a className="facebook" href={socials.Facebook[0].url} title="Follow  On Facebook" target="_blank">
                                                                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Facebook</title><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>
                                                            </a>
                                                        </li>
                                                        :
                                                        <li onClick={() => openSocial(socials.Facebook,"Facebook")} className="facebook">
                                                            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Facebook</title><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>
                                                        </li>
                                                        }
                                                        </>
                                                        }
                                                        
                                                        {socials.Twitter.length > 0 &&
                                                        <>
                                                        {socials.Twitter.length == 1 ?
                                                        <li>
                                                            <a className=" twitter" href={socials.Twitter[0].url} title="Follow On Twitter" target="_blank">
                                                                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Twitter</title><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/></svg>
                                                            </a>
                                                        </li>
                                                        :
                                                        <li onClick={() => openSocial(socials.Twitter,"Twitter")} className="twitter">
                                                            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Twitter</title><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/></svg>
                                                        </li>
                                                        }
                                                        </>
                                                        }
                                                        {socials.Instagram.length > 0 &&
                                                        <>
                                                        {socials.Instagram.length == 1 ?
                                                        <li>
                                                            <a className="instagram" href={socials.Instagram[0].url} title="Follow  On Instagram" target="_blank">
                                                            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Instagram</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>
                                                            </a>
                                                        </li>
                                                        :
                                                        <li onClick={() => openSocial(socials.Instagram,"Instagram")} className="instagram">
                                                            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Instagram</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>
                                                        </li>
                                                        }
                                                        </>
                                                        }
                                                        {socials.Linkedin.length > 0 &&
                                                        <>
                                                        {socials.Linkedin.length == 1 ?
                                                        <li>
                                                            <a className="linkedin" href={socials.Linkedin[0].url} title="Follow On Linkedin" target="_blank">
                                                                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>
                                                            </a>
                                                        </li>
                                                        :
                                                        <li onClick={() => openSocial(socials.Linkedin,"Linkedin")} className="linkedin">
                                                            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>
                                                        </li>

                                                        }
                                                        </>
                                                        }
                                                        {socials.Youtube.length > 0 &&
                                                        <>
                                                        {socials.Youtube.length == 1 ?
                                                        <li>
                                                            <a className=" youtube" href={socials.Youtube[0].url} title="Youtube" target="_blank">
                                                                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>YouTube</title><path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/></svg>
                                                            </a>
                                                        </li>
                                                        :
                                                        <li onClick={() => openSocial(socials.Youtube,"Youtube")} className="youtube">
                                                            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>YouTube</title><path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/></svg>
                                                        </li>
                                                        }
                                                        </>
                                                        }
                                                        {socials.Skype.length > 0 &&
                                                        <>
                                                        {socials.Skype.length == 1 ?
                                                        <li>
                                                            <a className=" skype" href={socials.Skype[0].url} title="Skype" target="_blank">
                                                                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Skype</title><path d="M12.069 18.874c-4.023 0-5.82-1.979-5.82-3.464 0-.765.561-1.296 1.333-1.296 1.723 0 1.273 2.477 4.487 2.477 1.641 0 2.55-.895 2.55-1.811 0-.551-.269-1.16-1.354-1.429l-3.576-.895c-2.88-.724-3.403-2.286-3.403-3.751 0-3.047 2.861-4.191 5.549-4.191 2.471 0 5.393 1.373 5.393 3.199 0 .784-.688 1.24-1.453 1.24-1.469 0-1.198-2.037-4.164-2.037-1.469 0-2.292.664-2.292 1.617s1.153 1.258 2.157 1.487l2.637.587c2.891.649 3.624 2.346 3.624 3.944 0 2.476-1.902 4.324-5.722 4.324m11.084-4.882l-.029.135-.044-.24c.015.045.044.074.059.12.12-.675.181-1.363.181-2.052 0-1.529-.301-3.012-.898-4.42-.569-1.348-1.395-2.562-2.427-3.596-1.049-1.033-2.247-1.856-3.595-2.426-1.318-.631-2.801-.93-4.328-.93-.72 0-1.444.07-2.143.204l.119.06-.239-.033.119-.025C8.91.274 7.829 0 6.731 0c-1.789 0-3.47.698-4.736 1.967C.729 3.235.032 4.923.032 6.716c0 1.143.292 2.265.844 3.258l.02-.124.041.239-.06-.115c-.114.645-.172 1.299-.172 1.955 0 1.53.3 3.017.884 4.416.568 1.362 1.378 2.576 2.427 3.609 1.034 1.05 2.247 1.857 3.595 2.442 1.394.6 2.877.898 4.404.898.659 0 1.334-.06 1.977-.179l-.119-.062.24.046-.135.03c1.002.569 2.126.871 3.294.871 1.783 0 3.459-.69 4.733-1.963 1.259-1.259 1.962-2.951 1.962-4.749 0-1.138-.299-2.262-.853-3.266"/></svg>
                                                            </a>
                                                        </li>
                                                        :
                                                        <li onClick={() => openSocial(socials.Skype,"Skype")} className="skype">
                                                            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Skype</title><path d="M12.069 18.874c-4.023 0-5.82-1.979-5.82-3.464 0-.765.561-1.296 1.333-1.296 1.723 0 1.273 2.477 4.487 2.477 1.641 0 2.55-.895 2.55-1.811 0-.551-.269-1.16-1.354-1.429l-3.576-.895c-2.88-.724-3.403-2.286-3.403-3.751 0-3.047 2.861-4.191 5.549-4.191 2.471 0 5.393 1.373 5.393 3.199 0 .784-.688 1.24-1.453 1.24-1.469 0-1.198-2.037-4.164-2.037-1.469 0-2.292.664-2.292 1.617s1.153 1.258 2.157 1.487l2.637.587c2.891.649 3.624 2.346 3.624 3.944 0 2.476-1.902 4.324-5.722 4.324m11.084-4.882l-.029.135-.044-.24c.015.045.044.074.059.12.12-.675.181-1.363.181-2.052 0-1.529-.301-3.012-.898-4.42-.569-1.348-1.395-2.562-2.427-3.596-1.049-1.033-2.247-1.856-3.595-2.426-1.318-.631-2.801-.93-4.328-.93-.72 0-1.444.07-2.143.204l.119.06-.239-.033.119-.025C8.91.274 7.829 0 6.731 0c-1.789 0-3.47.698-4.736 1.967C.729 3.235.032 4.923.032 6.716c0 1.143.292 2.265.844 3.258l.02-.124.041.239-.06-.115c-.114.645-.172 1.299-.172 1.955 0 1.53.3 3.017.884 4.416.568 1.362 1.378 2.576 2.427 3.609 1.034 1.05 2.247 1.857 3.595 2.442 1.394.6 2.877.898 4.404.898.659 0 1.334-.06 1.977-.179l-.119-.062.24.046-.135.03c1.002.569 2.126.871 3.294.871 1.783 0 3.459-.69 4.733-1.963 1.259-1.259 1.962-2.951 1.962-4.749 0-1.138-.299-2.262-.853-3.266"/></svg>
                                                        </li>
                                                        }
                                                        </>
                                                        }
                                                        {socials.Whatsapp.length > 0 &&
                                                        <>
                                                        {socials.Whatsapp.length == 1 ?
                                                        <li>
                                                            <a className=" whatsapp" href={socials.Whatsapp[0].url} title="Whatsapp" target="_blank">
                                                                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>WhatsApp</title><path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"/></svg>
                                                            </a>
                                                        </li>
                                                        :
                                                        <li onClick={() => openSocial(socials.Whatsapp,"Whatsapp")} className="whatsapp">
                                                            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>WhatsApp</title><path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"/></svg>
                                                        </li>
                                                        }
                                                        </>
                                                        }
                                                        {socials.Pinterest.length > 0 &&
                                                        <>
                                                        {socials.Pinterest.length == 1 ?
                                                        <li>
                                                            <a className=" pinterest" href={socials.Pinterest[0].url} title="Follow On Pinterest" target="_blank">
                                                                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Pinterest</title><path d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z"/></svg>
                                                            </a>
                                                        </li>
                                                        :
                                                        <li onClick={() => openSocial(socials.Pinterest,"Pinterest")} className="pinterest">
                                                            <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Pinterest</title><path d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z"/></svg>
                                                        </li>
                                                        }
                                                        </>
                                                        }
                                                    </ul>
                                                </div>
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="black-area-menu" onClick={menuClose}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <div className='whitearea pc_hide'></div>
            {cookieFadeIn && 
            <div className='cookiepopup'>
                <div className='container cookieimg'>
                    <div className='cookieimage'>
                        <img src={cookieImg} alt="Cookie" />
                    </div>
                </div>
                <div className='container cookietext'>
                    <div className='w90 float-center'>
                        <div className='cookiesubtext container'>
                            <p>We care about your data, and we'd love to use cookies to make your experience better.</p>
                        </div>
                        <div className='container cookiebuttons'>
                            <div className='container float-left'>
                                <button onClick={setAccepted}>Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {showSocials &&
        <div className='socialpop container'>
            <div className='socialpopflx container'>
                <div className='socialspopbox'>
                    <div className='w90 float-center'>
                        <div className='container socialspophead center-text'>
                            <p>{popsType}</p>
                        </div>
                        <div className='container sociallistpop'>
                            <ul>
                                {popData.map((soc,i) =>(
                                <a href={soc.url} title={soc.title} target="_blank" key={i}>
                                    <li>
                                          {soc.type == "Facebook" && 
                                          <div className='socipopicon'>
                                            <svg role="img" fill='#3b5998' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Facebook</title><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>
                                          </div>
                                          } 
                                          {soc.type == "Instagram" && 
                                          <div className='socipopicon'>
                                            <svg role="img" fill='#f26798' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Instagram</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>
                                          </div>
                                          }  
                                          {soc.type == "Linkedin" && 
                                          <div className='socipopicon'>
                                            <svg role="img" fill='#0077B5' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>
                                          </div>
                                          }  
                                          {soc.type == "Pinterest" && 
                                          <div className='socipopicon'>
                                            <svg role="img" fill="#BD081C" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Pinterest</title><path d="M12.017 0C5.396 0 .029 5.367.029 11.987c0 5.079 3.158 9.417 7.618 11.162-.105-.949-.199-2.403.041-3.439.219-.937 1.406-5.957 1.406-5.957s-.359-.72-.359-1.781c0-1.663.967-2.911 2.168-2.911 1.024 0 1.518.769 1.518 1.688 0 1.029-.653 2.567-.992 3.992-.285 1.193.6 2.165 1.775 2.165 2.128 0 3.768-2.245 3.768-5.487 0-2.861-2.063-4.869-5.008-4.869-3.41 0-5.409 2.562-5.409 5.199 0 1.033.394 2.143.889 2.741.099.12.112.225.085.345-.09.375-.293 1.199-.334 1.363-.053.225-.172.271-.401.165-1.495-.69-2.433-2.878-2.433-4.646 0-3.776 2.748-7.252 7.92-7.252 4.158 0 7.392 2.967 7.392 6.923 0 4.135-2.607 7.462-6.233 7.462-1.214 0-2.354-.629-2.758-1.379l-.749 2.848c-.269 1.045-1.004 2.352-1.498 3.146 1.123.345 2.306.535 3.55.535 6.607 0 11.985-5.365 11.985-11.987C23.97 5.39 18.592.026 11.985.026L12.017 0z"/></svg>
                                          </div>
                                          }  
                                          {soc.type == "Skype" && 
                                          <div className='socipopicon'>
                                            <svg role="img" fill='#55ACEE' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Skype</title><path d="M12.069 18.874c-4.023 0-5.82-1.979-5.82-3.464 0-.765.561-1.296 1.333-1.296 1.723 0 1.273 2.477 4.487 2.477 1.641 0 2.55-.895 2.55-1.811 0-.551-.269-1.16-1.354-1.429l-3.576-.895c-2.88-.724-3.403-2.286-3.403-3.751 0-3.047 2.861-4.191 5.549-4.191 2.471 0 5.393 1.373 5.393 3.199 0 .784-.688 1.24-1.453 1.24-1.469 0-1.198-2.037-4.164-2.037-1.469 0-2.292.664-2.292 1.617s1.153 1.258 2.157 1.487l2.637.587c2.891.649 3.624 2.346 3.624 3.944 0 2.476-1.902 4.324-5.722 4.324m11.084-4.882l-.029.135-.044-.24c.015.045.044.074.059.12.12-.675.181-1.363.181-2.052 0-1.529-.301-3.012-.898-4.42-.569-1.348-1.395-2.562-2.427-3.596-1.049-1.033-2.247-1.856-3.595-2.426-1.318-.631-2.801-.93-4.328-.93-.72 0-1.444.07-2.143.204l.119.06-.239-.033.119-.025C8.91.274 7.829 0 6.731 0c-1.789 0-3.47.698-4.736 1.967C.729 3.235.032 4.923.032 6.716c0 1.143.292 2.265.844 3.258l.02-.124.041.239-.06-.115c-.114.645-.172 1.299-.172 1.955 0 1.53.3 3.017.884 4.416.568 1.362 1.378 2.576 2.427 3.609 1.034 1.05 2.247 1.857 3.595 2.442 1.394.6 2.877.898 4.404.898.659 0 1.334-.06 1.977-.179l-.119-.062.24.046-.135.03c1.002.569 2.126.871 3.294.871 1.783 0 3.459-.69 4.733-1.963 1.259-1.259 1.962-2.951 1.962-4.749 0-1.138-.299-2.262-.853-3.266"/></svg>
                                          </div>
                                          }  
                                          {soc.type == "Twitter" && 
                                          <div className='socipopicon'>
                                            <svg role="img" fill='#3cf' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Twitter</title><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/></svg>
                                          </div>
                                          } 
                                           {soc.type == "Whatsapp" && 
                                          <div className='socipopicon'>
                                            <svg role="img" fill='#25D366' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>WhatsApp</title><path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"/></svg>
                                          </div>
                                          } 
                                           {soc.type == "Youtube" && 
                                          <div className='socipopicon'>
                                            <svg role="img" fill='#E52D27' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>YouTube</title><path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/></svg>
                                          </div>
                                          }   
                                          <div className='sociname'>
                                            <p>{soc.title}</p>
                                          </div>
                                    </li>
                                </a>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className='closesocial' onClick={() => setShowSocials(false)}>
                        <FiX />
                    </div>
                </div>
            </div>
        </div>
        }
        </React.Fragment>
    )
}