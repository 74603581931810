const seoTags = (description,keyword,title,headscript,bodyscript) =>{
    window.$('title').text(title)
    window.$('link[rel="canonical"]').attr("href",window.location.href)
    window.$('meta[name="description"]').attr("content",description)
    window.$('meta[name="keywords"]').attr("content",keyword)
    window.$('meta[property="og:url"]').attr("content",window.location.href)
    window.$('meta[property="og:title"]').attr("content",title)
    window.$('meta[property="og:description"]').attr("content",description)
    window.$('meta[name="twitter:title"]').attr("content",title)
    window.$('meta[name="twitter:description"]').attr("content",description)
    // var schema = $('#schemas')
    // schema.html({"@context":"https://schema.org","@type":"BlogPosting","mainEntityOfPage":{"@type":"WebPage","@id":"'+window.location.href+'"},"headline":"'+title+'","description":"'+description+'","image":"https://cdn.cdnbox.org/jvgapp/shoptrozz.png","author":{"@type":"Person","name":"Shoptroz"},"publisher":{"@type":"Organization","name":"Shoptroz","logo":{"@type":"ImageObject","url":"https://cdn.cdnbox.org/jvgapp/shoptrozz.png","width":"143","height":"52"}},"datePublished":"2022-08-27"})
}
export default seoTags;