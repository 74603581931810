global.url = "https://kellysearch.co.in/escholar/";
// global.asset = "https://assets.escholarsadmin.com/"
global.asset = "https://kellysearch.co.in/escholar/"
logoMain = process.env.PUBLIC_URL+'/logo.png'
logoDef = process.env.PUBLIC_URL+'/defaultimg.png'
noDataImg = process.env.PUBLIC_URL+'/nodata.png'
bgImg = process.env.PUBLIC_URL+'/bgpattern.jpg'
expiredImg = process.env.PUBLIC_URL+'/expired.png'
commentImg = process.env.PUBLIC_URL+'/comment.png'
iconLogo = process.env.PUBLIC_URL+'/iconlogo.png'
pagesImg = process.env.PUBLIC_URL+'/min.png'
liveNow = process.env.PUBLIC_URL+'/livenow.png'
liveNow2 = process.env.PUBLIC_URL+'/livenow2.png'
Mode = "Web"
defaultToken = "7|Ha08IBLf9iCmUF6nvfq7C864gEwTLe1Sl9FmiPud"
googleKey = "673758167392-2mkmr7r4miqi3vjujr932fndkmg5grs2.apps.googleusercontent.com"