import React,{useState,useEffect} from 'react'
import { Cookies, useCookies } from 'react-cookie';
import Sidebar from './Sidebar'
import TopBar from './TopBar'
import LoadingIndicator from 'react-loading-indicator';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { BsArrowRight, BsFillBookmarksFill } from 'react-icons/bs';
import moment from 'moment';
import { FaFilter } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import ELoader from '../eLoader';
import noBook from './images/bookmrk.png'

export default function Bookmarks() {
    const [act,setAct] = useState("1")
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(null)
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [refresh,setRefresh] = useState("Yes")
    const [questions,setQuestions] = useState("")
    const [showQuestions,setShowQuestions] = useState(true)
    let User = cookies.user;
    const newToken = cookies.userToken
    var a = 0;
    useEffect(() => {
      if(a == 0){
        fetchBookmarks()
      }
    }, [])
    const fetchBookmarks = () =>{
        setLoading(true)
        axios.post(global.url + "api/user/getbookmarks",{
            userid : User.userid
        },{
            headers:{
            'Content-Type': 'application/json',
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            setQuestions(res.data.questions)
            console.log(res.data)
            setLoading(false)
        },(error) =>{
            setError(error)
        })
    }
    
    const bookQ = (book,id) =>{
        axios.post(global.url + "api/user/removegetbookmark",{
            userid: User.userid, 
            id: id,
            book: book,
        },{
            headers : {
                "content-type": "application/json",   
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            if(res.data.status == "OK"){
                fetchBookmarks()
            }
        },
        (error) =>{
            setError(error)
        })
    }
    const optionsArray = [1,2,3,4,5,6]

    if(loading) 
    return(
        <section className='userdashboard '>
            <div className='my100 float-left dashboxmain'>
                <div className='sidebar-box float-left'>
                    <Sidebar act={3}/>
                </div>
                <div className='dashcontent float-left'>
                    <TopBar heading="My Bookmarks"/>
                    <div className='insideloading container'>
                        <ELoader /> 
                    </div>
                </div>  
            </div>
        </section>
    )
    else if(error) 
    return(
        <p>{error.message}</p>
    )
    else
      return (
        <>
        <section className='userdashboard '>
            <div className='my100 float-left dashboxmain'>
                <div className='sidebar-box float-left'>
                    <Sidebar act={3}/>
                </div>
                <div className='dashcontent float-left'>
                    <TopBar heading="My Bookmarks"/>
                    <div className='container bookbox maxwidth'>
                        <div className='w90 float-center booknt'>
                            <div className='container'>
                                <div className=' float-center tabs-top '>
                                    <div className={` float-left tabsmain ${(act == "1") ? "acttab2" : ""}`}>
                                        <p>Questions</p>
                                    </div>
                                    {/* <div className={` float-left tol tabsmain ${(act == "2") ? "acttab2" : ""}`} >
                                        <p>PDfs</p>
                                    </div> */}
                                </div>
                            </div>
                            <div className='container booklist'>
                                {showQuestions ?
                                <>
                                {questions != "" ?
                                <div className='container questionslist'>
                                    {questions.map((res,i) =>{
                                        let quiz_data = JSON.parse(res.quizdata)
                                        let ans
                                        if(res.questiontype == "Fill_In_The_Blanks") {
                                            ans = JSON.parse(res.correct)
                                        }
                                        return(
                                    <div className='container answersmainbxresult' key={i}>
                                        <div className='w90 float-center answersbox'>
                                            <div className='w95 float-center'>
                                                <div className='answers-topbar container'>
                                                    <div className='answerslside'>
                                                        <p>Question {i+1}</p>
                                                    </div>
                                                    {res.path != undefined && res.path != null && res.path != "" && <>
                                                    <div className='pathside'>
                                                        <div className='container filepath'>
                                                            <div className='container float-center pathflx'>
                                                                <ul>
                                                                    <li>{res.coursedata.name} / </li>
                                                                    {res.path.map((path,i) =>(
                                                                        <li key={i}>{path.name} {res.path.length == i+1 ? "" : "/"} </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                            {quiz_data.essay != null && quiz_data.essay != "" && quiz_data.essay != undefined &&
                                            <div className='container mainquestion'>
                                                <div className='w95 float-center questdiv'>
                                                    <div className='w98 float-center questtext ck-content' dangerouslySetInnerHTML={{__html : quiz_data.essay}}></div>
                                                </div>
                                            </div>
                                            }
                                            <div className='container mainquestion'>
                                                <div className='w95 float-center questdiv'>
                                                    <div className='w98 float-center questtext ck-content' dangerouslySetInnerHTML={{__html : quiz_data.question}}></div>
                                                </div>
                                            </div>
                                            <div className='container bookmarkbox bookpg'>
                                                <div className='w90 float-center right-text bookingflx'>
                                                    <div className='bookbutton' onClick={() => bookQ(false , res.id)}><BsFillBookmarksFill />Remove Bookmark </div>
                                                </div>
                                            </div>
                                            <div className='container answersbx'>
                                                {(quiz_data.question_type !== "Subjective" && quiz_data.question_type !== "Fill_In_The_Blanks") &&
                                                <div className='container answerhead'>
                                                    <div className='w95 float-center'>
                                                        <p>Options</p>
                                                    </div>
                                                </div>
                                                }
                                                {(quiz_data.question_type !== "Subjective" || quiz_data.question_type !== "Fill_In_The_Blanks") &&
                                                    optionsArray.map((item,index) =>{
                                                        var op = "A"
                                                        if(item === 1){ op = "A" }
                                                        else if(item === 2){ op = "B" }
                                                        else if(item === 3){ op = "C" }
                                                        else if(item === 4){ op = "D" }
                                                        else if(item === 5){ op = "E" }
                                                        else if(item === 6){ op = "F" }
                                                        var col = "option"+item 
                                                        return(
                                                            quiz_data[col] !== null && quiz_data[col] !== "" && 
                                                            <div className='container mainquestion' key={index}>
                                                                <div className={`w95 float-center questdiv answerboxmain `} >
                                                                    <div className='w98 float-center questtext answerbx ck-content'>
                                                                        <div className='answeropt'>{op}</div>
                                                                        <div className='answemainside'  dangerouslySetInnerHTML={{__html : quiz_data[col]}}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {/* {(quiz_data.question_type === "Fill_In_The_Blanks") && 
                                            <div className='container answersbx youransbx'>
                                                <div className='container answerhead'>
                                                    <div className='w95 float-center'>
                                                        <p>Correct Answer</p>
                                                    </div>
                                                </div>
                                                <div className='container mainquestion' >
                                                    <div className="w95 float-center questdiv answerboxmain" >
                                                        <div className='w98 float-center questtext answerbx'> 
                                                            <p>{ans}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            
                                            {(quiz_data.question_type !== "Subjective" && quiz_data.question_type !== "Fill_In_The_Blanks") &&
                                            <div className='container answersbx youransbx'>
                                                <div className='container answerhead'>
                                                    <div className='w95 float-center ateemptanswe'>
                                                        <p>Correct Answer</p>
                                                    </div>
                                                </div>
                                                <div className='container mainquestion' >
                                                    <div className="w95 float-center questdiv answerboxmain" >
                                                        <div className='w98 float-center questtext answerbx'> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            } */}
                                            {quiz_data.explanation !== null  && 
                                            <div className='container answersbx youransbx'>
                                                <div className='container answerhead'>
                                                    <div className='w95 float-center'>
                                                        <p>Explanation</p>
                                                    </div>
                                                </div>
                                                <div className='container mainquestion' >
                                                    <div className="w95 float-center questdiv answerboxmain" >
                                                        <div className='w98 float-center questtext ck-content' dangerouslySetInnerHTML={{__html: quiz_data.explanation}}> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                        )
                                    })}
                                </div>
                                :
                                <div className='emptybox container'>
                                    <div className='emptyimg'>
                                        <img src={noBook} alt="No Data Found" />
                                    </div>
                                    <div className='emptypara container'>
                                        <p>No Bookmarks Present</p>
                                    </div>
                                </div> 
                                }
                                </>
                                :
                                <p>PDFS</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
