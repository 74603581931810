import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

export default function Expiring(props) {
    var a = 0;
    const navigate = useNavigate()
    useEffect(() => {
       let myInterval = setInterval(function(){
            getExpiry(myInterval)
        },15000)
    }, [])
    const getExpiry = (myInterval) =>{
        axios.post(global.url + "api/user/changeHour",{
            purchase_id : props.purchaseid,
            userid : props.user.userid,
            course_id : props.courseid,
            mode:Mode
        },
        {
            headers : {
                "content-type": "application/json",
                "Authorization" : "Bearer "+(props.token != null && props.token != "" && props.token != undefined ? props.token : defaultToken)
            }    
        })
        .then((res) =>{
            if(res.data.seconds < 1){
                navigate("/dashboard/c/expired/" + props.courseid + '/'+ props.purchaseid + '/' + props.folderid,{state:{expired : "expired"},replace : true})
                clearInterval(myInterval)
            }
            else{
                clearInterval(myInterval)
            }
        },
        (error) =>{
            setError(error)
        }
        )
    }
}
