import React, { useEffect, useState ,useRef} from 'react'
import {FiChevronRight, FiX} from 'react-icons/fi' 
import {FaStar,FaStarHalfAlt,FaCaretRight, FaBell, FaSpinner} from 'react-icons/fa'
import {FiChevronDown,FiInfo,FiEye} from 'react-icons/fi'
import $ from 'jquery'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import wappIcon from '../whatsapp2.png'
import folder3 from './folder3.png'
import videoBig from './videobig.png'
import pdf2 from './pdf2.png'
import quiz from './quiz.png'
import image from './image.png'
import questions from './question.png'
import recording from './recording.png'
import youtubes from './youtube.png'
import assignment from './assignment.png'
import live from './live.png'
import LoadingIndicator from 'react-loading-indicator'
import axios from 'axios'
import { useLocation,useParams ,useNavigate,Link} from 'react-router-dom'
import { Cookies, useCookies } from 'react-cookie';
import cartGifB from './cart.gif'
import cartGifw from './cartw.apng'
import Preview from './Preview'
import { BsArrowRight, BsCheckCircleFill, BsChevronRight } from 'react-icons/bs'
import seoTags from '../SeoTags'
import ELoader from '../eLoader'
import notFImg from './ivld.png'
import ProductCard from './ProductCard'
import Menu from '../Menu'
import Footer from '../footer/Footer'

export default function Productpage() {
    const [tabContent,setTabContent] = useState("")
    const [foldersLoading,setFolderLoading] = useState(false)
    const [actCourse,setActCourse] = useState(1)
    const [cookies,setCookie,removeCookie] = useCookies('')
    const [error,setError] = useState(null)
    const [pageLoading,setPageLoading] = useState(true)
    const [productData,setProductData] = useState("")
    const [subCourses,setSubCourses] = useState("")
    const [activePlan,setActivePlan] = useState("")
    const [successPop,setSuccessPop] = useState(false)
    const [preview,setPreview] = useState(false)
    const [previewData,setPreviewData] = useState("")
    const [prevBox,setPrevBox] = useState("")
    const [actTabProd,setActTabProd] = useState(2)
    const [notFound,setNotfound] = useState(false)
    const [logPop ,setLogPop] = useState(false)
    const [related,setRelated] = useState("")
    const newToken = cookies.userToken
    const params = useParams()
    const navigate = useNavigate()
    const useQuery = () => {
        return new URLSearchParams(useLocation().search)
    }
    var mainid = params.id
    var mainslug = params.slug
    var mainuser = cookies.user
    const query = useQuery()
    var offermain = query.get("offer")
    var previewpg = query.get("preview")
    var actplan = query.get("plan_id")
    const [folderid,setFolderid] = useState(0)
    var a =0;
    useEffect(() =>{
        if(a == 0){
            setCookie('callback',(window.location.href),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
            setPageLoading(true)
            if(cookies.user != "" && cookies.user != null && cookies.user != undefined){
                getCourse(cookies.user.userid)
            }else{
                getCourse("")
            }
            if ($(window).width() < 960){
                var style = "<style>.mainfooter{margin-bottom:110px}.whatsappbut{display:none}</style>"
                window.$('body').append(style)
            }
        }
        a++
    },[])
    
    const [liveShow,setLiveShow] = useState(false)
    const getCourse = (id) =>{
        setPageLoading(true)
        axios.post(global.url + "api/user/getcourse",null,{
            headers : {
                'Authorization' : "Bearer " + cookies.maintoken,
                'Accept' : 'application/json'
            },
            params:{
                courseid : mainid,
                mode : Mode,
                userid : id,
                offer : (offermain != undefined && offermain != null && offermain != "") ? offermain : "",
                preview : (previewpg != undefined ) ? true : false,
                activePlan : (actplan != undefined && actplan != null && actplan != "") ? actplan : "",
            }
        })
        .then((res) =>{
            if(res.data.status == "Failed"){
                setNotfound(true)
                setPageLoading(false)
                setProductData(res.data)
            }else{
                fetchRelated(id)
                setProductData(res.data)
                setActivePlan(res.data.course.activeplan)
                setActPlanData(res.data.course.activeplanprice)
                seoTags(res.data.course.seodescription,res.data.course.seokeywords,res.data.course.seotitle,res.data.course.seoheadscript,res.data.course.seobodyscript)
                getContent(folderid,"false")
                if(res.data.course.preview) setPreviewData(res.data.course.previewcontent)
                else setPreviewData("")
                if(!res.data.course.activeclass) setLiveShow(false)
                else setLiveShow(true)
                if(res.data.course.notifyme) setAlredyNotified(true)
                setPageLoading(false)
            }
        },(error)=>{
            setError(error)
        }
        )
    }
    const fetchRelated = (id) =>{
        axios.post(global.url + "api/user/relatedProducts",null,{
            headers : {
                'Authorization' : "Bearer " + cookies.maintoken,
                'Accept' : 'application/json'
            },
            params:{
                courseid : mainid,
                mode : Mode,
                userid : id
            }
        })
        .then((res) =>{
            console.log(res.data)
            if(res.data.status == "OK"){
                setRelated(res.data.relatedCourses)
            }else{
                setRelated("")
            }
        },(error) =>{
            console.log(error.message)
        })
    }
    const myRef = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView();

    const getContent = (folderid,x) =>{
        setFolderLoading(true)
        axios.post(global.url + "api/user/getcoursecontent",{
            courseid : mainid,
            mode : Mode,
            p_id : folderid
        },{
            headers : {
                'Authorization' : "Bearer " + cookies.maintoken,
                'Accept' : 'application/json'
            }
        })
        .then((res) =>{
            setSubCourses(res.data)
            if(x == "true") executeScroll()
            setFolderLoading(false)
        },(error) =>{
            console.log(error.message)
        })
    }
    const showCourses = (x,subcourse) =>{
        setFolderLoading(true)
        setActCourse(x)
        setSubCourses(subcourse)
        setTimeout(function(){
            setFolderLoading(false)
        },1000)
    }
    const whySlides = (id) =>{
        var icon = window.$(".tabbox[data-bind="+id+"]").find(".tabicon");
        if(icon.hasClass("showed")){
            window.$("#"+id).slideUp()
            icon.removeClass("showed")
        }else{
            window.$("#"+id).slideDown()
            icon.addClass("showed")
        }
    }
    const faqSlides = (id) =>{
        var icon = window.$(".tabbox[data-bind="+id+"]").find(".tabicon");
        if(icon.hasClass("showed")){
            window.$("#"+id).slideUp()
            icon.removeClass("showed")
        }else{
            window.$("#"+id).slideDown()
            icon.addClass("showed")
        }
    }
    // ************Add to Cart****************
    const cartData = cookies.cartdata
    const addtocart = () =>{
        if(cartData != null && cartData != "" && cartData != undefined){
            var temp = cookies.cartdata
            var exist = false
            for(var i=0;i<temp.length;i++){
                if(temp[i]['courseid'] === mainid){
                    exist = true
                    temp[i]['planid'] = activePlan
                }
            }
            if(exist === false){
                temp.push({courseid: mainid, planid: activePlan,offer : offermain})
            }
            setCookie('cartdata',JSON.stringify(temp),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
            window.location.href = '/mycart'
        }
        else{
            var temp = []
            temp.push({courseid : mainid,planid : activePlan,offer : offermain})
            setCookie('cartdata',JSON.stringify(temp),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
            window.location.href = '/mycart'
        }
    }
    // ************Add to Cart****************
    const [prevTwo,setPrevTwo] = useState(false)
    const prevMain = (data) =>{
        setPreview(false)
        setPrevBox(data)
        setPrevTwo(true)
    }
    const backPrev = () =>{  
        setPrevTwo(false)
        setPrevBox("")
        setPreview(true)
    }
    const closePreview = () =>{
        setPrevTwo(false)
        setPrevBox("")
        setPreview(false)
    }
    const [actPlanData,setActPlanData] = useState("")
    const buynow = () =>{
        if(cookies.user != "" && cookies.user != null && cookies.user != undefined){
            var temp = [];
            temp.push({courseid: mainid, planid: activePlan})  
            axios.post(global.url + "api/user/getcart",{
                userid: cookies.user.userid,
                cart: temp,
            },{
                headers : {
                    'Authorization' : "Bearer " + (newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken),
                    'Accept' : 'application/json'
                }
            })
            .then((res) =>{
                if(res.data.status === "OK"){
                    addToLibrary(res.data.cart, res.data.price)
                }
            },(error) =>{
                console.log(error.message)
            })
        }
        else{
            setLogPop(true)
        }
    }
    const addToLibrary = (cart,price) =>{
        axios.post(global.url + "api/user/checkout",{
            mode: Mode,
            price: price,
            userid: cookies.user.userid,
            cart: cart,
            payment_id: "",
            address: "",
            gst: ""
        },{
            headers : {
                'Authorization' : "Bearer " + (newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken),
                'Accept' : 'application/json'
            }
        })
        .then((res) =>{
            if(res.data.status === "OK"){
                window.location.href = "/success?"+res.data.cartid
            }
        },(error) =>{
            console.log(error.message)
        })
    }
    const [notifLoading,setNotifLoading] = useState(false)
    const [alredyNotified,setAlredyNotified] = useState(false)
    const [notifPop,setNotifPop] = useState(false)
    const notifyMe = () =>{
        if(cookies.user != undefined) {
        setNotifLoading(true)
        axios.post(global.url + "api/user/Notifyme",{
            course_id:mainid,
            mode:Mode,
            userid:cookies.user.userid
        },{
            headers : {
                'Authorization' : "Bearer " + (newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken),
                'Accept' : 'application/json'
            }
        })
        .then((res) =>{
            setNotifPop(true)
            setAlredyNotified(true)
            setNotifLoading(false)
            setTimeout(function(){
                setNotifPop(false)
            },3000)
        },(error) =>{
            console.log(error.message)
        })
    }
    else{
        setLogPop(true)
    }
    }
    if(pageLoading){
        return (
            <div className='loadingbox signloading'>
                <div className="loadercircle container"><ELoader /> </div> 
            </div>
        )
    }
    else if(error){
        return(
        <p>Error</p>
        )
    }
    else
    return (
    <React.Fragment>
        <Menu />
        {notFound ?
        <section className='errorboxcourse'>
            <div className='container maxwidth'>
                <div className='w90 float-center center-text'>
                    <div className='notfoundimg'>
                        <img src={notFImg} alt="Not Found" />
                    </div>
                    <p>{productData.message}</p>
                </div>
            </div>
        </section>
         :
    <section className='product-page'>
        <div className='my100 '>
            <div className='my100 float-center productmainbox'>
                <div className='container product-details black-deets'>
                    {productData.course.cover != undefined && productData.course.cover != null && productData.course.cover != "" &&
                    <div className='balcksbg container'>
                        <img src={global.asset + productData.course.cover} alt='Result Banner' />
                    </div>
                    }
                    <div className={`container blacksfront ${productData.course.cover != undefined && productData.course.cover != null && productData.course.cover != "" ? "" : "prodbgblack"}`}>
                        <div className='w90 float-center productbox maxwidth'>
                            <div className='w70 float-left leftsides'>
                                <div className='w90 float-left leftflmbox'>
                                    {/* <div className='container navigation'>
                                        <ul>
                                            <li>
                                                <a href='#' title=''>Courses</a> <FiChevronRight/>
                                            </li>
                                            <li>
                                                <a href='#' title=''>CA Inter</a> <FiChevronRight/>
                                            </li>
                                            <li>
                                                <p>Course</p>
                                            </li>
                                        </ul> 
                                    </div> */}
                                    <div className='container pagecardimage pc_hide'>
                                    {productData.course.thumbnail == "" || productData.course.thumbnail == null || productData.course.thumbnail == undefined
                                    ? 
                                    <div className='cimage defaultimage'>
                                        <img src={logoDef} alt={productData.course.coursedata.name} />
                                    </div>
                                    :                              
                                    <div className='pageimage'>
                                        {(productData.course.youtube_thumbnail != "" && productData.course.youtube_thumbnail != null && productData.course.youtube_thumbnail != undefined) ? 
                                            <video autoPlay muted loop controls controlsList="nodownload">
                                                <source src={productData.course.youtube_thumbnail} />
                                            </video>
                                            // <iframe src={productData.course.youtube_thumbnail} allow="autoplay; fullscreen"></iframe>
                                        :
                                        <img src={global.asset + productData.course.thumbnail} alt={productData.course.coursedata.name} />
                                        }
                                    </div>
                                    }
                                    </div>
                                    
                                    {productData.course.showbannerdata != undefined && productData.course.showbannerdata != null && productData.course.showbannerdata == 1 && 
                                    <>
                                    <div className='container coursename'>
                                        <p>{productData.course.coursedata.name}</p>
                                    </div>
                                    <div className='container subpara'>
                                        <p>
                                        {productData.course.coursedata.subheading}
                                        </p>
                                    </div>
                                    {(productData.course.coursetype != "Course") && (productData.course.packagecontentcount != undefined) && (productData.course.packagecontentcount > 0) &&
                                    <div className='cousetags container'>
                                        <ul>
                                            <li>{productData.course.packagecontentcount} Courses</li>
                                        </ul>
                                    </div>
                                    }
                                    <div className='container highlights'>
                                        <ul>
                                            {
                                            productData.course.coursedata.language != "" && productData.course.coursedata.language != null &&
                                            <li>Language - <span>{productData.course.coursedata.language}</span></li>
                                            }
                                            {
                                            productData.course.coursetype == "Course" && productData.course.coursedata.instructor != undefined && productData.course.coursedata.instructor != null && productData.course.coursedata.instructor != "" && 
                                            <li>Instructor - <span>{productData.course.coursedata.instructor}</span></li>
                                            }
                                        </ul>
                                    </div>
                                </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='my100 product-data overflow-visible float-left'>
                    <div className='my90 float-center overflow-visible data-flex maxwidth'>
                        <div className='w70 float-left leftsides'>
                            <div className='w90 float-left leftcontentbox'>
                                {productData.course.topeditor != undefined && productData.course.topeditor != null && productData.course.topeditor != "" && <>
                                <div className='container topcontent ck-content' dangerouslySetInnerHTML={{__html : productData.course.topeditor}}>

                                </div>
                                </>  }
                                {productData.course.including.length > 0 &&
                                <div className='contentbox container'>
                                    <div className='container cardhighhead'>
                                        <p>This course includes :</p>
                                    </div>
                                    <div className='container cardhiglights'>
                                        <div className='cardhighs w95 float-center'>
                                            <ul>
                                                {productData.course.including.map((inc , i) =>(
                                                <li key={i}>
                                                    <div className='highicon'>
                                                        <img src={(inc.icon != null && inc.icon != "") ? global.asset + inc.icon : iconLogo} alt={inc.title} />
                                                    </div>
                                                    <div className='hightext'>
                                                        <p>{inc.title}</p>
                                                    </div>
                                                </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                }
                                <div className='container tabonsprod'  ref={myRef}>
                                    <ul>
                                        <li className={(actTabProd == 2) ? "activeprodtab" : ""} onClick={() => setActTabProd(2)}>Description</li>
                                        <li className={(actTabProd == 1) ? "activeprodtab" : ""} onClick={() => setActTabProd(1)}>
                                            {(productData.course.coursecontent != "" && productData.course.coursecontent != null) ? "Course " : 
                                               (productData.course.packagecontent != "" && productData.course.packagecontent != null) && "Package " 
                                            }
                                            Content
                                        </li>
                                        <li className={(actTabProd == 3) ? "activeprodtab" : ""}  onClick={() => setActTabProd(3)}>How to use</li>
                                    </ul>
                                </div>
                                {actTabProd == 1 && <>
                                {productData.course.coursetype == "Package" ?
                                productData.course.packagecontent != "" && productData.course.packagecontent != null &&
                                <div className='container contentdrops packagecontentbox'>
                                    <div className='container sectionhead'>
                                        <p>Package Content</p>
                                    </div>
                                    <div className='container packages'>
                                        {productData.course.packagecontent.map((pkg , i) =>(
                                        <div className='float-left packagecardbox' key={i}>
                                            <a href={`/p/` + pkg.slug + "/" + pkg.otherid} title={pkg.coursedata.name} >
                                                <div className='w90 float-center packagecard'>
                                                    <div className='container'>
                                                        {pkg.thumbnail == "" || pkg.thumbnail == null || pkg.thumbnail == undefined
                                                        ?
                                                        <div className='packageimg'>
                                                            <img src={logodef} alt={pkg.coursedata.name} />
                                                        </div>
                                                        :
                                                        <div className='packageimg'>
                                                            <img src={global.asset + pkg.thumbnail} alt={pkg.coursedata.name} />
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className='container pack-details'>
                                                        <div className='w90 float-center'>
                                                            <div className='container pack-name'>
                                                                <p>{pkg.coursedata.name}</p>
                                                            </div>
                                                        </div>
                                                        <div className='container packaction'>
                                                            <div className='container float-left'>
                                                                <div className='w90 float-center'>
                                                                    <button className='infobut'><span>Info</span> <FiInfo /></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                                :
                                productData.course.coursetype != "" && productData.course.coursecontent != null && productData.course.coursecontent.length > 0 &&
                                <div className='container contentdrops'>
                                    <div className='container sectionhead sectheadflx'>
                                        <p>Course Content</p>
                                    {subCourses != "" && <>
                                        {subCourses.path.length > 0 &&
                                        <div className='pathlist'>
                                            <ul>
                                                <li className='otherels' onClick={() => getContent(folderid,"false")}>Root <span><BsChevronRight /></span></li> 
                                                {subCourses.path.map((path , i) =>(
                                                <li key={i} className={(subCourses.path.length == i+1) ? "lastlist" : "otherels"} onClick={() => (subCourses.path.length == i+1) ? console.log("lastel") : getContent(path.id,"true")} title={path.name}>
                                                    <p>{path.name}</p> {(subCourses.path.length !== i+1) && <span><BsChevronRight /></span>}
                                                </li>
                                                ))}
                                            </ul>
                                        </div>
                                        }
                                        </>
                                        }
                                    </div>
                                    <div className='container mainstepsbox'>
                                        <div className='container topbarsteps'>
                                            <div className='w30 float-left sidebarbox'>
                                                <div className='container sidebarhead'>
                                                    <div className='w95 float-center'>
                                                        <p>Timeline</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w70 float-left sidebarcontent'>
                                                <div className='container float-center'>
                                                    <div className='container'>
                                                        <div className='container navigation'>
                                                            <ul>
                                                            {subCourses != "" &&
                                                                <>
                                                                {subCourses.path.length < 1 ?
                                                                <li className='firtname'>
                                                                    <p>Root</p>
                                                                </li>
                                                                :
                                                                <>
                                                                {subCourses.path.map((path , i) =>(
                                                                    subCourses.path.length === (i + 1) &&
                                                                    <li key={i} className='firtname'>
                                                                        <p  onClick={() => getContent(path.p_id,"true")} title={path.name}>Back</p>
                                                                    </li>
                                                                ))}
                                                                </>
                                                                }
                                                                </>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='container float-left sidebarcontent'>
                                            <div className='container float-center'>
                                            {foldersLoading ?
                                                <div className='container insidefolders folderloadings'>
                                                    {[...Array(5)].map((i,index) => {
                                                        return (
                                                        <div className='infolder container' key={index}>
                                                            <div className='container float-center infolderbox'>
                                                                <div className='infoldericon'>
                                                                    <div className='infolicon'>
                                                                        <Skeleton width={40} height={40}/>
                                                                    </div>
                                                                </div>
                                                                <div className='infoldertext '>
                                                                    <div className='w95 float-center'>
                                                                        <p><Skeleton width="80%" height={25}/></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )})}
                                                </div>
                                                :
                                                <div className='container insidefolders'>
                                                 {(subCourses != "") &&
                                                 <>
                                                    {subCourses.content.length < 1 ?
                                                        <div className="nodatabox container" >
                                                            <div className='w95 float-center emptyfilex'>
                                                                <div className='nodataimg'>
                                                                    <img src={noDataImg} alt="No data found" />
                                                                </div>
                                                                <div className='container nofiles'>
                                                                    <p>No files found</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    :
                                                    subCourses.content.map((sublist , j) =>(
                                                    <div className='infolder container' key={j}>
                                                        <div className='container float-center infolderbox' title={sublist.name} onClick={() => (sublist.type == "Folder") ? getContent(sublist.id,"true") : console.log("not a folder")}>
                                                            <div className='infoldericon'>
                                                                <div className='infolicon'>
                                                                    {(sublist.type == "Folder") &&
                                                                        <img src={folder3} alt="" />
                                                                    }
                                                                    {sublist.type == "Document" &&
                                                                        <img src={pdf2} alt="" />
                                                                    }
                                                                    {(sublist.type == "Quiz") &&
                                                                        <img src={quiz} alt="" />
                                                                    }
                                                                    {(sublist.type == "Image") &&
                                                                        <img src={image} alt="" />
                                                                    }
                                                                    {(sublist.type == "Video") &&
                                                                        <img src={videoBig} alt="" />
                                                                    }
                                                                    {(sublist.type == "Question") &&
                                                                        <img src={questions} alt="" />
                                                                    }
                                                                    {(sublist.type == "Recording") &&
                                                                        <img src={recording} alt="" />
                                                                    }
                                                                    {(sublist.type == "Youtube") &&
                                                                        <img src={youtubes} alt="" />
                                                                    }
                                                                    {(sublist.type == "Live") &&
                                                                        <img src={live} alt="" />
                                                                    }
                                                                    {(sublist.type == "Assignment") &&
                                                                        <img src={assignment} alt="" />
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='infoldertext'>
                                                                <div className='w90 float-center '>
                                                                    <div className='container fileflexprod'>
                                                                        <p>{sublist.name}</p> 
                                                                        {sublist.activeclass &&
                                                                            <div className='livetag'>
                                                                                <img className='liveimg' src={liveNow2} alt='' />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    {sublist.subtext != undefined && sublist.subtext != null && sublist.subtext != "" && <>
                                                                    <div className='filesubtxt container'>
                                                                        <p>{sublist.subtext}</p>
                                                                    </div>
                                                                    </> }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ))}
                                                    </>
                                                    }
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                </>
                                }
                                {actTabProd == 2 && <>
                                {productData.course.why.length > 0 &&
                                <div className='container contentbox'>
                                    <div className='container cardhighhead'>
                                        <p>Why Choose This Course</p>
                                    </div>
                                    {productData.course.why.map((why, i) =>(
                                        why.title != "" && why.title != "" &&
                                    <div className='container tabboxes' key={i}>
                                        <div className='container tabbox' data-bind={`tab${i}`} onClick={() => whySlides(`tab${i}`)}>
                                            <div className='w10 float-left center-text iconside'>
                                                <div className='w90 float-center tabicon'>
                                                    <FiChevronDown />
                                                </div>
                                            </div>
                                             <div className='w90 float-left'>
                                                <div className='w98 float-left'>
                                                    <p>{why.title}</p>
                                                </div>
                                             </div>
                                        </div>
                                        <div className="container tabcontent" id={`tab${i}`}>
                                            <div className='w95 float-center ck-content description-box'
                                            dangerouslySetInnerHTML={{__html:why.body}}>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                                }
                                </>}
                                {actTabProd == 3 && <>
                                {productData.course.faq.length > 0 &&
                                <div className='container contentbox'>
                                    <div className='container cardhighhead'>
                                        <p>Frequently Asked Questions</p>
                                    </div>
                                    {productData.course.faq.map((faq, i) =>(
                                        faq.title != "" && faq.title != "" &&
                                    <div className='container tabboxes' key={i}>
                                        <div className='container tabbox' data-bind={`faq${i}`} onClick={() => faqSlides(`faq${i}`)}>
                                            <div className='w10 float-left center-text iconside'>
                                                <div className='w90 float-center tabicon'>
                                                    <FiChevronDown />
                                                </div>
                                            </div>
                                             <div className='w90 float-left'>
                                                <div className='w98 float-left'>
                                                    <p>{faq.title}</p>
                                                </div>
                                             </div>
                                        </div>
                                        <div className="container tabcontent" id={`faq${i}`}>
                                            <div className='w95 float-center ck-content description-box'
                                            dangerouslySetInnerHTML={{__html:faq.body}}>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                                }
                                </>}
                                {productData.course.bottomeditor != undefined && productData.course.bottomeditor != null && productData.course.bottomeditor != "" && <>
                                <div className='container topcontent botcontent ck-content' dangerouslySetInnerHTML={{__html : productData.course.bottomeditor}}>

                                </div>
                                </>  }
                            </div>
                        </div>
                        <div className='my30 float-left cardside overflow-visible float-left'>
                            <div className='w90 float-center product-pagecard' style={{marginTop : (($('.product-page').innerHeight() < 400) ? "0" : "-300px")}}>
                                
                                <div className='container pagecardimage mob_hide'>
                                
                                {productData.course.thumbnail == "" || productData.course.thumbnail == null || productData.course.thumbnail == undefined
                                ? 
                                <div className='cimage defaultimage'>
                                    <img src={logoDef} alt={productData.course.coursedata.name} />
                                </div>
                                :                              
                                <div className='pageimage'>
                                    {(productData.course.youtube_thumbnail != "" && productData.course.youtube_thumbnail != null && productData.course.youtube_thumbnail != undefined) ? 
                                        <video autoPlay muted loop controls controlsList="nodownload">
                                            <source src={productData.course.youtube_thumbnail} />
                                        </video>
                                        // <iframe src={productData.course.youtube_thumbnail} allow="autoplay; fullscreen"></iframe>
                                    :
                                    <img src={global.asset + productData.course.thumbnail} alt={productData.course.coursedata.name} />
                                    }
                                </div>
                                }
                                </div>
                                {productData.course.btns != undefined && productData.course.btns != "" && productData.course.btns.hide_all_buttons === "No" && 
                                <>
                                <div className='container pagecard-loweside'>
                                    <div className='w90 float-center'>
                                        
                                    {productData.course.btns.hide_plans == "No" &&
                                        <>
                                        {productData.course.plans.map((plan , i) =>(
                                        plan.id == activePlan && productData.course.btns.hide_all_buttons === "No" &&
                                        <div className='container float-left pricebox' key={i}>
                                            {plan.selling_price == "0" ?
                                            <>
                                                <p className='freetag'>Free</p>
                                                <p className='validitysub'><span>{plan.tag}</span></p>
                                            </>
                                            : 
                                            <>
                                                <div className='lsidep'>
                                                    <p className='mainp'><span className='pricst'>₹</span> {plan.selling_price}</p>
                                                    <span className='oldpbx'><span className='pricst'>₹</span><p className='oldp'> {plan.listing_price}</p></span>
                                                    <p className='disc'>{plan.off} % Off</p>
                                                </div>
                                                <p className='validitysub'><span>{plan.tag}</span></p>
                                            </>
                                            }
                                        </div>
                                        ))}
                                        <div className='container coursename cardboxcname'>
                                            <p>{productData.course.coursedata.name}</p>
                                        </div>
                                        <div className='container validity coursescat-normal'>
                                            <p className='validityhead slctpln'>Select Plan :</p>
                                            <ul>
                                                {productData.course.plans.map((plan , i) =>(
                                                    <li className={plan.id == activePlan ? "actcat" : ""} key={i} onClick={() => {setActivePlan(plan.id),setActPlanData(plan.selling_price)}}>
                                                        <p>{plan.plan_name}</p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        </>
                                        }
                                        {(productData.course.coursedata.contain != null && productData.course.coursedata.contain != "") &&
                                        <div className='container subpara'>
                                            <p>
                                            {productData.course.coursedata.contain}
                                            </p>
                                        </div>
                                        }
                                        {productData.course.btns != undefined && productData.course.btns != "" && productData.course.btns.hide_all_buttons === "No" && 
                                        <>
                                        <div className='container fixedbuttons'>
                                            <div className='container buybuttons'>
                                                {productData.course.purchased ? 
                                                <>
                                                {productData.course.btns.hide_buy_now_button === "No" && <>
                                                <div className={`buybutton float-left w50 `}>
                                                    <div className={`float-left w95 `}>
                                                        <button title='Buy Now' onClick={() => actPlanData > 0 ? addtocart() : buynow()}>{actPlanData > 0 ? "Repurchase" : "Add To Library"}</button>
                                                    </div>
                                                </div>
                                                </>
                                                }
                                                </>
                                                :
                                                <>
                                                {(productData.course.preview && productData.course.btns.hide_buy_now_button === "No") &&
                                                <div className='addbutton w50 float-left'>
                                                    <div className='w95 float-left'>
                                                        <button title='Preview' onClick={() => setPreview(true)}>Preview</button>
                                                    </div>
                                                </div>
                                                }
                                                </> 
                                                }
                                                {productData.course.purchased ? 
                                                <div className={`buybutton float-left ${productData.course.preview ? "w50" : productData.course.purchased  ? "w50" : "container hundredbutton"}`}>
                                                    <div className={`float-right ${productData.course.preview ? "w95" : productData.course.purchased  ? "w95" :"container hundredbutnf"}`}>
                                                        <a href='/d/courses' title='Go to library' >
                                                            <button >Go to library</button>
                                                        </a>
                                                    </div>
                                                </div>
                                                :
                                                productData.course.btns.hide_buy_now_button === "No" && <>
                                                <div className={`buybutton float-left ${productData.course.preview ? "w50" : "container hundredbutton"}`}>
                                                    <div className={`float-right ${productData.course.preview ? "w95" : "container hundredbutnf"}`}>
                                                        <button title='Buy Now' onClick={() => actPlanData > 0 ? addtocart() : buynow()}>{actPlanData > 0 ? "Buy Now" : "Add To Library"}</button>
                                                    </div>
                                                </div>
                                                </>
                                                }
                                                {productData.course.btns.hide_redirect_button == "No" &&
                                                <> 
                                                <div className='container float-left buybutton hundredbutnf redirectbut'>
                                                    <a href={(productData.course.btns.redirect_url != null && productData.course.btns.redirect_url != "") ? productData.course.btns.redirect_url : "#" } title='Get This Course'>
                                                        <button>Get This Course</button>
                                                    </a>
                                                </div>
                                                </>
                                                }
                                                {productData.course.btns.hide_notifyme_button == "No" &&
                                                <> 
                                                {notifLoading ?
                                                
                                                <div className='container float-left buybutton hundredbutnf notifybutton notifload'>
                                                    <button ><FaSpinner /></button>
                                                </div>
                                                :
                                                alredyNotified ?
                                                <div className='container float-left buybutton hundredbutnf notifybutton notificon'>
                                                    <button><FaBell />Notification On</button>
                                                </div>
                                                :
                                                <div className='container float-left buybutton hundredbutnf notifybutton'>
                                                    <button onClick={notifyMe}><FaBell />Notify Me</button>
                                                </div>
                                                }
                                                </>
                                                }
                                            </div>
                                            {productData.course.btns.hide_whatsapp_button === "No" && <>
                                            <div className='container whatsapp'>
                                                {(productData.course.whatsapp_link != undefined &&productData.course.whatsapp_link != null && productData.course.whatsapp_link != ""  ) &&
                                                <a href={productData.course.whatsapp_link} title='Connect with us on Whatsapp' target='_blank'>
                                                    <div className='wappicon'>
                                                        <img src={wappIcon} alt="whatsapp" />
                                                    </div>
                                                    <p>Chat On Whatsapp</p>
                                                </a>
                                                }
                                            </div>
                                            </>
                                            }
                                        </div>
                                        </>}
                                    </div>
                                </div>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {(related != null && related.length > 0) && 
                <div className='container related-box'>
                    <div className='container '>
                        <div className='container cboxheadm center-text'>
                            <p>Related Courses</p>
                        </div>
                        <div className='container related-cards '>
                            <div className='w90 float-center coursesflex maxwidth'>
                                {related.map((rel,i) =>(
                                <div className='w25 float-left' key={i}>
                                    <ProductCard  pack={rel}/>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div> 
                }
            </div>
        </div>
    </section>
    }
        {successPop &&
            <div className='successpop'>
                <div className='sucessmessage float-center'>
                    <p><span><img src={cartGifw} alt="cart" className='sucscart'/></span>Successfully Added To Cart</p>
                </div>
            </div>
        }
        {preview &&
        <div className='previewpop'>
            <div className='container prevflx'>
                <div className='container maxwidth'>
                    <div className='w90 float-center previewmainbox'>
                        <div className='closepreview closeprodprev' onClick={closePreview}><FiX /></div>
                        <div className='container previewdatabox'>
                            <div className='w90 float-center'>
                                <div className='container previewhead center-text'>
                                    <p>Preview Files</p>
                                </div>
                                <div className='previewlist container'>
                                    <ul>
                                        {previewData.map((prev , i) =>(
                                            <li key={i} onClick={() => prevMain(prev)}>
                                                <div className='infolicon'>
                                                    {(prev.type == "Folder") &&
                                                        <img src={folder3} alt="" />
                                                    }
                                                    {prev.type == "Document" &&
                                                        <img src={pdf2} alt="" />
                                                    }
                                                    {(prev.type == "Quiz") &&
                                                        <img src={quiz} alt="" />
                                                    }
                                                    {(prev.type == "Image") &&
                                                        <img src={image} alt="" />
                                                    }
                                                    {(prev.type == "Video") &&
                                                        <img src={videoBig} alt="" />
                                                    }
                                                    {(prev.type == "Question") &&
                                                        <img src={questions} alt="" />
                                                    }
                                                    {(prev.type == "Recording") &&
                                                        <img src={recording} alt="" />
                                                    }
                                                    {(prev.type == "Youtube") &&
                                                        <img src={youtubes} alt="" />
                                                    }
                                                    {(prev.type == "Live") &&
                                                        <img src={live} alt="" />
                                                    }
                                                    {(prev.type == "Assignment") &&
                                                        <img src={assignment} alt="" />
                                                    }
                                                </div>
                                                <div className='infoldertext'>
                                                    <p>{prev.name}</p>
                                                </div>
                                                <div className='viewarrow'>
                                                    <BsArrowRight />
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        {prevTwo &&
            <div className='previewpop'>
                <div className='container prevflx'>
                    <div className='w90 float-center previewmainbox overflow-visible'>
                        <div className='container previewbuttons'>
                            <div className='w90 float-center prevbutflx'>
                                <div className='backpreview' onClick={() => backPrev()}>Back</div>
                                <div className='closepreview' onClick={closePreview}><FiX /></div>
                            </div>
                        </div>
                        <Preview data={prevBox}/>
                    </div>
                </div>
            </div>
        }
        
        {logPop &&
            <div className='popup container'>
                <div className='popboxmain container'>
                    <div className='popmainbox'>
                        <div className='w90 float-center'>
                            <div className='container center-text popheadbox'>
                                <div className='popheading container'>
                                    <p>please login to continue</p>
                                </div>
                                <div className='popsubtext container'>
                                    <p>Lorem ipsum is a placeholder text commonly used to demonstrate the visual form</p>
                                </div>
                                <div className='poplogbuttons container'>
                                    <div className='poploginbutton container float-left'>
                                        <div className='w80 float-center'>
                                            <Link to='/user-login' title=''>Log In</Link>
                                        </div>
                                    </div>
                                    <div className='popor  container float-left'>
                                        <p>OR</p>
                                    </div>
                                    <div className=' container float-left popsignup'>
                                        <Link to='/signup' title=''>Create Account</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='closepop' onClick={() => setLogPop(false)}>
                            <FiX />
                        </div>
                    </div>
                </div>
            </div>
        }
        {notifPop &&
            <div className='repotedbox'>
                <p>< BsCheckCircleFill /> Notification turned on</p>
            </div>
        }
        <Footer />
    </React.Fragment>
  )
}
