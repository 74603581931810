import React, { useEffect, useState ,useCallback } from 'react'
import { Link,useLocation,useNavigate } from 'react-router-dom'
import { Cookies, useCookies } from 'react-cookie';
import {FaStar,FaDownload,FaTrash} from 'react-icons/fa'
import {AiOutlineClose, AiOutlineTags} from 'react-icons/ai'
import {BsCheckCircleFill} from 'react-icons/bs'
import {FiAlertTriangle, FiX} from 'react-icons/fi'
import ofImg from './of.png'
import walImg from './wallet.png'
import resImg from './res.png'
import appliedImg from './applied.png'
import coinImg from './coin.png'
import gstImg from './gst.png'
import axios from "axios";
import LoadingIndicator from "react-loading-indicator";
import ELoader from '../eLoader';
import Menu from '../Menu';
import Footer from '../footer/Footer';

export default function Checkout() {
    const [cartItems,setCartItems] = useState([])
    const [isLoading,setIsLoading] = useState(true)
    const [error,setError] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [usedCoin,setUsedCoin] = useState()
    const [appliedPromocode,setAppliedPromocode] = useState("")
    const [compName,setCompName] = useState("")
    const [ErrCompName,setErrCompName] = useState("")
    const [gstbox,setGstbox] = useState(true)
    const [gstnumber,setGstnumber] = useState("")
    const [ErrGstNum,setErrGstNum] = useState("")
    const [gstApplied,setGstapplied] = useState(false)
    const fetchItem = global.url + "api/user/getcart"
    let location = useLocation();
    let cItem = cookies.cartdata
    let User = cookies.user
    const newToken = cookies.userToken
    const [paymentLoading,setPaymentLoading] = useState(false)
    const [addressArray,setAddressArray] = useState("")
    const [cartArray,setCartArray] = useState("")
    const [priceArray,setPriceArray] = useState("")
    const [finalPrice,setFinalPrice] = useState("")
    
    const navigate = useNavigate()
    var a =0;
    useEffect(() =>{
        if(a == 0){
            fetchCart(appliedPromocode)
        }
        a++
        const script = document.createElement("script")
        script.src = "https://js.instamojo.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
     },[])
    
     function fetchCart(x) {
        setOfferpop(false)
        if(location.state == "" || location.state == null || location.state == undefined) {
            navigate("/")
        }
        else if(cookies.user == "" || cookies.user == null || cookies.user == undefined) {
            navigate("/")
        }
        else if(cItem == "" || cItem == null){
            navigate("/")
        }
        else{
            setIsLoading(true)
            axios({
                method : "POST",
                url:fetchItem,
                params : {
                    cart: JSON.stringify(cItem),
                    address : location.state.addressID,
                    userid : User.userid,
                    promo : x
                },
                headers:{
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
                }
            }).then(
                (response) => {
                    setCartItems(response.data)
                    setPromoVal("")
                    setAddressArray(response.data.address)
                    setCartArray(response.data.cart)
                    setPriceArray(response.data.price)
                    setFinalPrice(response.data.price.final)
                    setIsLoading(false)
                },
                (error) =>{
                    setError(error)
                }
            )
        }
        }
        const checkOutNow  = (price,address,purpose) =>{
            if(!paymentLoading){
                setPaymentLoading(true)
                if(price < 1){
                    checkedOut()
                }
                else{
                    fetch(global.url+"api/user/getPaymentUrl", {
                        "method": "POST",
                        "headers": {
                            "content-type": "application/json",
                            "accept": "application/json",
                            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
                        }, 
                        "body": JSON.stringify({
                            userid: cookies.user.userid,
                            address: address,
                            price: price,
                            purpose: purpose,
                            mode : Mode
                        })
                        })
                        .then(response => response.json())
                        .then(response => {
                            openPayment()
                            onButtonClick(response.url)
                        })
                }
            }
        }
        const checkedOut = (paymentId) =>{
            fetch(global.url+"api/user/checkout", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
                },
                "body": JSON.stringify({
                    mode: Mode,
                    userid: cookies.user.userid,
                    payment_id: paymentId,
                    cart: cartArray,
                    address: addressArray,
                    price: ((finalPrice == 0) ? "" : priceArray),
                    gst: ((gst =="") ? null : JSON.stringify(gst))
                })
                })
                .then(response => response.json())
                .then(response => {
                    if(response.status === "OK"){
                        removeCookie('cartdata',{path : "/"});
                        window.location.href = "/success?"+response.cartid
                    }
                })
                .catch(err => {
                    setError(err)
                });
        }
        const openPayment = () =>{
            
        function onOpenHandler () {
            //    alert("Payments Modal is Opened");
            console.log(JSON.stringify(cartArray),"cart",JSON.stringify(addressArray))
        }

        function onCloseHandler () {
            // alert("Payments Modal is Closed");
        }

        function onPaymentSuccessHandler (response) {
            Instamojo.close()
            checkedOut(response.paymentId)
        }

        function onPaymentFailureHandler (response) {
            // alert("Payment Failure");                                    
        }
        
        Instamojo.configure({
            handlers: {
            onOpen: onOpenHandler,
            onClose: onCloseHandler,
            onSuccess: onPaymentSuccessHandler,
            onFailure: onPaymentFailureHandler
            }
        });
        
        }
        function onButtonClick(url) {
            Instamojo.open(url);
            setTimeout(function(){
                setClosePopMain(true)
            },1000)
        }

    // *************************GST ******************************
    const [gst, setGst] = useState(null)
    const getCompName = (val) =>{
        setCompName(val.target.value)
    }
    const getGstNum = (val) =>{
        setGstnumber(val.target.value)
    }
    const showgstform = () =>{
        setGstbox(false)
        setErrCompName("")
        setErrGstNum("")
    }
    const hidegstform = () =>{
        setGstbox(true)
        setCompName("")
        setGstnumber("")
    }
    const applyGst = (e) =>{
        e.preventDefault();
        if(compName == ""){
            setErrCompName("Enter valid company name")
        }
        else if((gstnumber == "") || (gstnumber.length < 15) || (gstnumber.length > 15)) {
            setErrGstNum("Enter a valid GST number")
        }
        else{
            setGst({
                company: compName,
                gst: gstnumber
            })
            setGstbox(true)
            setGstapplied(true)
        }
    }
    const removeGst = () =>{
        setCompName("")
        setGstnumber("")
        setGst("")
        setGstapplied(false)
        setGstbox(true)
    }
    const [closePopMain ,setClosePopMain] = useState(false)
    // *************************GST ******************************
    const [promoCode, setPromoCode] = useState("")
    const [promoLoading,setPromoLoading] = useState(true)
    const [offerpop,setOfferpop] = useState(false)
    const [promoVal,setPromoVal] = useState("")
    const fetchPromocodes = () =>{
        setOfferpop(true)
        axios.post(global.url + "api/user/user_get_promos",{
            count: 9999,
        },{
            headers:{
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
            }
        })
        .then((res) =>{
            console.log(res.data)
            setPromoCode(res.data.promocodes)
            setPromoLoading(false)
        },(err) =>{
            console.log(err.message)
        })
    }
    const checkPromo = (val) =>{
        setPromoVal(val.target.value)
    }
    const typedPromo = (e) =>{
        e.preventDefault();
        applyPromoCode(promoVal)
        setAppliedPromocode(promoVal)
        fetchCart(promoVal)
    }
    const applyPromoCode = (code) => {
        setPromoLoading(true)
        setAppliedPromocode(code)
        fetchCart(code)
    }
    const removeCode = () =>{
        fetchCart("")
        setAppliedPromocode("")
    }
    if(error) {
        return(
            <p>{error.message}</p>
        )
    }else if(isLoading){
        return(
            <div className='loadingbox signloading'>
                <div className="loadercircle container"><ELoader /></div> 
            </div>
        )
    }else 
  return (
      <React.Fragment>
        <Menu />
        <section className='checkout-box address'>
            <div className='container maxwidth'>
                <div className='w80 float-center'>
                    <div className='w60 float-left'>
                        <div className='container cart-heading'>
                            <div className='w95 float-center'>
                                <p>Items in Cart</p>
                            </div>
                        </div>
                        { cartItems.cart.map((item , i) =>(
                            <div className='container cart-itemslist' key={i}>
                                <div className='cart-item container'>
                                    <a href={`/p/` + item.course.slug + "/" + item.course.otherid} title={item.course.name} >
                                        <div className='w98 float-center'>
                                            <div className='container cart-item-details'>
                                                <div className='w30 float-left'>
                                                {item.course.thumbnail =="" || item.course.thumbnail == null || item.course.thumbnail == undefined ?
                                                    <div className="cart-itemimg defimg">
                                                        <img src={logoDef}  alt={item.name} referrerPolicy="no-referrer" />
                                                    </div>
                                                :
                                                    <div className="cart-itemimg">
                                                        <img src={global.asset + item.course.thumbnail} alt={item.name} referrerPolicy="no-referrer" />
                                                    </div>
                                                }
                                                </div>
                                                <div className='w70 float-left'>
                                                    <div className="w90 float-center">
                                                        <div className="container prod-name">
                                                            <p>{item.course.name}</p>
                                                        </div>
                                                        <div className='container planname'>
                                                            <p>{item.plan.plan_name}</p>
                                                        </div>
                                                        <div className="container cart-prod-price">
                                                            <div className="container pricebox pboxcart">
                                                                <div className="finalamt">
                                                                    <p><span className='pricst'>₹</span>{item.price.final}</p>
                                                                </div>
                                                                <div className="main-price">
                                                                    <span className='oldpbx'><span className='pricst'>₹</span><p>{item.price.price}</p></span>
                                                                </div>
                                                                <div className="percent-off">
                                                                    <p>{item.price.off}% off</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        ))}
                        <div className='container'>
                            <div className='w60 float-left checkoutaddress'>
                                <div className='w98 float-left'>
                                    <div className='container cart-heading'>
                                        <div className='w95 float-center'>
                                            <p>Delivery Address</p>
                                        </div>
                                    </div>
                                    <div className='container deladdress'>
                                        <div className='addressbox container float-left'>
                                            <Link to="/address"  title='address' replace >
                                            <div className='w90 float-center'>
                                                <div className='container address-name'>
                                                    <p>{cartItems.address.name}</p>
                                                </div>
                                                <div className='container addressfull'>
                                                    <p className='address-p'>
                                                        {cartItems.address.address}
                                                    </p>
                                                    <p className='address-city'>
                                                        {cartItems.address.city}
                                                    </p>
                                                    <p className='address-state'>
                                                        {cartItems.address.state} - {cartItems.address.pincode}
                                                    </p>
                                                    <p className='address-email'>
                                                        {cartItems.address.email}
                                                    </p>
                                                    <p className='address-num'>
                                                        {cartItems.address.contact}
                                                    </p>
                                                </div>
                                                <div className='container deliverhere'>
                                                    <button>Change Delivery Address</button>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                    <div className='w40 float-left'>
                        <div className='w90 float-right'>
                            <div className='container'>
                                <div className='container cart-heading'>
                                    <div className='w95 float-center'>
                                        <p>Price Details</p>
                                    </div>
                                </div>
                                <div className='container float-center price-detailsbox'>
                                    <div className='container pricebif'>
                                        <div className='w90 float-center'>
                                            <ul>
                                                <li>
                                                    <div className='m-text'>
                                                        <p>Price ({cartItems.cart.length} Items)</p>
                                                    </div>
                                                    <div className='p-text'>
                                                        <p><span className='pricst'>₹</span> {cartItems.price.price}</p>
                                                    </div>
                                                </li>
                                                <li className='discount'>
                                                    <div className='m-text'>
                                                        <p>Discount</p>
                                                    </div>
                                                    <div className='p-text'>
                                                        <p>- <span className='pricst'>₹</span> {cartItems.price.discount}</p>
                                                    </div>
                                                </li>
                                                {cartItems.price.additional > 0 &&
                                                <li className='discount'>
                                                    <div className='m-text'>
                                                        <p>Additional Discount</p>
                                                    </div>
                                                    <div className='p-text'>
                                                        <p>- <span className='pricst'>₹</span> {cartItems.price.additional}</p>
                                                    </div>
                                                </li>
                                                }
                                                {cartItems.price.additioalArray.map((add,i) =>{
                                                    if(add.discount > 0 )
                                                    return(
                                                    <li key={i} className='discount subdiscs '>
                                                        <div className='m-text'>
                                                            <p>{add.name}</p>
                                                        </div>
                                                        <div className='p-text'>
                                                            <p><span className='pricst'>₹</span> {add.discount}</p>
                                                        </div>
                                                    </li>
                                                    )})
                                                }
                                                {cartItems.show_gst && <>
                                                    {cartItems.price.gst > 0 &&
                                                    <li>
                                                        <div className='m-text'>
                                                            <p>GST</p>
                                                        </div>
                                                        <div className='p-text'>
                                                            <p><span className='pricst'>₹</span> {cartItems.price.gst}</p>
                                                        </div>
                                                    </li>
                                                    }
                                                </>}
                                                <li className='f-amt'>
                                                    <div className='m-text'>
                                                        <p>Total Amount {cartItems.show_gst && "(GST Inc.)" }</p>
                                                    </div>
                                                    <div className='p-text'>
                                                        <p><span className='pricst'>₹</span> {cartItems.price.final}</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {cartItems.price.promocodeArray != null && cartItems.price.promocodeArray != "" &&
                            cartItems.price.promocodeArray.status == "Failed" &&
                            <div className='container cashback notapplied'>
                                <AiOutlineTags /><p>{cartItems.price.promocodeArray.message}</p>
                            </div>
                            }
                            {cartItems.price.promocodeArray != null && cartItems.price.promocodeArray != "" &&
                            cartItems.price.promocodeArray.message == "Promocode Applied" ?
                            <div className='container '>
                                <div className='w90 float-center cart-heading promoapp'>
                                    <div className='offersbox w95 float-center'>
                                        <div className='ofpara'>
                                            <div className='ofimg'>
                                                <img src={appliedImg} alt="Offers" />
                                            </div>
                                            <p>Promocode "{appliedPromocode}" Applied</p>
                                        </div>
                                        <p className='remove-promo right-text' onClick={removeCode}>Remove</p>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                            {cartItems.showpromocodes == true && <>
                            <div className='container '>
                                <div className='container float-center cart-heading' onClick={fetchPromocodes}>
                                    <div className='offersbox w95 float-center'>
                                        <div className='ofpara'>
                                            <div className='ofimg'>
                                                <img src={ofImg} alt="Offers" />
                                            </div>
                                            <p>Apply a promo code</p>
                                        </div>
                                        <p className=' right-text'>View Offers</p>
                                    </div>
                                </div>
                            </div>
                            </>}
                            </>
                            }
                            {(gstbox) ? 
                            <>
                            {(gstApplied) ? 
                            <div className='container offers'>
                                <div className='container '>
                                    <div className='container float-center cart-heading promoapp'>
                                        <div className='offersbox w95 float-center'>
                                            <div className='ofpara'>
                                                <div className='ofimg'>
                                                    <img src={gstImg} alt="Offers" />
                                                </div>
                                                <p>Successfully applied for GST</p>
                                            </div>
                                            <p className='remove-promo right-text' onClick={removeGst}>Remove</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            cartItems.gstclaimbutton != "Hide" && cartItems.show_gst == true &&
                            <div className='container offers'>
                                <div className='container '>
                                    <div className='container float-center cart-heading gstheading' onClick={showgstform}>
                                        <div className='offersbox w95 float-center'>
                                            <div className='ofpara'>
                                                <div className='ofimg'>
                                                    <img src={gstImg} alt="Offers" />
                                                </div>
                                                <p>GST Claim</p>
                                            </div>
                                            <p className=' right-text'>Claim now</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            </>
                            :
                            <div className='container'>
                                <div className='container float-center gst-form'>
                                    <div className='container gst-heading center-text gstheading'>
                                        <p>Fill details to apply GST claim</p>
                                    </div>
                                    <div className='container gst-details'>
                                        <form onSubmit={applyGst}>
                                            <div className='container infields gstinf'>
                                                <div className='w95 float-center'>
                                                    <input
                                                        type="text"
                                                        required
                                                        placeholder="Company Name"
                                                        value={compName}
                                                        onChange={getCompName}
                                                        id="name"
                                                        />
                                                </div>
                                                <div className='error-message container'>
                                                    <p>{ErrCompName}</p>
                                                </div>
                                            </div>
                                            <div className='container infields gstinf'>
                                                <div className='w95 float-center'>
                                                    <input
                                                        type="text"
                                                        required
                                                        placeholder="GST Number"
                                                        value={gstnumber}
                                                        onChange={getGstNum}
                                                        
                                                        />
                                                </div>
                                                <div className='error-message container'>
                                                    <p>{ErrGstNum}</p>
                                                </div>
                                            </div>
                                            <div className='container deliverhere applygst'>
                                                <div className='w60 float-left'>
                                                    <div className='w90 float-center'>
                                                        <button>Apply</button>
                                                    </div>
                                                </div>
                                                <div className='w40 float-left cancelgst'>
                                                    <div className='w90 float-center center-text'>
                                                        <p onClick={hidegstform}>Cancel</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            }
                            {
                                (cartItems.price.discount > 0) &&
                            <div className='container cashback'>
                                <AiOutlineTags /><p>You will save <span className='pricst'>₹</span> {cartItems.price.discount + cartItems.price.additional} on purchasing this course</p>
                            </div>
                            }
                            {
                                (cartItems.price.cb > 0) &&
                            <div className='container cashback'>
                                <AiOutlineTags /><p>You will receive {cartItems.price.cb} coins on purchasing this course</p>
                            </div>
                            }
                            
                            <div className='payments container'>
                                <div className='container payment-options'>
                                    <div className='container float-center paynow'>
                                        <button onClick={() => checkOutNow(cartItems.price.final,cartItems.address,cartItems.purpose)}>Place Order</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {paymentLoading &&
        <div className='container paymentPopup'>
            <div className="container maxwidth">
                <div className='w90 float-center'>
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                    <p className='redirecttext'>Redirecting</p>
                    <p className='redirectsubt'>Please Do Not Refresh This Page <br/>or <br/>Press the Back Button</p>
                </div>
            </div>
        </div>
        }
        {closePopMain &&
            <div className="closepayment" onClick={() => window.location.reload()}><FiX /></div>
            }
        {offerpop &&
        <div className='offers-popup'>
            <div className='w40 float-right offerboxpop'>
                <div className='w95 float-center'>
                    <div className='container offerlist-heading'>
                        <div className='w90 float-center'>
                            <p>Offers Available</p>
                        </div>
                    </div>
                    <div className='container enter-promo'>
                        <form onSubmit={typedPromo}>
                            <div className='container e-promo'>
                                <div className='w80 float-left promoinput'>
                                    <input 
                                    type="text"
                                     placeholder='Alredy have a promocode enter here' 
                                     required 
                                     value={promoVal} 
                                     onChange={checkPromo} 
                                     ></input>
                                </div>
                                <div className='w20 float-left applybut'>
                                    <button>Apply</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {
                    (promoLoading) ? 
                    <div className="loadercircle promoloading container"><LoadingIndicator segmentWidth={4} segmentLength={9}/> </div> 
                    : (
                    <div className='container'>
                        { (promoCode != "" && promoCode != null ) &&
                        promoCode.map((promo,index) => (
                        <div className='container' key={index}>
                            <div className='w95 float-center offer-list'>
                                <div className='offerbox container' onClick={() => applyPromoCode(promo.promo)}>
                                    <div className='w95 float-center'>
                                        <div className='w70 float-left'>
                                            <div className='container offer-head'>
                                                <p>{promo.title}</p>
                                            </div>
                                            <div className='container offer-details'>
                                                <p>
                                                    {promo.body}
                                                </p>
                                            </div>
                                            <div className='code'>
                                                <p>{promo.promo}</p>
                                            </div>
                                        </div>
                                        <div className='w30 float-left'>
                                            <div className='container float-center applybut'>
                                                <button>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>      
                        </div>  
                        ))}
                    </div>
                    )}
                </div>
                <div className='offerpopclose' onClick={() => setOfferpop(false)}> <AiOutlineClose /></div>
            </div>
        </div>
        }
        <Footer />
    </React.Fragment>
  )
}
