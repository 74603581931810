import React from 'react'
import { useEffect } from 'react'
import VideoPage from './VideoPage';
import { FiMenu, FiX } from 'react-icons/fi';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Link, useLocation, useParams } from 'react-router-dom'
import { useState } from 'react';
import folder3 from './images/folder3.png'
import videoBig from './images/videobig.png'
import pdf2 from './images/pdf2.png'
import quiz from './images/quiz.png'
import image from './images/image.png'
import questions from './images/question.png'
import recording from './images/recording.png'
import youtubes from './images/youtube.png'
import assignment2 from './images/assignment.png'
import live2 from './images/live.png'

export default function SidebarManager(props) {
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [files,setFiles] = useState("")
    let User = cookies.user;
    const newToken = cookies.userToken
    const params = useParams()
    const location = useLocation()
    var purchaseid = props.purchaseid
    var courseid = props.courseid
    var folderid = props.folderid
    var parentid = props.parentid
    var a = 0;
    useEffect(() =>{
        if(a == 0){
            getSideBar()
        }
    },[])
    const getSideBar = () =>{ 
        axios.post(global.url + "api/user/myCourseContent",{
            uid : User.userid,
            purchase_id : purchaseid,
            course_id : courseid,
            p_id : parentid
        },
        {headers : {
            "content-type": "application/json",
            "Authorization" : "Bearer "+(newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken)
        }}
        )
        .then((res) =>{
            setFiles(res.data.files)
        },
        (error) =>{
            console.log(error.message)
        })
    }
    const hideBar = () =>{
        if($(window).width() < 900){
            $('.sidebarside').css("left","-100%");
        }
    }
  return (
    <>
    <div className='container sidebarboxms'>
        <div className='container backbtn'>
            <div className='w90 float-center backflex'>
                <a href={"/dashboard/c/manager/"+props.courseid+"/"+props.purchaseid+"/"+props.parentid} title='Back to course' >Back to course</a>
                <div className='closesbar pc_hide' onClick={hideBar}>
                    <FiX />
                </div>
            </div>
        </div>
        <div className='container progress'></div>
        {files != null && files != "" && <>
            <div className='container contentlist'>
                <ul>
                    {files.map((file, i) =>{
                        let url = "";
                        let url2 = "";
                        if(file.type == "Document" && file.available == 1)  {
                         url = "/dashboard/c/" + courseid + "/"+ purchaseid + "/doc/"  + file.id + "/";
                        }
                        else if (file.type == "Image" && file.available == 1) {
                            url = "/dashboard/c/" + courseid + "/"+ purchaseid + "/img/"  + file.id;
                        }
                        else if ((file.type == "Video" || file.type == "Youtube" || file.type == "Recording")  && file.available == 1){
                            url = "/dashboard/c/" + courseid + "/"+ purchaseid + "/video/"  + file.id;
                        }
                        else if ((file.type == "Live")  && file.available == 1){
                            url = "/dashboard/c/" + courseid + "/"+ purchaseid + "/live/"  + file.id;
                        }
                        else if (file.type == "Assignment" && (file.available == 2 || file.available == 1)) {
                            url = "/dashboard/c/" + courseid + "/"+ purchaseid + "/assign/"  + file.id;
                        }
                        else if (file.type == "Quiz" && file.available == 1) {
                            url = "/dashboard/c/" + courseid + "/"+ purchaseid + "/quiz/"  + file.id;
                        }
                        else if(file.available == 0){
                            url = "/dashboard/c/manager/" + courseid + "/"+ purchaseid + "/"  + file.id + "?avail=not";
                            url2 ="manager";
                        }
                        else{
                            url = "/dashboard/c/manager/" + courseid + "/"+ purchaseid + "/"  + file.id;
                            url2 ="manager"
                        }
                        return(
                        <li key={i} className={(props.folderid == file.id) ? "actsid" : ""}>
                            <a href={(file.available != undefined && file.available != null && file.available != 1) ? "#" : url} state={{prev : location.pathname}} title={file.name} >
                                <div className='sidebarlist container'>
                                    <div className='fileimg'>
                                        {(file.type == "Folder") &&
                                            <img src={folder3} alt="" />
                                        }
                                        {file.type == "Document"  &&
                                            <img src={pdf2} alt="" />
                                        }
                                        {(file.type == "Quiz") &&
                                            <img src={quiz} alt="" />
                                        }
                                        {(file.type == "Image") &&
                                            <img src={image} alt="" />
                                        }
                                        {(file.type == "Video") &&
                                            <img src={videoBig} alt="" />
                                        }
                                        {(file.type == "Question") &&
                                            <img src={questions} alt="" />
                                        }
                                        {(file.type == "Recording") &&
                                            <img src={recording} alt="" />
                                        }
                                        {(file.type == "Youtube") &&
                                            <img src={youtubes} alt="" />
                                        }
                                        {(file.type == "Live") &&
                                            <img src={live2} alt="" />
                                        }
                                        {(file.type == "Assignment") &&
                                            <img src={assignment2} alt="" />
                                        }
                                    </div>
                                    <div className='filenames'>
                                        {file.name}
                                    </div>
                                </div>
                            </a>
                        </li>
                        )
                    })}
                </ul>
            </div>
        </>}
    </div>
    </>
  )
}
