import React, { useEffect, useState } from 'react'
import EmptyCart from '../emptycart.png';
import {FaStar,FaDownload,FaTrash} from 'react-icons/fa'
import {BsFillBookmarkCheckFill} from 'react-icons/bs'
import { Cookies, useCookies } from 'react-cookie';
import { Link,useNavigate } from 'react-router-dom';
import {FiAlertTriangle} from 'react-icons/fi'
import LoadingIndicator from "react-loading-indicator";
import axios from 'axios';
import CartFetch from './CartFetch';
import {GoTrashcan} from 'react-icons/go'
import CheckLogin from '../auth/CheckLogin';
import {FiX} from 'react-icons/fi'
import ELoader from '../eLoader';
import Menu from '../Menu';
import Footer from '../footer/Footer';

function filterByProperty(array, prop, value){
    var filtered = [];
    for(var i = 0; i < array.length; i++){

        var obj = array[i];
        if(typeof array[i][prop] !== "undefined"){
            if((array[i][prop]['course'] == value.cid) && (array[i][prop]['plan'] == value.planid)){
                filtered.push(i);
            }
        }
    }    

    return filtered;

}

export default function Cart() {
    const [displays,setDisplays] = useState(false)
    const [error,setError] = useState("")
    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies('')
    const [logPop ,setLogPop] = useState(false)
    const [removePop,setRemovePop] = useState(false)
    useEffect(() =>{
        setCookie('callback',('/mycart'),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
    },[])
    
    const {cartItems,isLoadingCart,setCartItems,fetchCart} = CartFetch()
    const {loggedIn,currentUser} = CheckLogin()
    const placeOrder = () =>{
        if(loggedIn) {
            navigate("/address")
        }else{
            setLogPop(true)
        }
    }
    const [remoindx,setRemoindx] = useState("")
    const removeItem = (index) =>{
        setRemovePop(true)
        setRemoindx(index)
    }
    const removeFromCart = () =>{
        var temp = cookies.cartdata
        var temp2 = temp[remoindx] 
        const result = filterByProperty(temp, "upsaleparent", {"cid": temp2.courseid, "planid": temp2['planid']});
        if(result.length > 0 ){
            for(var i=0;i<result.length;i++){
                temp.splice(i, 1)  
            }
        }
        temp.splice(remoindx,1)
        setCookie('cartdata',JSON.stringify(temp),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
        window.location.href ="/mycart"
    }
    
    // ************Add to Cart****************
    const cartData = cookies.cartdata
    const addtocart = (upsale) =>{
        let mainid = upsale.courseid
        let activePlan = upsale.id
        if(cartData != null && cartData != "" && cartData != undefined){
            var temp = cookies.cartdata
            var exist = false
            for(var i=0;i<temp.length;i++){
                if(temp[i]['courseid'] === mainid){
                    exist = true
                    temp[i]['planid'] = activePlan
                }
            }
            if(exist === false){
                temp.push({courseid: mainid, planid: activePlan, upsaleparent: {"course": upsale.forcourse, "plan": upsale.forplan}})
            }
            setCookie('cartdata',JSON.stringify(temp),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
            window.location.href = '/mycart'
        }
        else{
            var temp = []
            temp.push({courseid : mainid,planid : activePlan, upsaleparent: {"course": mainid, "plan": activePlan}})
            setCookie('cartdata',JSON.stringify(temp),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
            window.location.href = '/mycart'
        }
    }
    // ************Add to Cart****************
    if(error) {
        return(
            <p>Error</p>
        )
    }
    else if(isLoadingCart){
        return(
            <div className='loadingbox signloading'>
                <div className="loadercircle container">
                <ELoader/>
                </div> 
            </div>
        )
    }else 
  return (
    <React.Fragment>
        <Menu />
        <section className='cart'>
            <div className='w90 float-center maxwidth'>
                {(cartItems != "") ? (
                <div className='container'>
                    <div className='w65 float-left cart-items'>
                        <div className='container cart-heading'>
                            <div className='w95 float-center'>
                                <p>My Cart ({cartItems.cart.length} Items) </p>
                            </div>
                        </div>
                        { cartItems.cart.map((item , index) =>(
                            <div className='container cart-itemslist' key={index}>
                                <div className='cart-item container'>
                                    <div className='w98 float-center'>
                                        <div className='container cart-item-details'>
                                            <div className='w30 float-left'>
                                                {item.course.thumbnail =="" || item.course.thumbnail == null || item.course.thumbnail == undefined ?
                                                    <div className="cart-itemimg defimg">
                                                        <a href={`/p/` + item.course.slug + "/" + item.course.otherid} title={item.course.name} >
                                                            <img src={logoDef}  alt={item.name} referrerPolicy="no-referrer" />
                                                        </a>
                                                    </div>
                                                :
                                                    <div className="cart-itemimg">
                                                        <a href={`/p/` + item.course.slug + "/" + item.course.otherid} title={item.course.name} >
                                                        <img src={global.asset + item.course.thumbnail} alt={item.name} referrerPolicy="no-referrer" />
                                                        </a>
                                                    </div>
                                                }
                                            </div>
                                            <div className='w70 float-left'>
                                                <div className="w90 float-center">
                                                    <a href={`/p/` + item.course.slug + "/" + item.course.otherid} title={item.course.name} >
                                                        <div className="container prod-name">
                                                            <p>{item.course.name}</p>
                                                        </div>
                                                        <div className='container planname'>
                                                            <p>{item.plan.plan_name}</p>
                                                        </div>
                                                        <div className="container cart-prod-price">
                                                            <div className="container pricebox pboxcart">
                                                                <div className="finalamt">
                                                                    <p><span className='pricst'>₹</span>{item.price.final}</p>
                                                                </div>
                                                                <div className="main-price">
                                                                    <span className='oldpbx'><span className='pricst'>₹</span><p>{item.price.price}</p></span>
                                                                </div>
                                                                <div className="percent-off">
                                                                    <p>{item.price.off}% off</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <div className='container removeitem'>
                                                        <button className='removefromcart' onClick={() => removeItem(index)}><FaTrash /> Remove From Cart</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {item.upsale.length > 0 &&
                                    item.upsale.map((upsale , k) =>{
                                        let imgData = JSON.parse(upsale.image)
                                        console.log(imgData)
                                        return(
                                            <div className='container upsaleimg' key={k} onClick={() => addtocart(upsale)}>
                                                <img src={global.asset + imgData.path} alt={imgData.name} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        ))}
                    </div>
                    <div className='w35 float-left'>
                        <div className='w80 float-center price-detailsbox'>
                            <div className='container pdetailshead'>
                                <div className='w90 float-center'>
                                    <p>Payment Summary</p>
                                </div>
                            </div>
                            <div className='container pricebif'>
                                <div className='w90 float-center'>
                                    <ul>
                                        <li>
                                            <div className='m-text'>
                                                <p>Price ({cartItems.cart.length} Items)</p>
                                            </div>
                                            <div className='p-text'>
                                                <p><span className='pricst'>₹</span> {cartItems.price.price}</p>
                                            </div>
                                        </li>
                                        <li className='discount'>
                                            <div className='m-text'>
                                                <p>Discount</p>
                                            </div>
                                            <div className='p-text'>
                                                <p>- <span className='pricst'>₹</span> {cartItems.price.discount}</p>
                                            </div>
                                        </li>
                                        <li className='f-amt'>
                                            <div className='m-text'>
                                                <p>Total Amount</p>
                                            </div>
                                            <div className='p-text'>
                                                <p><span className='pricst'>₹</span> {cartItems.price.final}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='container placebutton'>
                                                <button className='buybutton' onClick={placeOrder}>Place Order</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>) :(
                    <div className='container empty-cart center-text'>
                        <div className='w30 float-center empty-img'>
                            <div className="cart-itemimg">
                                <img src={EmptyCart} alt="Empty Cart" />
                            </div>
                            <div className='container empty-message'>
                                <p className='m-paracart'>Your cart is empty!</p>
                                <p className='s-paracart'>Add Items to The Cart</p>
                            </div>
                            <div className='container start-shopbutton'>
                                <button className="check-drop" onClick={() => navigate("/")}>Start Learning</button>
                            </div>
                        </div>
                    </div>
                )
                }
            </div>
        </section>
        {logPop &&
            <div className='popup container'>
                <div className='popboxmain container'>
                    <div className='popmainbox'>
                        <div className='w90 float-center'>
                            <div className='container center-text popheadbox'>
                                <div className='popheading container'>
                                    <p>please login to continue</p>
                                </div>
                                <div className='popsubtext container'>
                                    <p>Lorem ipsum is a placeholder text commonly used to demonstrate the visual form</p>
                                </div>
                                <div className='poplogbuttons container'>
                                    <div className='poploginbutton container float-left'>
                                        <div className='w80 float-center'>
                                            <Link to='/user-login' title=''>Log In</Link>
                                        </div>
                                    </div>
                                    <div className='popor  container float-left'>
                                        <p>OR</p>
                                    </div>
                                    <div className=' container float-left popsignup'>
                                        <Link to='/signup' title=''>Create Account</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='closepop' onClick={() => setLogPop(false)}>
                            <FiX />
                        </div>
                    </div>
                </div>
            </div>
        }
        {removePop &&
            <div className='popup container'>
                <div className='popboxmain container'>
                    <div className='popmainbox'>
                        <div className='w90 float-center'>
                            <div className='container center-text popheadbox'>
                                <div className='popheading container'>
                                    <p>Remove Item</p>
                                </div>
                                <div className='popsubtext container'>
                                    <p>Are you sure you want to remove this item?</p>
                                </div>
                                <div className='poplogbuttons container'>
                                    <div className='poploginbutton w50 float-left'>
                                        <button className='removebut  float-left' onClick={removeFromCart}>Remove</button>
                                    </div>
                                    <div className=' w50 float-left popsignup'>
                                        <button className='cancelbutton float-right' onClick={() => setRemovePop(false)}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='closepop' onClick={() => setRemovePop(false)}>
                            <FiX />
                        </div>
                    </div>
                </div>
            </div>
        }
        <Footer />
    </React.Fragment>
  )
}

