// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging ,getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB5S2NAu_MVzt_fDM0IGyqndBWDC3442lc",
    authDomain: "escholars-a8f4b.firebaseapp.com",
    projectId: "escholars-a8f4b",
    storageBucket: "escholars-a8f4b.appspot.com",
    messagingSenderId: "673758167392",
    appId: "1:673758167392:web:0c3990a78ca50c186c5e05",
    measurementId: "G-S3DNE7993R"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
const analytics = getAnalytics(app);