import React,{useState,useEffect} from 'react'
import { Cookies, useCookies } from 'react-cookie';
import axios from 'axios';

export default function CartFetch() {
    const [cartItems,setCartItems] = useState("")
    const [cartReq,setCartReq] = useState("")
    const [cookies, setCookie, removeCookie] = useCookies(''); 
    const [isLoadingCart,setIsLoadingCart] = useState(true)
    const [token,setToken] = useState("")
    const [error,setError] = useState(null)
    var a = 0;
    const cartData = cookies.cartdata
    const newToken = cookies.userToken
    useEffect(() =>{
        if(a == 0) {
            if(cartData != null && cartData != "" && cartData != undefined){
                fetchCart(cartData)
            }else{
                setCartItems("")
                setIsLoadingCart(false)
            }
        }
        a++
    },[])

    const fetchCart = (data) =>{
        setIsLoadingCart(true)
            setCartReq(data)
            axios.post(global.url + "api/user/getcart",null,{
                headers : {
                    'Authorization' : "Bearer " + (newToken != null && newToken != "" && newToken != undefined ? newToken : defaultToken),
                    'Accept' : 'application/json'
                },
                params : {
                    cart : JSON.stringify(data)
                }
            })
            .then(
            (res) =>{
                setCartItems(res.data)
                setCookie('cartproducts',JSON.stringify(res.data),{path : "/",maxAge: new Date(Date.now()+(10 * 365 * 24 * 60 * 60))})
                setIsLoadingCart(false)
            },
            (error) =>{
                setError(error)
                setIsLoadingCart(false)
            }
            )  
    }
  return {cartItems,setCartItems,isLoadingCart,fetchCart}
}
